import React from 'react'
import {Box, Divider} from '@material-ui/core'
import AlarmButton from '../alarm'

type Props = {
  header: string | React.ReactElement
  stretcher?: React.ReactElement
  children?: React.ReactElement
  bottom?: React.ReactElement
  hideAlarmButton?: boolean
}

export default (props: Props) => <React.Fragment>
  <Box>
    {props.header}
  </Box>
  <Divider style={{margin: "20px 0px 0 0px"}}/>
  {props.children}
  <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
    {props.stretcher}
  </Box>
  <Box display="flex">
    {props.hideAlarmButton ? null : <AlarmButton/>}
    <Box flexGrow={1}/>
    {props.bottom}
  </Box>
</React.Fragment>