import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import { closeWarningMessageAction } from '../../config/pages/shared/common_store'

export default (props: { message: string }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(true)
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    setTimeout(() => dispatch(closeWarningMessageAction(props.message)), 500)
  }

  return <Snackbar open={open} autoHideDuration={6000}
                   onClose={handleClose}
                   anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
    <Alert onClose={handleClose} severity="warning">
      {props.message}
    </Alert>
  </Snackbar>
}