import { BaseAttribute } from "./attributes/base_attribute"
import { getStorageEntityKey } from "./helpers/storage_key"
import { IEntityBase, EntityId, TEntityClass } from "./type"

export class BaseEntity implements IEntityBase {
  public static attributes: BaseAttribute[]
  public static entity_name: string = "undef_entity_name"
  id: EntityId
  constructor(id: EntityId = "-1") {
    this.id = id
  }
  public static buildFromRaw<TEnt extends BaseEntity>(raw: any, t: TEntityClass<TEnt>): TEnt {
    const res = new t()
    for (const attr of t.attributes) {
      attr.setVal(res, raw[attr.key])
    }
    if (raw.id) {
      res.id = raw.id
    }
    return res
  }
  public static *fetch<TEnt extends BaseEntity>(id: EntityId, t: TEntityClass<TEnt>) {
    const url = `${getStorageEntityKey(t)}/${id}`
    const options = { method: "GET" }
    const raw = yield ({ url, options })
    return t.buildFromRaw(raw, t) as TEnt
  }
}
