import { IEntityBase, TEntityClass, IReloadAction, EntityState, ICreateAction, IUpdateAction, IDeleteAction, ISingleLoadAction } from "../type";


export function buildLoadMutator<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>>(t: TEClass) {
  return (action: IReloadAction, state: EntityState<TEntity>): EntityState<TEntity> => {
    const reciveEntities: TEntity[] = action.response.map( (rawEntity:any) => t.buildFromRaw(rawEntity, t))
    const preparedEntities: { [key: string]: TEntity } = {}
    reciveEntities.forEach(entity => preparedEntities[entity.id] = entity)
    return {
      ...state,
      entities: preparedEntities
    }
  }
}

export function buildSingleLoadMutator<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>>(t: TEClass) {
  return (action: ISingleLoadAction, state: EntityState<TEntity>): EntityState<TEntity> => {
    const reciveEntity: TEntity = t.buildFromRaw(action.response, t)
    return {
      ...state,
      entities: {
        ...state.entities,
        [reciveEntity.id]: reciveEntity
      }
    }
  }
}

export function buildCreateMutator<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>>(t: TEClass) {
  return (action: ICreateAction<TEntity>, state: EntityState<TEntity>): EntityState<TEntity> => {
    const reciveEntity: TEntity = t.buildFromRaw(action.response.model, t)
    return {
      ...state,
      entities: {
        ...state.entities,
        [reciveEntity.id]: reciveEntity
      }
    }
  }
}

export function buildUpdateMutator<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>>(t: TEClass) {
  return (action: IUpdateAction<TEntity>, state: EntityState<TEntity>): EntityState<TEntity> => {
    const reciveEntity: TEntity = t.buildFromRaw(action.response.model, t)
    return {
      ...state,
      entities: {
        ...state.entities,
        [reciveEntity.id]: reciveEntity
      }
    }
  }
}

export function buildDeleteMutator<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>>(t: TEClass) {
  return (action: IDeleteAction, state: EntityState<TEntity>): EntityState<TEntity> => {
    const id:string = action.response.model.id
    const entities = { ...state.entities }
    delete entities[id]
    return {
      ...state,
      entities
    }
  }
}
