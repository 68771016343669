import {connect, ConnectedProps, useSelector} from "react-redux"
import React, {forwardRef, useRef} from "react"
import MaterialTable, {Icons} from "material-table"
import {Box, Container, Input} from "@material-ui/core"
import {useTranslation} from "react-i18next"
import {SMS} from "./sms"
import {getArrowTable, getCancelTable, getDoneTable, getEditTable, getTrashTable} from "../../assets/icons";
import {AppState} from "../../config/store";
import MultiSelectField from '../../features/entity/components/table_multiple'
import SelectField from '../../features/entity/components/table_select'
import {BaseEntity, CPagination} from "../../features/entity";
import {City} from "../city";
import {buildMapDispatch, buildMapState} from "../../features/entity/containers/list";
import {Line} from "../line";
import {IEntityBase} from "../../features/entity/type";
import {setEnableSMS} from "./saga";
import Switcher from '../ticket_template/switcher'
import {arrayToString} from "../../shared/helper";

const mapStateToProps = buildMapState(SMS)
const mapDspToProp = {
  ...buildMapDispatch(SMS),
  onSetDefault: (sms: IEntityBase, isEnabled: boolean) => setEnableSMS(sms.id, isEnabled),
}

const connector = connect(mapStateToProps, mapDspToProp)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector((props: PropsFromRedux) => {
  const { t } = useTranslation()
  const refTable = useRef<MaterialTable<BaseEntity>>(null)
  const {cities, lines} = useSelector((store: AppState) => {
    return {
      cities: Object.keys(store.cities.entities).map(k => store.cities.entities[k] as City),
      lines: Object.keys(store.lines.entities).map(k => store.lines.entities[k] as Line)
    }
  })

  const onAddClick = () => {
    const table: any = refTable.current!
    table.dataManager.changeRowEditing()
    table.setState({
      ...table.dataManager.getRenderState(),
      showAddRow: !table.state.showAddRow,
    })
  }

  const edit = getEditTable()
  const trash = getTrashTable()
  const done = getDoneTable()
  const cancel = getCancelTable()

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref)),
    Check: forwardRef(() => done),
    Clear: forwardRef(() => cancel),
    Delete: forwardRef(() => trash),
    Edit: forwardRef(() => edit)
  }

  return <>
    <Box display="flex" flexDirection="row">
      <Box style={{marginTop: 15}}>
        {props.tabs ? props.tabs : null}
      </Box>
    </Box>
    <Container maxWidth="lg" className="mtable container-table" style={{marginTop: 8}}>
      <MaterialTable
        data={props.filtered as SMS[]}
        icons={tableIcons}
        options={{
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1
        }}
        tableRef={refTable}
        localization={{ header: { actions: "" } }}
        columns={[
          {
            field: "city_ids",
            title: t("city"),
            editComponent: rowProps => {
              return (
                <MultiSelectField
                    className={''}
                    errorClassName={''}
                    collection={cities}
                    val={rowProps.value || []}
                    setVal={rowProps.onChange}/>
              )
            },
            render: row => {
              return arrayToString(row.city_ids, cities)
            }
          },
          {
            field: "line_ids",
            title: t("line"),
            editComponent: rowProps => {
              return (
                <SelectField
                  className={''}
                  errorClassName={''}
                  collection={lines}
                  val={rowProps.value || []}
                  setVal={rowProps.onChange}/>
              )
            },
            render: row => {
              return arrayToString(row.line_ids, lines)
            }
          },
          {
            field: "sender",
            title: t("sender")
          },
          {
            field: "template",
            title: t("message")
          },
          {
            field: "link",
            title: t("link in sms"),
            render: row => {
              return <a>{row.link}</a>
            }
          },
          {
            field: "delay",
            title: t("delay in minutes"),
            editComponent: rowProps => {
              return (
                <Input
                  value={rowProps.value}
                  onChange={(e) =>  rowProps.onChange(e.currentTarget.value)}
                  type="number"
                />
              )
            }
          },
          {
            field: "enabled",
            title: t("enabled"),
            render: (row) => <>
              {row ? <Switcher
                checked={row.enabled}
                onChange={() => props.onSetDefault(row, !row.enabled)}
              /> : null}
            </>,
            editComponent: () => <></>
          }
        ]}
        components={{
          Pagination: (props) => <CPagination {...props} onAddClick={onAddClick} />,
        }}
        editable={{
          onRowAdd: (newData) => {
            props.onCreate(newData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100)
            })
          },
          onRowUpdate: (newData) => {
            props.onUpdate(newData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100);
            })
          },
          onRowDelete: (oldData) => {
            props.onDelete(oldData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100);
            })
          }
        }}
      />
    </Container>
  </>
})