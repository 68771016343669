import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../../store'
import {loginAction, loginReqAction} from '../reducers/store'
import SignIn from './signin'
import { useLocation, useHistory } from 'react-router-dom'
import {LoadingCircularProgress} from "../../shared/loading_progress";
import {Box, Button, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const mapStateToProps = ({ authentication }: AppState) => {
  return {
    authStore: authentication
  }
}

const mapDispatch = {
  loginReqAction,
  loginAction
}

const connector = connect(mapStateToProps, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

function AuthForm(props: Props) {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    const { from } = (location.state as any) || { from: { pathname: "/" } }
    const log = props.authStore.current_user_id
    if(log && from.pathname !== location.pathname){
      setTimeout(() => history.push(from), 500)
    }
  })
  if(window.localStorage["authToken"]){
    return <Grid
      style={{width: "100%", height: "100%", position: "absolute"}}
      container
      direction="column"
      justify="space-between"
      alignItems="center">
      <Box />
      <LoadingCircularProgress />
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "10px", marginBottom: 100, marginRight: 0, width: 156, textTransform: 'none' }}
        qa-tag="ep-save-client-button"
        className="primary-button"
        disableRipple={true}
        onClick={() => { history.push('/logout') }}>
        {t('logout')}
      </Button>
    </Grid>
  }
  return <SignIn errorMessage={props.authStore.errorMessage}
    onSignInClick={(email, password) => props.loginReqAction(email, password)} />
}

const Wrapper = (props: Props) => (
  <AuthForm {...props} />
)

export default connector(Wrapper)
