import { buildStore, buidlRemoteSyncContainer } from "../../features/entity"
import { EntityId } from "../../features/entity/type"
import Table from "./table"
import { Device } from "./device"

export const DeviceReducer = buildStore(Device)
export const RemoteSyncDeviceContainer = buidlRemoteSyncContainer(Device)
export const ListDeviceContanier = Table
export function loadDevice(deviceId: EntityId) {
  return {
    type: "FETCH_ACTION",
    url: "devices/"+deviceId,
    options: {method: "GET"},
    callbackAction: "devices_SINGLE_LOAD_ACTION"
  }
}
