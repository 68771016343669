
import {CircularProgress, CircularProgressProps, createStyles, makeStyles} from "@material-ui/core";
import {activeTabColor, lightGreyColor} from "../../../styles/variables";

const useStylesFacebook = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottom: {
      color: lightGreyColor
    },
    top: {
      color: activeTabColor,
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

export function LoadingCircularProgress(props: CircularProgressProps) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={180}
        thickness={3}
        {...props}
        value={100}
        />
    <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={180}
        thickness={3}
        {...props}
    />
  </div>
);
}