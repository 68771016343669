import React from "react"

import Show from './components/show'
import Edit from './components/edit'
import { TMappedState, TAditionalStore } from "../type"
import { BaseEntity } from "../BaseEntity"
import { Column } from "material-table"

export type TEvent = React.ChangeEvent<{ value: unknown }>

export type TEditable = "always" | "never" | "onUpdate" | "onAdd" | ((columnDef: Column<BaseEntity>, rowData: BaseEntity) => boolean) | undefined

export class BaseAttribute {
  deffaultVlaue: string = "deffualt string"
  key: string
  editable: TEditable
  hide: boolean
  constructor(key: string, editable:TEditable = "always", hide: boolean = false) {
    this.key = key
    this.editable = editable
    this.hide = hide
  }

  getDisplayValue(source: BaseEntity, state: TMappedState): string {
    const entity: any = source //state.mapEntitiesById[source.id]
    return entity[this.key]
  }
  getVal(source: BaseEntity):any{
    const entity: any = source //state.mapEntitiesById[source.id]
    return entity[this.key]
  }
  change<T extends BaseEntity>(source: T, event: TEvent): T {
    return Object.assign(Object.create(source), {
      [this.key]: String(event.target.value)
    })
  }
  showRender(source: BaseEntity, state: TMappedState) {
    return <Show key={this.key} value={this.getDisplayValue(source, state)} />
  }
  editRender(onChange: (event: TEvent) => void, source: BaseEntity, state: TMappedState ) {
    return <Edit key={this.key} attr_key={this.key} value={this.getDisplayValue(source, state)} onChange={onChange} />
  }
  mapState(appState: any): { [key: string]: BaseEntity } {
    return {}
  }
  setVal(source: any, val:any){
    source[this.key] = val
  }
  buildColumn(additionals: TAditionalStore): null | Column<BaseEntity>{
    if (this.hide)
      return null
    return {
      title: this.key, field: this.key,
      initialEditValue:  this.key,
      editable: this.editable
    }
  }
}