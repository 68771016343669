import {Ticket} from "../../models/ticket";
import {TClientData, TExpertData} from "../../models/ticket/type";
import {Field} from "../../models/field";

export function validateClientData(clientData: TClientData) {
  const phone: string = clientData['phone'] as string | ""
  const firstName = clientData['first_name']
  const lastName = clientData['last_name']

  return {
    phone: !phone || !(phone.trim().length > 9),
    first_name: !firstName,
    last_name: !lastName
  }
}

export function validateExpertData(ticket: Ticket, expertData: TExpertData) {
  const fields = ticket.ticket_template!.fields
  return fields.map(field => {
    return {id: field.id, error: checkField(field.id, field, expertData[field.id])}
  })
}

function checkField(key: string, field: Field, value?: any) {
  return field.validate(value)
}

export function validateAdditionalData(ticket: Ticket) {
  return {
    errorLineId: ticket.line_id !== "-1",
    errorHubId: ticket.hub_id !== "-1"
  }
}
