import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { ITicketChange } from '../../ticket_change'
import {Box, makeStyles, useTheme} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { AppState } from '../../../config/store'
import { useCurrentUser } from '../../../config/pages/shared/use_current_user'
import { User } from '../../user'
import {i18n} from "i18next";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none"
  }
}))

type Props = {
  history: ITicketChange[]
}

const datePrepare = (i18n: i18n, dS: string) => {
  moment.locale(i18n.language)
  const date = moment(dS)
  return `${date.format("HH:mm DD MMMM YYYY")}`
}

interface ItemProps {
  item: ITicketChange
  isAdmin: boolean
}

const Item = ({ item, isAdmin }: ItemProps) => {
  const { t, i18n } = useTranslation()
  const user = useSelector((state: AppState) => state.users.entities[item.user_id] as User)
  return <Box flexDirection="row">
    <Box style={{marginTop: 5}}>{datePrepare(i18n, item.created_at)} — <b>{t(item.action)}</b></Box>
    <Box style={{marginTop: 5}}>{isAdmin && user && ` ${user.name} ${user.email}`}</Box>
    <Box style={{marginTop: 5}}>{` ${item.line_name ? item.line_name : ""}`}</Box>
  </Box>
}

export default ({ history }: Props) => {
  const theme = useTheme()
  const classes = useStyles()
  const isAdmin = useCurrentUser()?.role?.code == "admin"
  if (history.length === 0)
    return <></>
  return <Timeline align="left">
      {history.slice(0, history.length - 1).map((ch, index) => <TimelineItem
        key={index}
      >
        <TimelineSeparator>
          {index > 0 ? <>
            <TimelineConnector style={{
              backgroundColor: theme.palette.primary.main,
            }} className="before-connector timeline-connector" />
            <TimelineDot
              variant="outlined"
              color="primary" />
          </>
            :
            <TimelineDot
              variant="outlined"
              style={{ marginTop: 7 }}
              color="primary" />}
          <TimelineConnector style={{
            backgroundColor: theme.palette.primary.main,
          }} />
        </TimelineSeparator>
        <TimelineContent qa-tag={`ep-${ch.action}-history-item`}>
          <Item item={ch} isAdmin={isAdmin} />
        </TimelineContent>
      </TimelineItem>)}

      <TimelineItem
        className={"missingOppositeContent"}>
        <TimelineSeparator>
          {history.length > 1 ? <><TimelineConnector style={{
            backgroundColor: theme.palette.primary.main,
          }} className="before-connector timeline-connector" />
            <TimelineDot color="primary" className={classes.root} />
          </>
            :
            <TimelineDot color="primary" className={classes.root} style={{ marginTop: 7 }} />
          }

        </TimelineSeparator>
        <TimelineContent qa-tag={`ep-${history[history.length - 1].action}-history-item`}>
          <Item item={history[history.length - 1]} isAdmin={isAdmin} />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
}