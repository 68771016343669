import {makeStyles, Theme, createStyles, List, Box, Typography, Container, Paper, Divider} from "@material-ui/core"
import { useRouteMatch, Switch, Route, matchPath, useHistory } from "react-router-dom"
import React, { useEffect } from "react"
import { MainMenu } from "../shared/main_menu"
import MiniDrawer from '../../../shared/components/mini_drawer'
import { useTranslation } from "react-i18next"
import { NavLink } from 'react-router-dom'
import {
  bildUrl,
} from "../statistics/pages/helper"
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../../store"
import moment from "moment"
import { fetchRequest } from "../../../features/entity/store/actions"
import { LOAD_EXPERT_REPORT } from "./type_expert"
import Plate from './components/plate'
import ServiceTime from './components/setvice_time'
import {activeTabColor, linkColor, tabTextWeight, textColor, textLightTitleColor} from "../../../styles/variables";
import { getHoursWork } from "./helper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    title: {
      margin: "28px 0 18px 33px",
      fontSize: 24,
      fontWeight: 500,
      color: textColor,
      lineHeight: "28px"
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      color: textColor,
      lineHeight: "11px"
    },
    links: {
      width: "260px",
      height: "19px",
      fontSize: 14,
      color: linkColor
    },
    accordion: {
      marginBottom: 15
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: "100%"
    },
    appBarSpacer: theme.mixins.toolbar,

    paper: {
      marginTop: theme.spacing(1),
      padding: "24px",
    },
    pageHead: {
      marginBottom: "30px"
    },
    tabsWrapper: {
      margin: "0px 0 0 24px",
      minWidth: 607,

      '& > *': {
        marginRight: 32,
        fontWeight: tabTextWeight,
        fontSize: 16,
        color: textLightTitleColor,
        paddingBottom: 10,
        textDecoration: "none",
        paddingTop: 10
      },
      '& > .active': {
        marginRight: 32,
        fontWeight: tabTextWeight,
        fontSize: 16,
        paddingBottom: 8,
        color: textColor,
        borderBottom: `2px solid ${activeTabColor}`
      },
      '& > *:hover': {
        borderBottom: `2px solid ${textLightTitleColor}`,
        textDecoration: "none",
        paddingBottom: 8,
      },
      '& > .active:hover': {
        borderBottom: `2px solid ${activeTabColor}`
      }
    }
  })
)

type TDashBoardParams = {
  period: "today" | "yesterday" | "week" | "month"
}

const periodMap = {
  today: {
    since: moment().subtract(0, 'd').format("YYYY-M-D"),
    till: moment().subtract(0, 'd').format("YYYY-M-D")
  },
  yesterday: {
    since: moment().subtract(1, 'd').format("YYYY-M-D"),
    till: moment().subtract(1, 'd').format("YYYY-M-D")
  },
  week: {
    since: moment().subtract(7, 'd').format("YYYY-M-D"),
    till: moment().subtract(0, 'd').format("YYYY-M-D")
  },
  month: {
    since: moment().subtract(31, 'd').format("YYYY-M-D"),
    till: moment().subtract(0, 'd').format("YYYY-M-D")
  }
}

let timerId: NodeJS.Timeout | null = null

export default () => {
  const classes = useStyles()
  const { t } = useTranslation()
  let match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const data = useSelector((state:AppState) => state.expertDashboard.data)

  const join = matchPath<TDashBoardParams>(history.location.pathname, {
    path: "/expert_dashboard/:period",
    exact: true,
    strict: true
  })

  let periods = periodMap[join?.params.period || "today"]

  const loadReports = () => {
    dispatch(fetchRequest(bildUrl("dashboard/expert", {
      ...periods
    }), {
      method: "GET"
    }, LOAD_EXPERT_REPORT))
  }
  useEffect(() => {
    if (timerId){
      clearTimeout(timerId)
    }
    loadReports()
    timerId = setInterval(loadReports, 30000)
  }, [periods])

  const menu = <List>
    <MainMenu />
  </List>
  const serviceData = { max: data?.max_time, average: data?.average_time, min: data?.min_time }
  return (<MiniDrawer
    headerTitle="Admin page"
    menu={menu}>
    <main className={classes.content + " dashbord-wrp info-window"}>
        <Box className="title-page-margin" >
          <Typography variant="h5" className={classes.pageHead}>{t("dashboard")}</Typography>
        </Box>
        <Box className={classes.tabsWrapper}>
          <NavLink to={match.path + "/today"}>{t("today")}</NavLink>
          <NavLink to={match.path + "/yesterday"}>{t("yesterday")}</NavLink>
          <NavLink to={match.path + "/week"}>{t("week")}</NavLink>
          <NavLink to={match.path + "/month"}>{t("month")}</NavLink>
        </Box>
        <Switch>
          <Route path={match.path}>
            <Container maxWidth="lg" className={classes.container}>
              <Paper className={classes.paper}>
                <Box display="flex" flexDirection="row" style={{marginTop: "8px", marginBottom: "25px"}}>
                  <p className="dash-plate-caption">
                    {t("work time") + ":"}
                  </p>
                  <p className="dash-plate-time" style={{marginLeft: "5px"}}>
                    {getHoursWork(data?.work_time || 0, t('h'), t('min'), t('sec'))}
                  </p>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="row">
                  <Plate caption={t("served")} >{data?.finished}</Plate>
                  <Plate caption={t("forwarded")} >{data?.forwarded}</Plate>
                  <Plate caption={t("total tickets")} >{data?.total}</Plate>
                </Box>
                <Box>
                  <ServiceTime data={serviceData}/>
                </Box>
              </Paper>
            </Container>
          </Route>
        </Switch>
    </main>
  </MiniDrawer>
  )
}