import React from 'react'
import moment from 'moment'

import { AppState } from '../../../store'
import { setDayAction, setTimeSlotAction, submitAction } from '../type'
import { useDispatch, useSelector } from 'react-redux'
import { TimeSlot, RemoteSyncTimeSlotContainer } from '../../../../models/time_slot'
import { Button, Box, Typography } from '@material-ui/core'
import SButton from './button'
import { zeroForm } from '../../../../shared/helper'
import DateFns from "@date-io/date-fns";
import {ru, enGB} from 'date-fns/esm/locale'
import { useTranslation } from 'react-i18next'
import {LocalizationProvider, StaticDatePicker} from "@material-ui/pickers";

export default () => {
  const { t } = useTranslation()
  const {selected_day, selectedSlotId: slotId, slots, lang} = useSelector(({ devicePage, time_slots }: AppState) => {
    let slots: TimeSlot[] = []
    for (let key in time_slots.entities) {
      slots.push(time_slots.entities[key] as TimeSlot)
    }
    return {
      selected_day: devicePage.selected_day,
      selectedSlotId: devicePage.timeSlotId,
      slots,
      lang: devicePage.city?.lang
    }
  })
  const dispatch = useDispatch()

  let locale = ru
  if (lang == "en") {
    locale = enGB
  }

  return <>
    <Typography className="page-title" variant="h2">{t('select_date_and_time')}</Typography>
    <Box className="time-slot-data-picker">
      <LocalizationProvider dateAdapter={DateFns} locale={locale}>
        <StaticDatePicker
          showToolbar={false}
          onChange={(e) => dispatch(setDayAction(moment(e)))}
          minDate={moment()}
          value={selected_day}
          renderInput={() => <></>}/>
      </LocalizationProvider>
    </Box>
    <RemoteSyncTimeSlotContainer
      customUrl={"time_slots/free?date=" + selected_day.format("YYYY-M-D")}
      customPrefix={"service_api/"} />
    <Box className="time-slots">
      {slots.map(slot => <Button
        key={slot.id}
        variant={slotId === slot.id ? "contained" : "outlined"}
        disableRipple={true}
        style={{boxShadow: "none"}}
        onClick={() => dispatch(setTimeSlotAction(slot.id))}
        color={slotId === slot.id ? "primary" : "default"}
      >{zeroForm(slot.since_hour)}:{zeroForm(slot.since_min)} — {zeroForm(slot.till_hour)}:{zeroForm(slot.till_min)}</Button>)}
    </Box>
    <Box flexGrow={1} />
    <SButton variant={"filled"} size="normal" disable={!slotId}
      onClick={() => slotId ? dispatch(submitAction()) : null}>
      <Box>{t('signup')}</Box>
    </SButton>
  </>
}