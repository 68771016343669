import React from 'react'
import TableComponent from '../../features/entity/components/table'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../config/store'
import { buildMapState, buildMapDispatch } from '../../features/entity/containers/list'
import { EntityId } from '../../features/entity/type'
import { Device } from "./device"

const rawStatToProps = buildMapState(Device)

interface IOwnProps {
  hub_id: EntityId,
  tabs?: React.ReactElement
}

const mapStateToProps = (state: AppState, { hub_id }: IOwnProps) => {
  const res = rawStatToProps(state, { editable: true })
  const filtered = (res.filtered as Device[]).filter(dv => (dv.hub_id === hub_id))
  return {
    ...res,
    filtered,
    hub_id,
    attributes: res.attributes.slice(0, 5)
  }
}

const mapDspToProp = buildMapDispatch(Device)

const connector = connect(mapStateToProps, mapDspToProp)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector((props: PropsFromRedux) => <TableComponent {...{
  ...props,
  onCreate: (dv:Device) =>{
    dv.hub_id = props.hub_id
    return props.onCreate(dv)
  }
}} />)