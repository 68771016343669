
type THttpMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH'

export default (method: THttpMethod = 'GET', body: any = null) => {
  const parametrs = body ? JSON.stringify(body) : null
  return {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: parametrs
  }
}