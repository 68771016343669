import React, { ReactNode } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { useStore } from 'react-redux'
import { AppState } from '../../store'
import { TRole } from '../../../features/policy/type'
import { useTranslation } from 'react-i18next'
import { activeTabColor, tabTextWeight, textColor, textLightTitleColor } from "../../../styles/variables";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: "auto",
    margin: "0px 0 0 24px",
    minWidth: 607,

    '& > *': {
      marginRight: 32,
      fontWeight: tabTextWeight,
      fontSize: 16,
      color: textLightTitleColor,
      paddingBottom: 10,
      textDecoration: "none",
      paddingTop: 10
    },
    '& > .active': {
      marginRight: 32,
      fontWeight: tabTextWeight,
      fontSize: 16,
      paddingBottom: 8,
      color: textColor,
      borderBottom: `2px solid ${activeTabColor}`
    },
    '& > *:hover': {
      borderBottom: `2px solid ${textLightTitleColor}`,
      textDecoration: "none",
      paddingBottom: 8,
    },
    '& > .active:hover': {
      borderBottom: `2px solid ${activeTabColor}`
    }
  }
}))

type Props = {
  to: string
  children: ReactNode | string
  roles: TRole[]
}

const PolicyLink = (props: Props ) => {
  const { policy }: AppState = useStore().getState()
  if (props.roles.includes(policy.currentRole? policy.currentRole : "")) {
    return <NavLink {...props} />
  }else{
    return <></>
  }
}

export default ({ subPath }: { subPath: string }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return <Box className={classes.wrapper}>
    <PolicyLink to={subPath + '/towns'} roles={["admin"]}>{t("towns")}</PolicyLink>
    <PolicyLink to={subPath + '/hubs'} roles={["admin", "manager"]}>{t("hubs")}</PolicyLink>
    <PolicyLink to={subPath + '/lines'} roles={["admin"]}>{t("lines")}</PolicyLink>
    <PolicyLink to={subPath + '/desks'} roles={["admin"]}>{t("desks")}</PolicyLink>
    <PolicyLink to={subPath + '/users'} roles={["admin"]}>{t("users")}</PolicyLink>
    <PolicyLink to={subPath + '/tickets'} roles={["admin", "manager"]}>{t("tickets")}</PolicyLink>
    <PolicyLink to={subPath + '/templates'} roles={["admin"]}>{t("templates")}</PolicyLink>
    <PolicyLink to={subPath + '/tic_tags'} roles={["admin"]}>{t("tags")}</PolicyLink>
    <PolicyLink to={subPath + '/sms'} roles={["admin"]}>{t("sms")}</PolicyLink>
  </Box>
}
