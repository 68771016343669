import React, {useEffect, useState} from 'react'
import InputMask from 'react-input-mask'
import {TextField} from "@material-ui/core";
import {MIN_PHONE_NUMBER} from "../device/terminal/phone";
import parsePhoneNumber from 'libphonenumber-js'

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void,
  phone_code: string,
  phone: any,
  setPhone: any
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props
  const defaultPhoneCode = '+' + other.phone_code
  const phone_code = '+' + other.phone_code.replace(/9/g, "\\9") + "\\"
  const [isPaste, setPaste] = useState(false)

  return (
      <InputMask
        {...other}
        placeholder={defaultPhoneCode}
        mask={phone_code + " 9999999999"}
        maskChar={null}
        value={props.phone}
        onInput={(e) => {
          let splitPhone = e.currentTarget.value.split(" ")

          if (!isPaste) {
            if (splitPhone[1]?.length < MIN_PHONE_NUMBER) {
              props.setPhone(e.currentTarget.value)
            } else if (defaultPhoneCode === '+7'
              && /^\d+$/.test(splitPhone[0])
              && splitPhone[0].length == MIN_PHONE_NUMBER - 1) {
              props.setPhone("+7 " + e.currentTarget.value)
            } else if (/^\d+$/.test(splitPhone[1])){
              props.setPhone(e.currentTarget.value)
            }
          } else {
            setPaste(false)
          }

          if (e.currentTarget.value === '+' + props.phone_code + ' '
              || e.currentTarget.value === '+' + props.phone_code)
            props.setPhone(undefined)
        }}
        onPaste={(e)=>{
            let number = e.clipboardData.getData("Text").replace(/\D+/g,"")
            let parsePhone = parsePhoneNumber("+" + number)
            let subNumber = number.substr(props.phone_code.length,
              parsePhone?.formatNational() != undefined ? parsePhone?.formatNational().length : MIN_PHONE_NUMBER - 1)

            setPaste(true)
            props.setPhone(`+${other.phone_code} ` + subNumber)
          }
        }
      />
  )
}

interface IProps {
  phone_code: string
  value: any
  label: string
  onChange: (value: string) => void
  minPhoneNumber: number
  className?: string
}

export default function FormattedInputs(props: IProps) {

  const [phone, setPhone] = useState<string | undefined>()
  useEffect(() => {
    if (!phone) {
      setPhone(props.value)
    }
  }, [props.value])

  useEffect(() => {
    props.onChange(phone || "")
  }, [phone])

  const copyToClipboard = (text: string) => {
    // @ts-ignore
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      // @ts-ignore
      return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      }
      finally {
        document.body.removeChild(textarea);
      }
    }
  }

  return (
    <TextField
        label={props.label}
        className={`ep-client-data cl-field ${(props.value ? 'phone-active-label' : '')} ${props.className ? props.className : ''}`}
        InputProps={{
          inputComponent: TextMaskCustom as any,
          inputProps: {
            qaTag:'phone',
            phone: phone,
            setPhone: setPhone,
            phone_code: props.phone_code,
          }
        }}
        onCopy={() => {
          let selectedText = window?.getSelection()?.toString()
          if (phone && selectedText && selectedText.length >= phone.length - 1) {
            copyToClipboard(phone?.replace(" ", '') || '')
          }
        }}
    />
  )
}
