import {put, takeEvery} from 'redux-saga/effects'
import {loadHistory, TICKETS_HISTORY_LOAD} from '../../../models/ticket/saga'
import {endLoadReportAction, ILoadReportAction, LOAD_REPORT, setTaskAction} from "./type";
import {bildUrl} from "./pages/helper";
import {getApiResponse} from "../authentication/reducers/sagas";

function* loadReport({typeStatistics, params}: ILoadReportAction) {
  yield put(endLoadReportAction([], true))
  const url = bildUrl("/statistics/async/" + typeStatistics, params);
  const response = yield getApiResponse(url, 'GET')
  yield put(setTaskAction(response.task_id))
}

export function* statisticsSaga() {
  yield takeEvery(LOAD_REPORT, loadReport)
  yield takeEvery(TICKETS_HISTORY_LOAD, loadHistory)
}