import React from 'react'
import PageAdmin from './admin_page'
import { AppState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'

const mapState = (state: AppState) => {
  return {
    currentRole: state.policy.currentRole
  }
}

const connector = connect(mapState, {})
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(({ currentRole }: PropsFromRedux) => <PageAdmin currentRole={currentRole!} />)