import React, { useState } from 'react'

import { Grid, Button, Table, TableBody, TableRow, TableCell, TableHead, Paper, makeStyles, Typography } from '@material-ui/core'
import { TEntityClass, TMappedState } from '../type'
import { BaseEntity } from "../BaseEntity"
import { TEvent, BaseAttribute } from '../attributes/base_attribute'

export interface Props<TEntity extends BaseEntity> {
  onDelete(entity: TEntity): void
  onUpdate(entity: TEntity): void
  attributes: BaseAttribute[]
}

interface IItemProps<TEntity extends BaseEntity> {
  entity: TEntity
  onDelete(entity: TEntity): void
  onUpdate(entity: TEntity): void
  mappedState: TMappedState
}

function ShowItemMode<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>(props: IItemProps<TEntity>) {
  const { entity, mappedState } = props
  return <React.Fragment>
    {(entity.constructor as TEClass).attributes.map(attr => <TableCell key={attr.key}>
      {attr.showRender(entity, mappedState)}
      </TableCell>
    )}
  </React.Fragment>
}


function Item<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>(props: IItemProps<TEntity>) {
  const { entity, onDelete, mappedState } = props
  const [editMode, setEditMode] = useState(false)
  const [editingEntity, setEditingEntity] = useState(entity)
  const onChange = (attribute: BaseAttribute) => (event: TEvent) => {
    setEditingEntity(attribute.change(editingEntity, event))
  }
  const onSaveClick = () => {
    setEditMode(false)
    props.onUpdate(editingEntity)
  }
  const attributes = (editingEntity.constructor as TEClass).attributes
  return <TableRow className="cy_entity_item">
    {editMode ?
      attributes.map(attr => <TableCell key={attr.key}>
        {attr.editRender(onChange(attr), editingEntity, mappedState)}
      </TableCell>)
      :
      <ShowItemMode {...props} />
    }

    <TableCell>
      <Button className="cy_entity_delete" variant="contained" color="primary" onClick={() => onDelete(entity)}>
        delete
      </Button>
    </TableCell>
    <TableCell>
      {editMode ?
        <Button className="cy_entity_save" variant="contained" color="primary" onClick={onSaveClick}>
          Save
        </Button>
        :
        <Button className="cy_entity_edit" variant="contained" color="primary" onClick={() => setEditMode(true)}>
          Edit
        </Button>
      }
    </TableCell>
  </TableRow>
}

type TProps<TEntity extends BaseEntity> = Props<TEntity> & TMappedState


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    paddingBottom: theme.spacing(4),
  },
  thead: {
    fontWeight: 600
  }
}))

export default function <TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>(props: TProps<TEntity>) {
  
  const classes = useStyles()
  return <Grid item xs={12}>
    <Paper className={classes.paper}>
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      Entity
    </Typography>
    <Table size="small">

      <TableHead>
        <TableRow>
          {props.attributes.map(attr => <TableCell className={classes.thead} key={attr.key}>{attr.key}</TableCell>)}
          <TableCell className={classes.thead} >delete</TableCell>
          <TableCell className={classes.thead} >edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.filtered.map((entity: BaseEntity) => <Item key={entity.id} entity={entity} mappedState={props} onDelete={props.onDelete} onUpdate={props.onUpdate} />
        )}
      </TableBody>
    </Table>
  </Paper>
  </Grid>
}
