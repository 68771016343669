import React, { useState } from "react"
import { connect, ConnectedProps} from "react-redux"
import { setLineIdAction, goNextAction } from "../type"
import {Typography, Box, Button} from "@material-ui/core"
import { AppState } from "../../../store"
import SButton from './button'
import { useTranslation } from "react-i18next"

const mapStateToProps = ({devicePage}: AppState) => {
  return {
    lines: devicePage.lines
  }
}

const mapDispatchToProps = {
  setLineIdAction,
  goNextAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromReduc = ConnectedProps<typeof connector>

export default connector(({lines, setLineIdAction, goNextAction}: PropsFromReduc) => {

  const { t } = useTranslation()

  const [selectLineId, setSelectLineId] = useState()

  const onSelectQueue = () => {
    setLineIdAction(selectLineId)
    goNextAction()
  }

  return <>
    <Typography className="phone-header" variant="h2">{t('select line')}</Typography>
    {
      lines?.map(line => {
        return <Button qa-tag="line-term-btn"
          disableRipple={true}
          key={line.id}
          style={{ margin: "10px", marginLeft: 0, marginBottom: 0 }}
          variant="outlined"
          color="secondary"
          onClick={() => setSelectLineId(line.id)}
          className={"default-button default-button-terminal " + ( line.id === selectLineId ? "active-default-button-terminal" : "")}>
          {line.display_name}
        </Button>
      })
    }

    <Box flexGrow={1} />
    <SButton variant={"filled"} size="normal" disable={!selectLineId}
      onClick={() => onSelectQueue()}>
      <Box qa-tag="confirm-line">{t('next')}</Box>
    </SButton>
    {/* <Mc1 /> */}
  </>
})
