import { BaseAttribute, BaseEntity } from "../../features/entity"
import { EntityId } from "../../features/entity/type"
import { BelongsToAttribute } from "../../features/entity/attributes/belongs_to_attribute"
import { BooleanAttribute } from "../../features/entity/attributes/boolean_attribute"
import moment from 'moment'

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BaseAttribute('kind'),
  new BaseAttribute('hint'),
  new BaseAttribute('placeholder'),
  new BaseAttribute('format'),
  new BaseAttribute('options'),
  new BaseAttribute('position'),
  new BooleanAttribute('required'),
  new BelongsToAttribute({
    key: "ticket_template_id",
    parentClassStorageEntityKey: "ticket_templates",
    displayAttribute: "display_name"
  })
]

type TFieldKind = "multiselect" | "select" | "string" | "integer" | "date"

export class Field extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Field"
  display_name: string = ""
  kind: TFieldKind = "string"
  hint: string = ""
  placeholder: string = ""
  format: string = ""
  options: string = ""
  position: number = 0
  required: boolean = false
  ticket_template_id: EntityId = "-1"
  constructor(id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
  }
  validate(value?: any): boolean {
    if (!this.required) {
      return false
    }

    switch (this.kind) {
      case "date":
        return !moment(value as string, "YYYY-M-D").isValid()

      case "integer":
        return value === null || value === undefined

      default:
        return !value
    }
  }
}
