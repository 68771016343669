import React, {useEffect, useState} from "react"
import "moment/locale/ru"
import {DateRange, StaticDateRangePicker} from "@material-ui/pickers"
import {Box, ClickAwayListener, Fade, Grid, Popper, Typography} from "@material-ui/core";
import DateFns from "@date-io/date-fns";
import {enGB, ru} from 'date-fns/esm/locale'
import { LocalizationProvider } from '@material-ui/pickers';
import {useTranslation} from "react-i18next";
import DateTextEdit from "./date_textedit";
import moment from "moment";

type Props = {
  open: boolean
  val: DateRange<Date>
  setVal: (from: Date, to: Date) => void
  close: () => void
  anchor: HTMLElement | undefined
  resetDate: () => void
}

export default ({open, val, setVal, close, anchor, resetDate}: Props) => {

  const {t} = useTranslation()

  const lang = t('current lang')

  let locale = ru
  if (lang == "en") {
    locale = enGB
  }

  const [defaultValSet, setDefaultValSet] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<DateRange<Date>>(val)
  const [startDate, setStartDate] = useState<string>(moment(val[0]).format('DD/MM/YYYY'));
  const [endDate, setEndDate] = useState<string>(moment(val[1]).format('DD/MM/YYYY'));

  useEffect(() => {
    if (defaultValSet)
      if (dateRange && dateRange.length > 0 && !endDate.match(/\s/g)) {
        const startDate = dateRange[0]
        const endDate = dateRange[1]
        handleDateChange([moment(startDate, 'DD/MM/YYYY').toDate(), moment(endDate, 'DD/MM/YYYY').toDate()])
      }
    else
      setDefaultValSet(true)
  }, [startDate, endDate])

  const handleDateChange = (e: DateRange<Date>) => {
    if (e[0]) {
      setStartDate(moment(e[0]).format('DD/MM/YYYY'))
    }

    if (e[1]) {
      setEndDate(moment(e[1]).format('DD/MM/YYYY'))
    }

    setDateRange([e[0], e[1]])
    setVal(e[0] || new Date(), e[1] || new Date());
  }

  return <Popper open={open}
                 anchorEl={anchor}
                 placement={"bottom"}
                 transition
                 className="date-popper">
    {({TransitionProps}) => (
      <ClickAwayListener onClickAway={close}>
        <Fade {...TransitionProps}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
              <Grid container>
                <Grid item xs={6}>
                  <Typography className="date-range-label" align="center">{t('origin')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="date-range-label" align="center">{t('end')}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" flexDirection="row">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="center">
                    <DateTextEdit className="date-range-date"
                                  value={startDate}
                                  onChange={(e) => {
                                  setStartDate(e.target.value)
                    }}/>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="center">
                    <DateTextEdit className="date-range-date"
                                  value={endDate}
                                  onChange={(e) => {
                                  setEndDate(e.target.value)
                    }}/>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" flexDirection="row">
              <LocalizationProvider dateAdapter={DateFns} locale={locale}>
                <StaticDateRangePicker
                  displayStaticWrapperAs="desktop"
                  value={dateRange}
                  onChange={handleDateChange}
                  renderInput={() => (
                    <></>
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <div onClick={() => {
                  setDateRange(val)
                  resetDate()
                }}
                className="clear-filter-text">
                <span>{t('reset date')}</span>
              </div>
            </Box>
          </Box>
        </Fade>
      </ClickAwayListener>
    )}
  </Popper>
}
