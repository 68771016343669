import { BaseAttribute, BaseEntity, buildStore, buildTableContainer, buidlRemoteSyncContainer } from "../features/entity"
import { EntityId, EntityReducer } from "../features/entity/type"
import { BelongsToAttribute } from "../features/entity/attributes/belongs_to_attribute"

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BelongsToAttribute({
    key: "hub_id",
    parentClassStorageEntityKey: "hubs",
    displayAttribute: "display_name"
  }),
  new BelongsToAttribute({
    key: "line_id",
    parentClassStorageEntityKey: "lines",
    displayAttribute: "display_name"
  })
]

export class Desk extends BaseEntity{
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Desk"
  display_name: string
  line_id: EntityId = "-1"
  hub_id: EntityId = "-1"
  constructor(display_name:string="",  id: EntityId = "-1"){
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
  }
}

export const DeskReducer: EntityReducer<Desk> = buildStore(Desk)
export const RemoteSyncDeskContainer = buidlRemoteSyncContainer(Desk)
export const ListDeskContanier = buildTableContainer(Desk)
export function loadDesk(deskId: EntityId) {
  return {
    type: "FETCH_ACTION",
    url: "desks/"+deskId,
    options: {method: "GET"},
    callbackAction: "desks_SINGLE_LOAD_ACTION"
  }
}