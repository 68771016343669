import {EntityState} from "../../features/entity/type";

const initStateModes: EntityState<any> = {
  entities: {0: {id: "auto", mode: "auto"}, 1: {id: "manual", mode: "manual"}}
}

export function ModesReducer(state: EntityState<any> = initStateModes, action: any): EntityState<any> {
  switch (action.type) {
    default:
      return state
  }
}