import React from 'react'
import { BaseAttribute, BaseEntity } from '../../features/entity'
import { TMappedState, EntityId, TAditionalStore } from '../../features/entity/type'
import { TEvent } from '../../features/entity/attributes/base_attribute'
import { Column } from 'material-table'
import MultiSelectField from "../../features/entity/components/table_multiple";
import {arrayToString} from "../../shared/helper";

interface ICollItem {
  id: EntityId,
  display_name: string
}

export class MultiSelectAttribute extends BaseAttribute {
  childKey: string
  constructor(key: string, childKey: string) {
    super(key)
    this.childKey = childKey
  }
  getDisplayValue(source: BaseEntity, state: TMappedState): string {
    const entity: any = source //state.mapEntitiesById[source.id]
    return entity[this.key].length
  }
  change<T extends BaseEntity>(source: T, event: TEvent): T {
    return Object.assign(Object.create(source), {
      [this.key]: event.target.value as EntityId[]
    })
  }

  mapState(appState: any): { [key: string]: BaseEntity } {
    return {
      [this.childKey]: appState[this.childKey]
    } as { [key: string]: BaseEntity }
  }

  buildColumn(additionals: TAditionalStore): null | Column<BaseEntity> {
    if (this.hide)
      return null

    const collection: ICollItem[] = []
    if (additionals[this.childKey])
      for (const id in additionals[this.childKey].entities) {
        collection.push(additionals[this.childKey].entities[id] as ICollItem)
      }

    return {
      title: this.key, field: this.key,
      initialEditValue: [],
      editable: this.editable,
      render: (data: any) => <>{collection.length > 0 ? arrayToString(data.tag_ids, collection) : ""}</>,
      editComponent: x => {
        return <MultiSelectField
          className={''}
          errorClassName={''}
          collection={collection}
          // @ts-ignore
          val={x.rowData.tag_ids || []}
          setVal={(e) => {
            x.onChange(e)
          }}/>
      }
    }
  }
}