import React from 'react'
import {
  Dialog,
  Theme,
  Typography,
  IconButton,
  createStyles,
  makeStyles, withStyles,
} from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 20,
      minWidth: 200
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      marginRight: 20,
      marginLeft: 20
    }
  }))

const DialogContent = withStyles(() => ({
  root: {
    padding: 20,
  },
}))(MuiDialogContent)

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: 20,
  },
}))(MuiDialogActions)

type TProps = {
  children: React.ReactElement
  actions?: React.ReactElement
  title: string,
  titleAdditional?: string,
  open: boolean,
  setOpen: (v: boolean) => void
  className?: string
}

export const Modal = ({ className, actions, children, title, titleAdditional = "", open, setOpen }: TProps) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return <Dialog onClose={handleClose} className={className} aria-labelledby="simple-dialog-title" open={open}>
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{title} <span className="modal-additional-text">{titleAdditional}</span></Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
    <Divider className={classes.divider} />
    <DialogContent>
      {children}
    </DialogContent>
    {
      actions
        ? <DialogActions>
            {actions}
          </DialogActions>
        : null
    }
  </Dialog>
}