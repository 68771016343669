import React, { ReactNode } from 'react'
import { Box, makeStyles } from "@material-ui/core"
import { activeTabColor, inactiveColor, textColor, textLightTitleColor } from "../../../../styles/variables";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 840,
    backgroundColor: (p:TProps) => p.variant === "filled" ? (p.disable ? inactiveColor : activeTabColor) : "#FFF",
    borderWidth: (p:TProps) => p.variant === "filled" ? 0 : 3,
    borderStyle: "solid",
    borderColor: (p:TProps) => p.disable ? inactiveColor : activeTabColor,
    borderRadius: 10,
    height: (p:TProps) => p.size === "normal" ? 100 : 205,
    margin: 15,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    color: (p:TProps) => p.disable? textLightTitleColor : textColor,

    "& > div": {
      fontSize: 24,
      textTransform: (p:TProps) => p.size === "normal" ? undefined : "uppercase",
      "&:first-child": {
        fontSize: 34,
      }
    }
  },
}))

type TProps = {
  onClick?: () => void
  children: ReactNode | string
  variant?: "filled" | "bordered"
  size?: "big" | "normal"
  disable?: boolean
}

export default (props: TProps) => {
  const { onClick, children } = props
  const classes = useStyles(props)
  return <Box onClick={onClick} className={classes.wrapper}>
    {children}
  </Box>
}