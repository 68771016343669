import { Moment } from "moment"
import { EntityId } from "../../../features/entity/type"
import { Ticket } from "../../../models/ticket"
import { Desk } from "../../../models/desk"
import { Hub } from "../../../models/hub"
import { User } from "../../../models/user"
import { City } from "../../../models/city"
import { Device } from "../../../models/device/device"
import {Line} from "../../../models/line";
import {Sound} from "../../../models/sound";

export const LOADING_PAGE = "LOADING"
export const TV_PAGE = "TV"
export const AUTH_CODE_PAGE = "AUTH_CODE"
export const TERMINAL_MAIN_PAGE = "TERMINAL_MAIN"
export const TERMINAL_TICKET_PRINT_PAGE = "TERMINAL_TICKET_PRINT"
export const TERMINAL_SLOTS_PAGE = "TERMINAL_SLOTS"
export const TERMINAL_CLIENT_DATA_PAGE = "TERMINAL_CLIENT_DATA"
export const TERMINAL_LOYALTY_PAGE = "TERMINAL_LOYALTY_PAGE"
export const TERMINAL_SELECT_QUEUE = "TERMINAL_SELECT_QUEUE"
export const TERMINAL_RESULT_OPERATION_PAGE = "TERMINAL_RESULT_OPERATION"
export const TERMINAL_CHECK_IN_PAGE = "TERMINAL_CHECK_IN"
export const TERMINAL_ERORR_PAGE = "TERMINAL_ERORR"
export const MOVE_TO_MAIN = "DEVICE_PAGE_TO_MAIN"
export const MOVE_RETRY = "DEVICE_PAGE_AGAIN"

export type TPage = typeof LOADING_PAGE | typeof TV_PAGE | typeof AUTH_CODE_PAGE |
  typeof TERMINAL_MAIN_PAGE | typeof TERMINAL_TICKET_PRINT_PAGE | typeof TERMINAL_SLOTS_PAGE |
  typeof TERMINAL_CLIENT_DATA_PAGE | typeof TERMINAL_LOYALTY_PAGE | typeof TERMINAL_RESULT_OPERATION_PAGE |
  typeof TERMINAL_CHECK_IN_PAGE | typeof TERMINAL_ERORR_PAGE | typeof TERMINAL_SELECT_QUEUE

export interface TDay {
  day: number,
  month: number,
  year: number
}

export interface IClientData {
  phone: string
  first_name: string
  last_name: string
}

export interface ITicketInQueue {
  ticket: Ticket
}

export interface ITicketInDesk extends ITicketInQueue {
  desk: Desk
  user: User
  sound: Sound
}

export interface DevicePageState {
  page: TPage
  selected_day: Moment
  ticketsInQueue: ITicketInQueue[]
  ticketsOnDesk: ITicketInDesk[]
  clientData?: IClientData
  timeSlotId?: EntityId
  lineId?: EntityId,
  loyalty?: string,
  lines: Line[],
  errorMessag?: string
  deviceType?: TDeviceType
  hub?: Hub
  city?: City
  ticket?: Ticket
  audioTag?: HTMLAudioElement
  device?: Device
}

type TTerminalModes = "PRE-ENTRIES" | "WITHOUT-PRE-ENTRIES"

export const CHANGE_PAGE = "DEVICE_PAGE_CHANGE_PAGE"

export interface IChangePageAction {
  type: typeof CHANGE_PAGE
  page: TPage
}

export const USER_SELECT_MODE = "DEVICE_PAGE_USER_SELECT_MODE"

export interface IUserSelectModeAction {
  type: typeof USER_SELECT_MODE
  mode: TTerminalModes
}

export const userSelectModeAction = (mode: TTerminalModes): IUserSelectModeAction => {
  return {
    type: USER_SELECT_MODE,
    mode
  }
}


export const USER_INPUT_PHONE = "DEVICE_PAGE_USER_INPUT_PHONE"

export interface IUserInputPhoneAction {
  type: typeof USER_INPUT_PHONE
  phone: string
}

export const userInputPhoneAction = (phone: string): IUserInputPhoneAction => {
  return {
    type: USER_INPUT_PHONE,
    phone
  }
}

export const SET_ERROR = "DEVICE_PAGE_SET_ERROR"

export interface ISetErrorAction {
  type: typeof SET_ERROR
  error?: string
}

export const setErrorAction = (error?: string): ISetErrorAction => {
  return {
    type: SET_ERROR,
    error
  }
}

export const SET_DEVICE_TYPE = "DEVICE_PAGE_SET_DEVICE_TYPE"

export interface ISetDeviceTypeAction {
  type: typeof SET_DEVICE_TYPE
  deviceType: TDeviceType
}

export const setDeviceTypeAction = (deviceType: TDeviceType): ISetDeviceTypeAction => {
  return {
    type: SET_DEVICE_TYPE,
    deviceType
  }
}

export const SET_DAY_ACTION = "DEVICE_PAGE_SET_DAY_ACTION"

export interface ISetDayActionAction {
  type: typeof SET_DAY_ACTION
  date: Moment
}

export const setDayAction = (date: Moment): ISetDayActionAction => {
  return {
    type: SET_DAY_ACTION,
    date
  }
}

export const SET_TIME_SLOT = "DEVICE_PAGE_SET_TIME_SLOT"

export interface ISetTimeSlotAction {
  type: typeof SET_TIME_SLOT
  timeSlotId?: EntityId
}

export const setTimeSlotAction = (timeSlotId?: EntityId): ISetTimeSlotAction => {
  return {
    type: SET_TIME_SLOT,
    timeSlotId
  }
}

export const SET_LINE_ID = "SET_LINE_ID"

export interface ISetLineAction {
  type: typeof SET_LINE_ID
  lineId?: EntityId
}

export const setLineIdAction = (lineId?: EntityId): ISetLineAction => {
  return {
    type: SET_LINE_ID,
    lineId
  }
}

export const SET_LOYALTY = "SET_LOYALTY"

export interface ISetLoyaltyAction {
  type: typeof SET_LOYALTY
  loyalty: string
}

export const setLoyaltyAction = (loyalty: string): ISetLoyaltyAction => {
  return {
    type: SET_LOYALTY,
    loyalty
  }
}

export const SET_CLIENT_DATA = "DEVICE_PAGE_SET_CLIENT_DATA"

export interface ISetClientDataAction {
  type: typeof SET_CLIENT_DATA
  clientData?: IClientData
}

export const setClientDataAction = (clientData?: IClientData): ISetClientDataAction => {
  return {
    type: SET_CLIENT_DATA,
    clientData
  }
}

export const SUBMIT = "DEVICE_PAGE_SUBMIT"

export interface ISubmitAction {
  type: typeof SUBMIT
}

export const submitAction = (): ISubmitAction => {
  return {
    type: SUBMIT
  }
}

export const INPUT_AUTH_CODE = "DEVICE_PAGE_INPUT_AUTH_CODE"

export interface IInputAuthCodeAction {
  type: typeof INPUT_AUTH_CODE
  auth_code: string
}

export const inputAuthCodeAction = (auth_code: string): IInputAuthCodeAction => {
  return {
    type: INPUT_AUTH_CODE,
    auth_code
  }
}

export const SET_TICKETS_IN_QUEUE = "DEVICE_PAGE_SET_TICKETS_IN_QUEUE"

export interface ISetTicketsInQueueAction {
  type: typeof SET_TICKETS_IN_QUEUE
  ticketsInQueue: ITicketInQueue[]
  ticketsOnDesk: ITicketInDesk[]
}

export const setTicketsInQueueAction = (ticketsInQueue: ITicketInQueue[], ticketsOnDesk: ITicketInDesk[]): ISetTicketsInQueueAction => {
  return {
    type: SET_TICKETS_IN_QUEUE,
    ticketsInQueue,
    ticketsOnDesk
  }
}

export const SET_LINES = "SET_LINES"

export interface ISetLinesAction {
  type: typeof SET_LINES,
  lines: Line[]
}

export const setLinesAction = (lines: Line[]): ISetLinesAction => {
  return {
    type: SET_LINES,
    lines
  }
}

export const GO_BACK = "DEVICE_PAGE_GO_BACK"

export interface IGoBackAction {
  type: typeof GO_BACK
}

export const goBackAction = (): IGoBackAction => {
  return {
    type: GO_BACK
  }
}

export const GO_NEXT = "DEVICE_PAGE_GO_NEXT"

export interface IGoNextAction {
  type: typeof GO_NEXT
}

export const goNextAction = (): IGoNextAction => {
  return {
    type: GO_NEXT
  }
}


export const SET_CURRENT_HUB = "DEVICE_PAGE_SET_CURRENT_HUB"

export interface ISetCurrentHubAction {
  type: typeof SET_CURRENT_HUB
  hub?: Hub
}

export const setCurrentHubAction = (hub?: Hub): ISetCurrentHubAction => {
  return {
    type: SET_CURRENT_HUB,
    hub
  }
}

export const SET_CURRENT_CITY = "DEVICE_PAGE_SET_CURRENT_CITY"

export interface ISetCurrentCityAction {
  type: typeof SET_CURRENT_CITY
  city?: City
}

export const setCurrentCityAction = (city?: City): ISetCurrentCityAction => {
  return {
    type: SET_CURRENT_CITY,
    city
  }
}


export const SET_CURRENT_DEVICE = "DEVICE_PAGE_SET_CURRENT_DEVICE"

export interface ISetCurrentDeviceAction {
  type: typeof SET_CURRENT_DEVICE
  device: Device
}

export const setCurrentDeviceAction = (device: Device): ISetCurrentDeviceAction => {
  return {
    type: SET_CURRENT_DEVICE,
    device
  }
}

export const SET_TICKET = "DEVICE_PAGE_SET_TICKET"

export interface ISetTicketAction {
  type: typeof SET_TICKET
  ticket?: Ticket
}

export const setTicketAction = (ticket?: Ticket): ISetTicketAction => {
  return {
    type: SET_TICKET,
    ticket
  }
}

export const REPEAT_NUMBER = "DEVICE_PAGE_REPEAT_NUMBER"

export interface IRepeatNumberAction {
  type: typeof REPEAT_NUMBER
}

export const repeatNumberAction = (): IRepeatNumberAction => {
  return {
    type: REPEAT_NUMBER
  }
}


export const CANCLE = "DEVICE_PAGE_CANCLE"

export interface ICancleAction {
  type: typeof CANCLE
}

export const cancleAction = (): ICancleAction => {
  return {
    type: CANCLE
  }
}


export const SET_AUDIO_RING = "TV_SET_AUDIO_RING"

export interface ISetAudioRingAction {
  type: typeof SET_AUDIO_RING
  audio?: HTMLAudioElement
}

export const setAudioRingAction = (audio: HTMLAudioElement | undefined): ISetAudioRingAction => {
  return {
    type: SET_AUDIO_RING,
    audio
  }
}

export type TDeviceAction = IChangePageAction |
  IUserSelectModeAction | IUserInputPhoneAction | ISetErrorAction | ISetDeviceTypeAction |
  ISetDayActionAction | ISetTimeSlotAction | ISetClientDataAction | ISubmitAction |
  IInputAuthCodeAction | ISetTicketsInQueueAction | IGoBackAction | IGoNextAction |
  ISetCurrentHubAction | ISetTicketAction |
  IRepeatNumberAction | ISetAudioRingAction | ISetCurrentCityAction | ISetCurrentDeviceAction |
  ISetLineAction | ISetLoyaltyAction | ISetLinesAction

export type TDeviceType = "TERMINAL" | "TV"

export type AuthResult = {
  deviceType: TDeviceType
  deviceId: string
}
