import Table from './components/table'
import { buildStore, buidlRemoteSyncContainer } from '../../features/entity'
import { Ticket } from './ticket'

import Detail from './components/detail'

export { Ticket } from './ticket'
export const ListTicketContanier = Table
export const TicketReducer = buildStore(Ticket)
export const RemoteSyncTicketContainer = buidlRemoteSyncContainer(Ticket)
export const TicketEditPage = Detail