import { Desk } from "../../../models/desk"
import {
  IStatusChangingAction,
  STATUS_CHANGING,
  IMissClientAction,
  MISS_CLIENT,
  IReciveTicketAction,
  RECIVE_TICKET,
  ICancelingTicketAction,
  CANCEL_TICKET,
  IFinishTicketAction,
  FINISH_TICKET,
  IDeskSelectAction,
  DESK_SELECT_ACTION,
  IStartWorkSessionAction,
  START_WORK_SESSION,
  IChangeDeskAction,
  DESK_CHANGE_ACTION,
  IStopWorkSessionAction,
  STOP_WORK_SESSION,
  IForwardTicketAction,
  FORWARD_TICKET,
  IFinishAndStopTicketAction,
  FINISH_AND_STOP_TICKET,
  IClientIncomingAction,
  CLIENT_INCOMING,
  ExpertAction,
  TStatus,
  ExpertPageState,
  ISetCurrentTicketAction,
  SET_CURRENT_TICKET,
  WORK_SESSION_SET,
  IRecallAction,
  RECALL,
  FIRST_SCREEN,
  IStartWorkAction,
  START_WORK,
  ISelectAnotherLine,
  SELECT_ANOTHER_LINE,
  WEB_PUSH_READY,
  IHelpRequestAction,
  HELP_REQUEST,
  SELECT_ANOTHER_LINE_MANUAL_LINE
} from "./type"
import { Ticket } from "../../../models/ticket"
import {TClientData, TExpertData} from "../../../models/ticket/type"
import { EntityId } from "../../../features/entity/type"


export const statusChangingAction = (status: TStatus): IStatusChangingAction => {
  return {
    type: STATUS_CHANGING,
    status
  }
}

export const helpRequestAction = (): IHelpRequestAction => {
  return {
    type: HELP_REQUEST
  }
}

export const recallAction = (): IRecallAction => {
  return {
    type: RECALL
  }
}

export const missClientAction = (): IMissClientAction => {
  return {
    type: MISS_CLIENT
  }
}

export const reciveTicketAction = (): IReciveTicketAction => {
  return {
    type: RECIVE_TICKET
  }
}

export const cancelingTicketAction = (): ICancelingTicketAction => {
  return {
    type: CANCEL_TICKET
  }
}

export const finishTicketAction = (expertData: TExpertData, clientData: TClientData): IFinishTicketAction => {
  return {
    type: FINISH_TICKET,
    expertData,
    clientData
  }
}

export const selectDeskAction = (desk: Desk | null): IDeskSelectAction => ({
  type: DESK_SELECT_ACTION,
  desk
})

export const startWorkSessionAction = (): IStartWorkSessionAction => {
  return {
    type: START_WORK_SESSION
  }
}

export const changeDeskAction = (): IChangeDeskAction => ({
  type: DESK_CHANGE_ACTION
})

export const stopWorkSessionAction = (): IStopWorkSessionAction => {
  return {
    type: STOP_WORK_SESSION
  }
}

export const forwardTicketAction = (expertData: TExpertData, clientData: TClientData): IForwardTicketAction => {
  return {
    type: FORWARD_TICKET,
    expertData,
    clientData
  }
}

export const finishAndStopTicketAction = (expertData: TExpertData, clientData: TClientData): IFinishAndStopTicketAction => {
  return {
    type: FINISH_AND_STOP_TICKET,
    expertData,
    clientData
  }
}

export const clientIncomingAction = (): IClientIncomingAction => {
  return {
    type: CLIENT_INCOMING
  }
}

export const setCurrentTicketAction = (ticket: Ticket | null): ISetCurrentTicketAction => {
  return {
    type: SET_CURRENT_TICKET,
    ticket
  }
}

export const setWorkSessionAction = (workSessionId: string) => {
  return {
    type: WORK_SESSION_SET,
    workSessionId
  }
}

export const startWorkAction = (): IStartWorkAction => {
  return {
    type: START_WORK
  }
}

export const selectAnotherLineAction = (lineId: EntityId, ticketId?: EntityId): ISelectAnotherLine => {
  return {
    type: SELECT_ANOTHER_LINE,
    lineId,
    ticketId
  }
}

export const selectAnotherLineManualLineAction = (lineId: EntityId, ticketId?: EntityId): ISelectAnotherLine => {
  return {
    type: SELECT_ANOTHER_LINE_MANUAL_LINE,
    lineId,
    ticketId
  }
}

const initState: ExpertPageState = {
  selected_desk: null,
  status: FIRST_SCREEN,
  webPushReady: "NOT_LOAD_YET",
  currentTicket: null,
  expert_data: {},
  client_data: {},
  workSessionId: null
}

export default (state: ExpertPageState = initState, action: ExpertAction): ExpertPageState => {
  switch (action.type) {
    case DESK_SELECT_ACTION:
      return {
        ...state,
        selected_desk: action.desk
      }

    case STATUS_CHANGING:
      return {
        ...state,
        status: action.status
      }

    case SET_CURRENT_TICKET:
      return {
        ...state,
        currentTicket: action.ticket,
        expert_data: action.ticket ? action.ticket.expert_data : {}
      }

    case WORK_SESSION_SET:
      return {
        ...state,
        workSessionId: action.workSessionId
      }

    case FINISH_TICKET:
    case FINISH_AND_STOP_TICKET:
    case FORWARD_TICKET:
      return {
        ...state,
        expert_data: action.expertData,
        client_data: action.clientData
      }

    case WEB_PUSH_READY:
      return {
        ...state,
        webPushReady: action.status
      }



    default:
      return state
  }
} 