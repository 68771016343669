import {call, takeEvery, put} from "redux-saga/effects"

import i18next from "i18next";
import {successMessageAction, warningMessageAction} from "../../config/pages/shared/common_store";
import {fetchM} from "../../config/pages/shared/fetchM";
import {Comment} from "./comment"
import {buildActionTypeName} from "../../features/entity/store/actions";
import {getApiResponse} from "../../config/pages/authentication/reducers/sagas";

export const CREATE_COMMENT = "CREATE_COMMENT"
export const GET_COMMENTS = "GET_COMMENTS"
export const EDIT_COMMENT = "EDIT_COMMENT"
export const DELETE_COMMENT = "DELETE_COMMENT"

export interface ICreateCommentAction {
  type: typeof CREATE_COMMENT
  ticketId: string
  comment: Comment
}

export interface IGetCommentsAction {
  type: typeof GET_COMMENTS
  ticketId: string
}

export interface IEditCommentAction {
  type: typeof EDIT_COMMENT
  ticketId: string
  commentId: string
  message: string
}

export interface IDeleteCommentAction {
  type: typeof DELETE_COMMENT
  ticketId: string
  commentId: string
}

export const createCommentAction = (ticketId: string, comment: Comment): ICreateCommentAction => {
  return {
    type: CREATE_COMMENT,
    ticketId: ticketId,
    comment: comment
  }
}

export const getCommentsAction = (ticketId: string): IGetCommentsAction => {
  return {
    type: GET_COMMENTS,
    ticketId: ticketId
  }
}

export const editCommentAction = (ticketId: string, commentId: string, message: string): IEditCommentAction => {
  return {
    type: EDIT_COMMENT,
    ticketId: ticketId,
    commentId: commentId,
    message: message
  }
}

export const deleteCommentAction = (ticketId: string, commentId: string): IDeleteCommentAction => {
  return {
    type: DELETE_COMMENT,
    ticketId: ticketId,
    commentId: commentId
  }
}

function* createComment({ticketId, comment}: ICreateCommentAction) {

    const response = yield getApiResponse('/comments', 'POST', comment)

    if (response.success) {
      yield put(getCommentsAction(ticketId))
      yield put(successMessageAction(i18next.t('comment created')))
    } else {
      yield put(warningMessageAction(i18next.t(response.errors[0])))
    }
}

function* getComments({ticketId}: IGetCommentsAction) {
  const response = yield call(fetchM, `tickets/${ticketId}/comments`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  const {
    RELOAD_ACTION_TYPE
  } = buildActionTypeName(Comment)

  yield put({
    type: RELOAD_ACTION_TYPE,
    response: response
  })
}

function* editComment({ticketId, commentId, message}: IEditCommentAction) {
  const response = yield call(fetchM, `comments/${commentId}`, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      message: message
    })
  })

  if (response.success) {
    yield put(getCommentsAction(ticketId))
    yield put(successMessageAction(i18next.t('comment edited')))
  }
}

function* deleteComment({ticketId, commentId}: IDeleteCommentAction) {
  const response = yield call(fetchM, `comments/${commentId}`, {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (response.success){
    yield put(getCommentsAction(ticketId))
    yield put(successMessageAction(i18next.t('comment deleted')))
  }
}

export default function*(){
  yield takeEvery(CREATE_COMMENT, createComment)
  yield takeEvery(EDIT_COMMENT, editComment)
  yield takeEvery(DELETE_COMMENT, deleteComment)
  yield takeEvery(GET_COMMENTS, getComments)
}