import React from 'react'
import SelectDesk from './steps/select_desc'
import { AppState } from '../../store'
import { useSelector } from 'react-redux'

import SelectAnotherLine from './another_line'
import WaitSession from './steps/wait_session'
import WaitTicket from './steps/wait_ticket'
import WaitClient from './steps/wait_client'
import ClientHendling from './steps/client_handling'

export default () => {
  const page = useSelector((state: AppState) => state.expertPage.status)
  switch (page) {
    case "DESK_SELECTION":
      return <SelectDesk />

    case "WAIT_WORK_SESSION":
      return <WaitSession />

    case "WAIT_TICKET":
      return <WaitTicket />

    case "WAIT_ANOTHER_LINE":
      return <SelectAnotherLine />

    case "WAIT_CLIENT":
      return <WaitClient />

    case "CLIENT_HENDLING":
      return <ClientHendling />

    default:
      return <div>something went wrong</div>
  }
}