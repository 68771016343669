import React from 'react'
import { TEditable, BaseAttribute } from './base_attribute'
import { TAditionalStore } from '../type'
import { Column } from 'material-table'
import { BaseEntity } from '..'
import Switcher from "../../../models/ticket_template/switcher"

export class BooleanAttribute extends BaseAttribute {
  constructor(key: string, editable: TEditable = "always", hide: boolean = false) {
    super(key, editable, hide)
  }
  buildColumn(additionals: TAditionalStore): null | Column<BaseEntity> {
    if (this.hide)
      return null
    return {
      title: this.key, field: this.key,
      editable: this.editable,
      render: (row) => {
        //@ts-ignore
        const val = row[this.key]
        return <Switcher checked={val} />
      },
      editComponent: props => {
        return <Switcher
          checked={props.value}
          onChange={() => props.onChange(!props.value)}
        />
      }
    }
  }
}