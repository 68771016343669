import React from 'react'
import {FormControl, Select, MenuItem, ListItemText, Checkbox} from '@material-ui/core'
import {getCheckedIcon, getUncheckedIcon} from "../../../assets/icons";

interface ISelectItem {
  id: string
  display_name: string
}

type Props = {
  collection: ISelectItem[]
  val: string
  setVal: (v: string) => void
}

export default (props: Props) => {

  const uncheckedIcon = getUncheckedIcon()
  const checkedIcon = getCheckedIcon()

  return <FormControl className="st-select-field st-select-form">
    <Select
      labelId="select-cities-statistic-field"
      id="select-cities-statistic-field-label"
      qa-tag={"ticket-line"}
      value={props.val}
      onChange={(event) => props.setVal(event.target.value as string)}
      displayEmpty
      className="select-search st-select-field st-cities-field"
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      renderValue={(selected) => {
        const item = props.collection.find(item => item.id == selected)
        if (item)
          return item.display_name
        else
          return ""
      }}>
      {props.collection.map(c => <MenuItem key={c.id} value={c.id}>
          <ListItemText primary={c.display_name}/>
          <Checkbox checked={props.val == c.id}
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    style={{padding: '0', marginLeft: '5px'}}/>
        </MenuItem>
      )}

    </Select>
  </FormControl>
}