import React from 'react'
import { useDispatch } from 'react-redux'
import logo from '../../../logo.svg'
import { Button, Box, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { startWorkAction } from './store'

const useStyles = makeStyles(() => ({
  title: {
    margin: "20px 0 42px 0",
    fontWeight: "normal"
  },
  logo: {
    width: 355,
    height: 200
  }
}))

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  return <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={logo} alt="logo" className={classes.logo} />
      <Typography className={classes.title} variant="h5" qa-tag="expert-desk-title">{t('Expert work desk')}</Typography>
        <Button variant="contained"
          size="large"
          onClick={() => dispatch(startWorkAction())}
          color="primary" disableElevation
          qa-tag="expert-desk-start-button"
          className="primary-button">
          {t('Start')}
        </Button>
    </Box>
  </Box>
}