import { connect } from 'react-redux'
import React from 'react'

import { TEntityClass, EntityState, TStoreType, TAditionalStore, TMappedState } from '../type'
import { BaseEntity } from "../BaseEntity"
import ListComponent from '../components/list'
import { getStorageEntityKey } from "../helpers/storage_key"
import { buildActionTypeName, fetchRequest } from '../store/actions'
import buildOptions from '../helpers/fetch_option'
import { buildRaw } from '../helpers/buildRaw'

export function buildMapState<TEntity extends BaseEntity, TEntityState extends EntityState<TEntity>>
  (t: TEntityClass<TEntity>) {
  const className: string = getStorageEntityKey(t)
  return (appState: any, ownProps: {editable:boolean, tabs?: React.ReactElement}): TMappedState => {
    const targetState = appState[className]
    const aditional: TAditionalStore = {}
    const addit: TStoreType[] = t.attributes.map(attr => attr.mapState(appState))
    const mapEntitiesById: TStoreType = (targetState as TEntityState).entities
    const filtered = filteredEntities(mapEntitiesById)
    return {
      attributes: t.attributes,
      mapEntitiesById,
      additionals: { ...Object.assign(aditional, ...addit) },
      filtered,
      title: className,
      ...ownProps
    }
  }
}

export function filteredEntities(mapEntitiesById: TStoreType) {
  const filtered: BaseEntity[] = []
  for (const key in mapEntitiesById) {
    filtered.push(mapEntitiesById[key])
  }
  return filtered
}

export function buildMapDispatch<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>
  (t: TEClass, prefix: (entity: TEntity) => string = () => "") {
    const actionsTypeName = buildActionTypeName(t)
    const keySotrage = getStorageEntityKey(t)
  return {
    onDelete: (entity: TEntity) => {
      const sendedObject = buildRaw(entity, t)
      const options = buildOptions("DELETE", sendedObject)
      return fetchRequest(`${prefix(entity)}${keySotrage}/${entity.id}`, options, actionsTypeName.DELETE_ACTION_TYPE)
    },
    onUpdate: (entity: TEntity) => {
      const sendedObject = buildRaw(entity, t)
      const options = buildOptions("PATCH", sendedObject)
      return fetchRequest(`${prefix(entity)}${keySotrage}/${entity.id}`, options, actionsTypeName.UPDATE_ACTION_TYPE)
    },
    onCreate: (entity: TEntity) => {
      const sendedObject = buildRaw(entity, t)
      const options = buildOptions("POST", sendedObject)
      return fetchRequest(prefix(entity)+keySotrage, options, actionsTypeName.CREATE_ACTION_TYPE)
    }
  }
}


export function buidlConnector<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>
  (t: TEClass) {
  const mapState = buildMapState(t)
  const mapDispatch = buildMapDispatch(t)
  const connector = connect(mapState, mapDispatch)
  return connector
}

export default function buidlContainer<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>
  (t: TEClass) {
  return buidlConnector(t)(ListComponent)
}