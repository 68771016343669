import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { IMenuItemProps } from './type'

export const MenuItem = (props: IMenuItemProps) => {
  const { children, href, title } = props
  return <Link to={href} className={props.className}>
    <ListItem button>
      <ListItemIcon className="menu-icon-wrapper" qa-tag="side-menu-item">{children}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  </Link>
}
