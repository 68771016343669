import Switcher from './switcher'
import Route from './route'
import NoPerRoute  from './no_permission_route'
import reducer from './store'
import Visible from './visible'

export const PolicySwitch = Switcher
export const PolicyRoute = Route
export const NoPermissionRoute = NoPerRoute
export const PolicyReducer = reducer
export const PolicyVisible = Visible