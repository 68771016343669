import React from 'react'
import {Typography, Box} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Modal } from '../../../../shared/components/modal'
import History from "../../../../models/ticket/components/history";
import {ITicketChange} from "../../../../models/ticket_change";

type TProps = {
  title: string,
  history: ITicketChange[],
  open: boolean,
  setOpen: (v: boolean) => void
}

export default ({ title, history, open, setOpen }: TProps) => {
  const { t } = useTranslation()

  return <>
    <Modal
      title={title}
      titleAdditional={''}
      open={open}
      setOpen={setOpen}
      className={"info-session-dialog"}
      actions={<></>}>
        <Box>
          <Box>
            <Typography variant="h5" style={{ fontSize: 18, fontWeight: "normal", marginTop: 20 }}>
              {t('service history')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <History history={history || []} />
          </Box>
        </Box>
    </Modal>
  </>
}