import { put, take, call, race, select, delay } from "redux-saga/effects"
import { changePageAction } from "../../store"
import {
  setErrorAction,
  setDayAction,
  SUBMIT,
  IClientData,
  GO_BACK,
  setClientDataAction,
  GO_NEXT,
  REPEAT_NUMBER,
  CANCLE, setLinesAction
} from "../../type"
import * as API from "../../api"
import moment, { Moment } from "moment"
import { EntityId } from "../../../../../features/entity/type"
import {getClientData, getSelectedTimeSlotId, getSelectedDate, getLineId} from "."
/**
 * To implement back and moving to any window
 * refactoring like here yeild* firstWindow()  -> return || secondWindow() -> return ||
 */

export function* preEntries() {
  const currentTime = yield call(moment)
  yield put(setDayAction(moment(currentTime)))
  yield put(setClientDataAction())

  yield* phoneWindow()
}

function* phoneWindow(): any {
  yield goTerminalSelectLine()
}

function* goTerminalSelectLine() {
  /*
  yield put(setLineIdAction())
  const lines = yield getLines()

  if (lines.length > 1) {
    yield put(setLineIdAction())
    yield put(changePageAction("TERMINAL_SELECT_QUEUE"))
    const userChoose = yield race({ nexta: take(GO_NEXT), back: take(GO_BACK) })
    if (userChoose.back)
      return false

  } else if (lines.length === 1) {
    const line = lines[0]
    yield put(setLineIdAction(line.id))
  }*/

  //убрать, если нужен будет выбор очереди
  yield put(setLinesAction([]))

  yield goTerminalClientData()
}

function* goTerminalClientData() {
  yield put(changePageAction("TERMINAL_CLIENT_DATA"))
  const userChoose = yield race({ nexta: take(GO_NEXT), back: take(GO_BACK) })
  if (userChoose.back)
    return
  yield* timeslotWindow()
}


function* timeslotWindow(): any {
  yield put(changePageAction("TERMINAL_SLOTS"))
  const userChoose = yield race({ nexta: take(SUBMIT), back: take(GO_BACK) })
  if (userChoose.back){
    yield* phoneWindow()
    return
  }
  yield* tryPreEntries()
}

function* tryPreEntries(): any {
  yield put(changePageAction("LOADING"))
  const clientData: IClientData = yield select(getClientData)
  const timeSlotId: EntityId = yield select(getSelectedTimeSlotId)
  const date: Moment = yield select(getSelectedDate)
  const lineId: EntityId = yield select(getLineId)

  const res: API.IAPIResponse = yield call(API.preEntry, clientData, timeSlotId, date, lineId)

  if (res.success) {
    yield put(setErrorAction())
    yield put(changePageAction("TERMINAL_RESULT_OPERATION"))
  }
  else {
    yield put(setErrorAction(res.error))
    yield put(changePageAction("TERMINAL_ERORR"))
  }
  const { back, repeatNumber } = yield race({
    delay: delay(10000),
    back: take(GO_BACK),
    repeatNumber: take(REPEAT_NUMBER),
    ending: take(CANCLE),
  })

  if (back) {
    yield* timeslotWindow()
  } else if (repeatNumber) {
    yield* phoneWindow()
  }
}
