import React, { useRef, useEffect } from 'react'
import {
  BrowserRouter as Router
} from "react-router-dom"

import {useDispatch} from 'react-redux'
import { policy } from './policy'
import { setAudioRingAction } from './pages/device/type'
import HelpRequestDialog from './pages/shared/help_req_dialog'
import {useCurrentUser} from "./pages/shared/use_current_user";
import * as Sentry from "@sentry/react";

const AppRouter = () => {

  const dispatch = useDispatch()
  const mediaRef = useRef<HTMLAudioElement>(null)
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (mediaRef.current)
      dispatch(setAudioRingAction(mediaRef.current))
  })

  useEffect(() => {
    if (currentUser)
      Sentry.setUser({id: currentUser.id, username: currentUser.name, email:currentUser.email})
  }, [currentUser])

  return <>
    <audio src="/notify.short.mp3" ref={mediaRef} style={{ visibility: "hidden" }} />
    <Router>
      {policy}
    </Router>
    <HelpRequestDialog />
  </>
}

export default AppRouter