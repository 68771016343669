import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles, createStyles, Box, Divider
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PlateTime from './plate_time'
import {getHoursWork} from "../helper";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
    },
    divider: {
      backgroundColor: 'black'
    }
  })
)

type TProps = {
  data?: {
    title: string,
    minCaption: string,
    avgCaption: string,
    maxCaption: string,
    minTime: number
    avgTime: number
    maxTime: number
  }
}

export default ({ data }: TProps) => {
  if (!data)
    return <></>
  const classes = useStyles()
  const { t } = useTranslation()
  return <Card variant="outlined">
    <CardHeader title={data.title} classes={classes}/>
    <CardContent>
      <Box display="flex" flexDirection="row">
        <PlateTime caption={data.minCaption} children={getHoursWork(data.minTime, t('h'), t('min'), t('sec'))} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Divider className="divider-time-dash" orientation={'horizontal'} />
        </Box>
        <PlateTime caption={data.avgCaption} children={getHoursWork(data.avgTime, t('h'), t('min'), t('sec'))} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Divider className="divider-time-dash" orientation={'horizontal'} />
        </Box>
        <PlateTime caption={data.maxCaption} children={getHoursWork(data.maxTime, t('h'), t('min'), t('sec'))} />
      </Box>
    </CardContent>
  </Card>
}