import React from 'react'
import {TextField} from '@material-ui/core'
import { Ticket } from '..'
import { Field } from "../../field"
import { TExpertData } from '../type'

import SelectField from '../../../config/pages/statistics/components/select'
import MultiSelectField from '../../../config/pages/statistics/components/multiple'

import ExpertDateField from './expert_date_field'
import moment from "moment";
import {ValidationState} from "../../../shared/validation/validation_store";

type TFieldProps = {
  field: Field
  expertData: TExpertData
  setExpertData: (ed: TExpertData) => void
  ticket: Ticket
  validation?: ValidationState | null
}

const FieldEdit = (props: TFieldProps) => {

  const { field, expertData, setExpertData } = props
  if (!expertData)
    return <></>
  const validateClassName = "ep-failed-validate-field"

  let selectExpertDataName = ""
  let multiSelectExpertDataName = []

  switch (field.kind) {
    case "multiselect":
      if (expertData[field.id]) {
        // @ts-ignore
        multiSelectExpertDataName = expertData[field.id]
      }
      return <MultiSelectField
        className={"expert-select"}
        errorClassName={props.validation?.errorsExpertData?.find(val => val.id === field.id)?.error && multiSelectExpertDataName.length === 0 ? validateClassName : ""}
        collection={field.options.split(',').map((option, index) =>
          { return {id: index.toString(), display_name: option}})}
        val={multiSelectExpertDataName}
        setVal={(val) => setExpertData({ ...expertData, [field.id]: val })}
        label={field.display_name + (field.required ? "*" : "")}/>

    case "select":
      if (expertData[field.id]) {
        // @ts-ignore
        selectExpertDataName = expertData[field.id]
      }
      return <SelectField
        className={"expert-select"}
        errorClassName={props.validation?.errorsExpertData?.find(val => val.id === field.id)?.error && !selectExpertDataName ? validateClassName : ""}
        collection={field.options.split(',').map((option, index) =>
          { return {id: index.toString(), display_name: option}})}
        val={selectExpertDataName}
        setVal={(val) => setExpertData({ ...expertData, [field.id]: val })}
        label={field.display_name + (field.required ? "*" : "")}/>

    case "date":
      return <ExpertDateField setVal={(e) => setExpertData({ ...expertData, [field.id]: moment(e).format("YYYY-M-D") })}
                              expertData={expertData}
                              field={field}
                              isValid={!(props.validation?.errorsExpertData?.find(val => val.id === field.id)?.error && !expertData[field.id])}/>

    case "integer":
    default:
      return <TextField
        className={`ep-expert-data ed-field ${props.validation?.errorsExpertData?.find(val => val.id === field.id)?.error && !expertData[field.id]
          ? validateClassName 
          : ''}`}
        label={field.display_name}
        required={field.required}
        qa-tag={"ep-ed-field-"+field.kind}
        type={field.kind === "integer" ? "number" : "text"}
        onChange={(val) => setExpertData({ ...expertData, [field.id]: val.target.value })}
        value={expertData[field.id]} />
  }
}

interface IProps {
  ticket: Ticket
  expertData: TExpertData
  setExpertData: (ed: TExpertData) => void
  lang: string
  validation: ValidationState | null
}


export default (props: IProps) => {
  const fields: Field[] = props.ticket.ticket_template ? props.ticket.ticket_template.fields : []
  return <>{fields.map((field, index) => <FieldEdit {...{ ...props, field, key: index }} />)}</>
}
