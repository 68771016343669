import React, {forwardRef, useRef} from 'react'
import MaterialTable, {Column, Icons} from 'material-table'
import { buildMapDispatch, buildMapState } from '../../features/entity/containers/list'
import { connect, ConnectedProps } from 'react-redux'
import { IEntityBase } from '../../features/entity/type'
import { Line } from './line'
import {setDefaultLine, setUpdateAllowedLineAction, setUpdateTVAllowedLineAction} from './saga'
import Switcher from '../ticket_template/switcher'
import { Box, TextField, Container } from '@material-ui/core'
import { BaseEntity, CPagination } from '../../features/entity'
import { useTranslation } from 'react-i18next'
import {getArrowTable, getCancelTable, getDoneTable, getEditTable, getTrashTable} from "../../assets/icons";

const mapStateToProps = buildMapState(Line)
const mapDspToProp = {
  ...buildMapDispatch(Line),
  onSetDefault: (line: IEntityBase) => setDefaultLine(line.id),
  onSetEnabledTerminal: (line: IEntityBase, isAllowed: boolean) => setUpdateAllowedLineAction(line.id, isAllowed),
  onSetEnabledTvTerminal: (line: IEntityBase, isAllowed: boolean) => setUpdateTVAllowedLineAction(line.id, isAllowed)
}

const connector = connect(mapStateToProps, mapDspToProp)

type PropsFromRedux = ConnectedProps<typeof connector>

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export default connector((props: PropsFromRedux) => {
  let columns: Column<Line>[] = (props.attributes.map(att => att.buildColumn(props.additionals)).filter(notEmpty) as any)

  const refTable = useRef<MaterialTable<BaseEntity>>(null)
  const { t } = useTranslation()
  const changeS = (query: string) => {
    //@ts-ignore
    refTable.current!.dataManager.changeSearchText(query)
    //@ts-ignore
    refTable.current!.onSearchChangeDebounce(query)
  }

  columns.push(
    {
      field: "default",
      title: "enabled",
      render: (row) => <>
        {row ? <Switcher
          checked={row.default}
          onChange={() => props.onSetDefault(row)}
        /> : null}
      </>,
      editComponent: () => <></>
    },
    {
      field: "allowed",
      title: t("terminal"),
      render: (row) => <>
        {row ? <Switcher
          checked={row.allowed}
          onChange={() => props.onSetEnabledTerminal(row, !row.allowed)}
        /> : null}
      </>,
      editComponent: () => <></>
    },
    {
      field: "tv_allowed",
      title: t("tv"),
      render: (row) => <>
        {row ? <Switcher
          checked={row.tv_allowed}
          onChange={() => props.onSetEnabledTvTerminal(row, !row.tv_allowed)}
        /> : null}
      </>,
      editComponent: () => <></>
    })
  columns = columns.map(col => {
    let title = typeof col.title === "string" ? t(col.title) : col.title
    return { ...col, title }
  })
  const onAddClick = () => {
    const table: any = refTable.current!
    table.dataManager.changeRowEditing()
    table.setState({
      ...table.dataManager.getRenderState(),
      showAddRow: !table.state.showAddRow,
    })
  }
  const isEditable = props.editable

  const edit = getEditTable()
  const trash = getTrashTable()
  const done = getDoneTable()
  const cancel = getCancelTable()

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref)),
    Check: forwardRef(() => done),
    Clear: forwardRef(() => cancel),
    Delete: forwardRef(() => trash),
    Edit: forwardRef(() => edit)
  }

  return <>
    <Box display="flex" flexDirection="row">
      <Box style={{marginTop: 15}}>
        {props.tabs ? props.tabs : null}
      </Box>
      <Box flexGrow={1} />
      <TextField
        style={{marginTop: 6}}
        onChange={(e) => changeS(e.target.value)}
        placeholder={t("search")}
        className="mtable search-field" />
    </Box>
    <Container maxWidth="lg" className="mtable container-table">
      <MaterialTable
        icons={tableIcons}
        tableRef={refTable}
        title={"Lines"}
        columns={columns}
        options={{
          pageSize: 50,
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1,
        }}
        localization={{ header: { actions: "" } }}
        components={{
          Pagination: (props) => <CPagination {...props} onAddClick={isEditable ? onAddClick : undefined} />,
        }}
        data={props.filtered.sort((l, l2)=> l.id > l2.id ? 1 : -1) as Line[]}
        editable={{
          isEditable: () => props.editable,
          isDeletable: () => props.editable,
          onRowAdd: (newData) => {
            props.onCreate(newData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100)
            })
          },
          onRowUpdate: (newData) => {
            props.onUpdate(newData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100);
            })
          },
          onRowDelete: (oldData) => {
            props.onDelete(oldData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100);
            })
          },
        }}
      />
    </Container>
  </>
})