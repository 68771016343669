import React, {useEffect, useState} from 'react'
import {Box, Button, Grid, TextField} from '@material-ui/core'
import {Comment} from "./comment";
import {createCommentAction, deleteCommentAction, editCommentAction} from "./saga";
import {Ticket} from "../ticket";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../config/store";
import DetailPanelComment from "./detail_panel_comment";
import ClientPanelComment from "./client_panel_comment";
import {getDeleteGrayIcon} from "../../assets/icons";
import {useTranslation} from "react-i18next";
import {RemoteSyncCommentContainer} from "./index";

type Props = {
  ticket: Ticket,
  typePanel: string,
  setComments?: (comments: Comment[]) => void
}

export default ({ ticket, typePanel, setComments }: Props) => {

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [message, setMessage] = useState('')
  const [currentComment, setCurrentComment] = useState<Comment>()

  const customUrl = `tickets/${ticket.id}/comments`

  const { currentUserId, comments } = useSelector((state: AppState) => ({
    currentUserId: state.authentication.current_user_id,
    comments: Object.keys(state.comments.entities).map(k => state.comments.entities[k] as Comment)
  }))

  useEffect(() => {
    const comment = comments?.find(comment => comment.ticket_change_id === ticket.history[ticket.history.length - 1]?.id)
    if (comment) {
      setCurrentComment(comment)
      setMessage(comment?.message || '')
    } else {
      setCurrentComment(undefined)
    }

    if (comments)
      setComments?.(comments)
  }, [comments.length])

  const addComment = () => {
    if (ticket && currentUserId)
      dispatch(createCommentAction(
        ticket.id,
        Comment.buildFromRaw({
          message: message,
          user_id: currentUserId,
          ticket_id: ticket.id,
          ticket_change_id: ticket.history[ticket.history.length - 1].id,
        }, Comment)
      ))
  }

  const saveComment = (commentId: string, message: string) => {
    dispatch(editCommentAction(ticket.id, commentId, message))
  }

  const deleteComment = (commentId: string) => {
    setMessage("")
    dispatch(deleteCommentAction(ticket.id, commentId))
  }

  return <Box>
    <RemoteSyncCommentContainer customUrl={customUrl} />
    <Box>
      {
        comments.filter(comment => comment.id !== currentComment?.id).map(comment =>
          typePanel === "client"
            ? <ClientPanelComment key={comment.id} comment={comment} />
            : <DetailPanelComment key={comment.id} comment={comment} saveComment={saveComment} deleteComment={deleteComment} />
        )
      }
      <Box>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start">
          <TextField
            style={{marginTop: 0}}
            label={t('comment')}
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            rows={6}
            multiline
            className={`ep-client-data cl-field area-field ${currentComment ? "edit-comment" : ""}` }/>
          {
            currentComment
              ? <Box style={{marginTop: 16}}>
                <Box onClick={() => deleteComment(currentComment.id)}>
                  {getDeleteGrayIcon()}
                </Box>
              </Box>
              : null
          }
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className={`primary-button ${currentComment ? "edit-button-comment" : ""}`}
            onClick={() =>{
              currentComment
                ? saveComment(currentComment.id, message)
                : addComment()
            }}
            disabled={!message}
            disableRipple>
            {
              currentComment
                ? t('save')
                : t('add')
            }
          </Button>
        </Box>
      </Box>
    </Box>
  </Box>
}