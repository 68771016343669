import React from 'react'
import {Typography, Box, Grid} from '@material-ui/core'

import { Modal } from './modal_tv'
import arrowDialog from "../../../../assets/arrow_dialog.svg";
import {activeNumber} from "../../../../styles/variables";

export type TicketDialogData = {
  ticketNumber?: string,
  ticketWindow?: string,
  ticketSound?: string
}

type TProps = {
  title: string,
  ticketDialog: TicketDialogData | null
  open: boolean,
  setOpen: (v: boolean) => void
}

export default ({ title, ticketDialog, open, setOpen }: TProps) => {
  return <>
    <Modal
      title={title}
      titleAdditional={''}
      open={open}
      setOpen={setOpen}
      className={"tv-ticket-dialog"}>
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Box style={{marginLeft: '20px'}}>
            <Typography variant="h5" className="tv-dialog-bolder" style={{color: activeNumber}}>
              {ticketDialog?.ticketNumber}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <img className="arrow tv-dialog-arrow" src={arrowDialog} alt="arrow" />
          </Box>
          <Box style={{marginLeft: '20px', marginRight: '20px'}}>
            <Typography variant="h5" className="tv-dialog-bolder-window">
              {ticketDialog?.ticketWindow}
            </Typography>
          </Box>
        </Grid>
        <audio controlsList="nodownload" autoPlay style={{ visibility: "hidden" }}>
          <source src={ticketDialog?.ticketSound || ""} type="audio/mpeg" />
        </audio>
      </>
    </Modal>
  </>
}
