import { createMuiTheme } from "@material-ui/core/styles"
import {activeTabColor, errorFieldColor, tableSelectColor, textColor, textLightTitleColor} from "../styles/variables";

export default createMuiTheme({
  props: {
    MuiButton: {
      disableRipple: true
    },
  },
  typography: {
    fontFamily: 'YS Text',
    h5: {
      fontWeight: 500,
    }
  },
  palette: {
    primary: {
      main: activeTabColor
    },
    secondary: {
      main: textLightTitleColor
    },
    error: {
      main: errorFieldColor
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: "0 !important"
      }
    },
    MuiTypography: {
      root: {
        color: textColor
      }
    },
    MuiTableBody: {
      root: {
        "& tr:hover": {
          backgroundColor: `${tableSelectColor} !important`,

          "& .MuiButtonBase-root .material-icons": {
            display: "block",
            // "& ": {
              fontSize: 16
            // }
          }
        },

        "& .MuiButtonBase-root .material-icons": {
          display: "none",
          fontSize: 19
        },

        "& .MuiButtonBase-root ": {
          minWidth: 40
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none"
      },
      elevation2: {
        boxShadow: "none"
      }
    },
    MuiAccordion: {
      root: {
        "&::before": {
          backgroundColor: "white"
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: "none",
        minWidth: 150,
        minHeight: 40,
        borderRadius: 4,
        "&:hover": {
          backgroundColor: "transparent",
          boxShadow: "none !important"
        },
        "&:active": {
          backgroundColor: "transparent",
          boxShadow: "none !important"
        }
      },
      contained: {
        boxShadow: "none"
      },
      label: {
        fontSize: 14,
        textDecoration: "none"
      }
    },
    MuiInput: {
      root: {
        height: 40,
        paddingRight: 7,
        paddingLeft: 7
      }
    },
    MuiInputLabel: {
      formControl: {
        zIndex: 1,
        transform: "translate(10px, 28px) scale(1)"
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(0.75)"
      }
    }
  }
})