import { connect } from "react-redux"
import { AppState } from "../../../config/store"
import { setOpenAction } from "./store"
import MenuDrawer from "./mini_drawer"


interface IOwnProps{
  children: React.ReactNode[] | React.ReactNode
  menu: React.ReactNode[] | React.ReactNode
  headerTitle: String
}

const mapState = ({ menu }: AppState, ownProps:IOwnProps) => {
  return {
    open: menu.open
  }
}

const mapDispatch = {
  setOpen: setOpenAction
}

const connector = connect(mapState, mapDispatch)

export default connector(MenuDrawer)