import {EntityId} from "../../features/entity/type";
import {fetchM} from "../../config/pages/shared/fetchM";
import {put, takeEvery} from "redux-saga/effects";
import {fetchRequest} from "../../features/entity/store/actions";

export const ENABLE_SMS_ACTION = "ENABLE_SMS_ACTION"

interface IEnableSMSAction{
  type: typeof ENABLE_SMS_ACTION
  smsId: EntityId,
  isEnabled: boolean
}

export const setEnableSMS = (smsId: EntityId, isEnabled: boolean) => {
  return {
    type: ENABLE_SMS_ACTION,
    smsId,
    isEnabled
  }
}

function* updateEnable({smsId, isEnabled}: IEnableSMSAction){
  yield fetchM(`sms_templates/${smsId}/enable`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({enabled: isEnabled})
  })
  yield put(fetchRequest('sms_templates', {method: "GET"}, 'sms_templates_RELOAD_ACTION'))

}

export function* smsSaga(){
  yield takeEvery(ENABLE_SMS_ACTION, updateEnable)
}