import { TEntityClass, IDeleteAction, ICreateAction, IReloadAction, EntityReducer, EntityState, ActionTypes, IEntityBase, IUpdateAction, ISingleLoadAction } from '../type'
import { buildActionTypeName } from './actions'
import { buildLoadMutator, buildCreateMutator, buildUpdateMutator, buildDeleteMutator, buildSingleLoadMutator } from './mutators'


export default function buildStore<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>>(t: TEClass): EntityReducer<TEntity> {
  const initState: EntityState<TEntity> = {
    entities: {}
  }
  const loadMutator = buildLoadMutator<TEntity, TEClass>(t)
  const createMutator = buildCreateMutator<TEntity, TEClass>(t)
  const updateMutator = buildUpdateMutator<TEntity, TEClass>(t)
  const deleteMutator = buildDeleteMutator<TEntity, TEClass>(t)
  const singleLoadMutator = buildSingleLoadMutator<TEntity, TEClass>(t)
  const {
    CREATE_ACTION_TYPE,
    UPDATE_ACTION_TYPE,
    DELETE_ACTION_TYPE,
    RELOAD_ACTION_TYPE,
    SINGLE_LOAD_ACTION_TYPE
  } = buildActionTypeName(t)
  return function (state: EntityState<TEntity> = initState, action: ActionTypes<TEntity>): EntityState<TEntity> {
    switch (action.type) {
      case CREATE_ACTION_TYPE:
        return createMutator(action as ICreateAction<TEntity>, state)

      case DELETE_ACTION_TYPE:
        return deleteMutator(action as IDeleteAction, state)

      case SINGLE_LOAD_ACTION_TYPE:
        return singleLoadMutator(action as ISingleLoadAction, state)

      case UPDATE_ACTION_TYPE:
        return updateMutator(action as IUpdateAction<TEntity>, state)

      case RELOAD_ACTION_TYPE:
        return loadMutator(action as IReloadAction, state)

      default:
        return state
    }
  }
}