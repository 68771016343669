import React from 'react'
import MiniDrawer from '../../../shared/components/mini_drawer'
import { List, makeStyles, Container, Paper } from '@material-ui/core'
import BusinesRouter from './busines_router'
import { MainMenu } from '../shared/main_menu'
import Header from './header'
import { AppState } from '../../store'
import { useSelector } from 'react-redux'
import FirstScreen from './first_screen'


const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    display: "flex",
    flexDirection: "column"
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: 15,
    margin: "10px 0 0 0",
    display: "flex",
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,

  paper: {
    marginTop: theme.spacing(0),
    padding: 24,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }
}))

export default () => {
  const classes = useStyles()
  const status = useSelector((state:AppState) => state.expertPage.status)
  const menu = <List>
    <MainMenu />
  </List>
  return <MiniDrawer
    headerTitle="Expert Page"
    menu={menu}>
    <main className={classes.content + " info-window"}>
      <Header />
      {status === "FIRST_SCREEN" ?
        <FirstScreen />
        :
        <Container maxWidth="lg" className={classes.container}>
          <Paper className={classes.paper}>
            <BusinesRouter />
          </Paper>
        </Container>
      }
    </main>
  </MiniDrawer>
}