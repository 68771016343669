import React, {useEffect} from 'react'
import {Box, Snackbar, Typography} from '@material-ui/core'
import tick from '../../assets/tick.svg'
import {closeSuccessMessageAction} from "../../config/pages/shared/common_store";
import {useDispatch} from "react-redux";

export default (props: { message: string }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (props.message) {
      setOpen(true)
    }
  }, [props.message])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(closeSuccessMessageAction())

    setOpen(false)
  }

  return <Snackbar open={open} autoHideDuration={2000}
                   onClose={handleClose}
                   anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
    <Box className="success-snackbar" display="flex" flexDirection="row" alignItems="center">
      <Box marginRight={1}>
        <Typography className="text-snackbar">{props.message}</Typography>
      </Box>
      <img width="15px" height="16px" src={tick} alt="tick" />
    </Box>
  </Snackbar>
}