import { EntityId } from "../../features/entity/type"
import { takeEvery, put } from "redux-saga/effects"
import { fetchM } from "../../config/pages/shared/fetchM"
import { fetchRequest } from "../../features/entity/store/actions"

const UPDATE_FIELDS_ORDER_ACTION = "UPDATE_FIELDS_ORDER_ACTION"

interface IUpdateFieldAction{
  type: typeof UPDATE_FIELDS_ORDER_ACTION
  ticket_template_id: EntityId
  field_ids: EntityId[]
}
export const updateFieldOrder = (ticket_template_id: EntityId, field_ids: EntityId[]) => {
  return {
    type: UPDATE_FIELDS_ORDER_ACTION,
    ticket_template_id, field_ids
  }
}

function* updateOrder({ticket_template_id, field_ids}:IUpdateFieldAction){
  yield fetchM('fields/update_order', {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ ticket_template_id, field_ids }) 
  })
  yield put(fetchRequest('fields', {method: "GET"}, 'fields_RELOAD_ACTION'))

}

export function* fieldsSagaRegestration(){
  yield takeEvery(UPDATE_FIELDS_ORDER_ACTION, updateOrder)
}