import React from 'react'
import { BaseEntity, BaseAttribute } from '../../features/entity'
import { EntityId } from '../../features/entity/type'
import { BelongsToAttribute } from '../../features/entity/attributes/belongs_to_attribute'
import { Role } from '../role'
import { MultiSelectAttribute } from './multiselect'

const attributes = [
  new BaseAttribute("avatar"),
  new BaseAttribute('name'),
  new BaseAttribute('email'),
  new BaseAttribute('password'),
  new BaseAttribute('tv_display_name'),
  new BelongsToAttribute({
    key: "role_id",
    parentClassStorageEntityKey: "roles",
    displayAttribute: "code"
  }),
  new BelongsToAttribute({
    key: "hub_id",
    parentClassStorageEntityKey: "hubs",
    displayAttribute: "display_name"
  }),
  new MultiSelectAttribute('tag_ids', "tags"),
  new BaseAttribute('')
]

export class User extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "User"
  email: string = ""
  name: string = ""
  password: string = ""
  role_id: EntityId = "-1"
  hub_id: EntityId = "-1"
  tv_display_name: EntityId = ""
  role?: Role
  avatar?: string
  tag_ids: EntityId[] = []
  display_tags: string = ""
  constructor(id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
  }
  public static buildFromRaw(raw: any, _: any): any {
    let res = BaseEntity.buildFromRaw<User>(raw, User)
    if ('role' in raw) {
      res.role = Role.buildFromRaw<Role>(raw.role, Role)
    }
    if ('tag_ids' in raw){
      res.tag_ids = raw.tag_ids.map((t:any) => t?.id)
    }
    return res
  }
}
