import React from 'react'
import './App.css'
import Router from './config/routes'
import { Provider } from 'react-redux'
import configureStore from './config/store'
import './i18n'

export const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.__store__ = store
}

export default App