import React, {useState} from 'react'
import {Box, Button, Grid, TextField} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

import {Modal} from '../../../shared/components/modal'
import {useDispatch} from "react-redux";
import {fetchRequest} from "../../../features/entity/store/actions";

type TProps = {
  ticketId: string,
  open: boolean,
  setOpen: (v: boolean) => void
}

export default ({ticketId, open, setOpen}: TProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const [hold, setHold] = useState('')

  const onHoldTicket = () => {
    if (hold && hold !== '0') {
      dispatch(fetchRequest(
        `tickets/${ticketId}/hold`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          method: "PATCH", body: JSON.stringify({
            timeout: 60 * Number.parseInt(hold)
          })
        },
        ''))

      setTimeout(() => window.location.reload(), 1000)
    }


    setOpen(false)
  }

  return <>
    <Modal
      title={t('Freeze client')}
      titleAdditional={""}
      open={open}
      setOpen={setOpen}
      className={"info-session-dialog"}>
      <>
        <Grid
          container
          direction="row">
          <Box>
            <Box display="flex" flexDirection="row">
              <TextField
                label={t('enter time in minutes')}
                value={hold}
                type={'number'}
                id="hold-time-input"
                focused={true}
                onChange={(e) => setHold(e.currentTarget.value)}
                className={`ep-client-data cl-field`}
                style={{margin: 0, marginBottom: 40}}/>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                style={{margin: 0}}
                qa-tag="confirm-freeze-btn"
                className="primary-button"
                onClick={onHoldTicket}>OK</Button>
            </Box>
          </Box>
        </Grid>
      </>
    </Modal>
  </>
}