import React from 'react'
import { Container } from '@material-ui/core'

import AuthForm from './authentication/components/auth'
export default () => {
  return <React.Fragment>
    {/* <AppBar >
      <Typography variant="h6" noWrap>
        Авторизация
    </Typography>
    </AppBar> */}
    <main className={"classes.content"}>
      <div className={"classes.toolbar"} />
      <Container maxWidth={false}
                 style={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
        <AuthForm />
      </Container>
    </main>
  </React.Fragment>
}