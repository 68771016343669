import { SET_URL_PATH, ISetUrlPathAction, ISagaRoute, HISTORY } from "./type"
import { take, fork, cancel } from "redux-saga/effects"
import { IPolicySwitcherPorps } from "./switcher"
import { PolicySwitch } from "."
import { Task } from "redux-saga"

export const redirectTo = (path: string, brwHistory: HISTORY | null) => {
  if (brwHistory) {
    brwHistory.push(path, brwHistory.location)
  }
}

export function sagaBuilder(
  protoSwitcher: React.ReactElement<IPolicySwitcherPorps, typeof PolicySwitch>
) {
  const switcher = new PolicySwitch(protoSwitcher.props)
  return function* (): any {
    let subSagaTask: Task[] | null = null
    let action: ISetUrlPathAction
    let privious: ISetUrlPathAction | null = null
    while (true) {
      do {
        action = yield take(SET_URL_PATH)
      } while (action.routeProps.path === privious?.routeProps.path)
      privious = action
      if (subSagaTask){
        yield cancel(subSagaTask)
        subSagaTask = null
      }
      const path: string = (action.routeProps.path || "/" as any)
      const route: ISagaRoute | null = switcher.resolve(path)
      if (route && route.saga) {
        subSagaTask = []
        for(let key in route.saga){
          subSagaTask.push(yield fork(route.saga[key], action.routeProps))
        }
      }
    }
  }
}