import { BaseAttribute, BaseEntity, NumberAttribute } from "../../features/entity"
import { EntityId } from "../../features/entity/type"
import { BelongsToAttribute } from "../../features/entity/attributes/belongs_to_attribute"


const attributes = [
  new NumberAttribute('since_hour'),
  new NumberAttribute('since_min'),
  new NumberAttribute('till_hour'),
  new NumberAttribute('till_min'),
  new NumberAttribute('capacity'),
  new NumberAttribute('wday'),
  new BelongsToAttribute({
    key: "hub_id",
    parentClassStorageEntityKey: "hubs",
    displayAttribute: "display_name"
  })
]

export class TimeSlot extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "TimeSlot"
  since_hour: number = -1
  since_min: number = -1
  till_hour: number = -1
  till_min: number = -1
  capacity: number = -1
  wday: number = -1
  hub_id: EntityId = "-1"
  constructor(id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
  }
  deleteMeAction() {
    return {
      type: "FETCH_ACTION",
      url: `hubs/${this.hub_id}/time_slots/${this.id}`,
      options: {method: "DELETE"},
      callbackAction: "time_slots_DELETE_ACTION"
    }
  }
  createMeAction() {
    return {
      type: "FETCH_ACTION",
      url: `hubs/${this.hub_id}/time_slots`,
      options: {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...this
        })
      },
      callbackAction: "time_slots_CREATE_ACTION"
    }
  }
}
