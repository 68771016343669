import React, {useRef, useState} from "react"
import { User } from "."
import { makeStyles, Avatar, Box, createStyles } from "@material-ui/core"
import {baseAPIUrl} from "../../config/pages/authentication/reducers/sagas";

export const useStyles = makeStyles(() =>
  createStyles({
    controls: {
      position: 'fixed',
      zIndex: 100,
      right: 20,
      bottom: 20
    },
    fab: {
      position: 'relative',
      margin: 0,
      width: 34,
    },
    input: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0,
      cursor: 'pointer',
      width: 34,
      height: 34,
      zIndex: 100
    },
    avaedit: {
      width: 34,
      height: 34,
      cursor: "pointer",
      margin: "-10px 5px"
    },
    ava: {
      width: 34,
      height: 34,
      margin: "-10px 10px"
    }
  }))

type TProps = {
  setAvatar?: any
  user?: User
  read?: boolean
}

export default ({ user, read, setAvatar }: TProps) => {
  const classes = useStyles()
  const [imgSrc, setImgSrc] = useState<string | undefined>()
  const ref = useRef<HTMLInputElement>(null)
  const fileChange = () => {
    if (ref.current) {
      const fr = new FileReader()
      fr.onload = function () {
        setImgSrc(fr.result as string)
        setAvatar(ref.current!.files![0])
      }
      fr.readAsDataURL(ref.current!.files![0])
    }
  }

  if (read){
    return <Avatar alt="alt" src={user?.avatar} className={classes.ava} />
  }

  return <Box className={classes.fab}>
    <input className={classes.input} type="file" ref={ref} accept="image/png,image/jpeg" onChange={fileChange} />
    <Avatar alt="alt" src={imgSrc} className={classes.avaedit} />
  </Box>
}
