import React from "react"
import { User } from "."
import { useDispatch } from "react-redux"
import { closeSessionAction } from "./saga"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type TProps = {
  user?: User
}

export default ({ user }: TProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const onCloseSession = () => {
    if (user)
      dispatch(closeSessionAction(user.id))
  }

  return <Link to="#" onClick={() => onCloseSession()}>{t('close session')}</Link>
}