import {take, race, put, takeLatest, takeEvery} from "redux-saga/effects"
import { fetchRequest } from "../../features/entity/store/actions"
import { fetchM } from "../../config/pages/shared/fetchM"
import { EntityId } from "../../features/entity/type"
import {successMessageAction} from "../../config/pages/shared/common_store";
import i18next from "i18next";
import {User} from "./user";
import {getApiResponse} from "../../config/pages/authentication/reducers/sagas";
import {buildRaw} from "../../features/entity/helpers/buildRaw";

export const CLOSE_SESSION = "CLOSE_SESSION"

export const USER_CREATE_ACTION = "MANUAL_USER_CREATE_ACTION"
export const USER_UPDATE_ACTION = "MANUAL_USER_UPDATE_ACTION"

export interface ICloseSessionAction {
  type: typeof CLOSE_SESSION,
  userId: string
}

export const closeSessionAction = (userId: string): ICloseSessionAction => {
  return {
    type: CLOSE_SESSION,
    userId
  }
}

const SET_TAGS_ACTION = "user_SET_TAGS_ACTION"

export function setTagsAction(tags: EntityId[]){
  return {
    type: SET_TAGS_ACTION,
    tags
  }
}

function* setUserTags(action: any): any {
  const [upd, create] = yield race([take("users_UPDATE_ACTION"), take("users_CREATE_ACTION")])
  const response: any = upd ? upd.response : create.response
  if (response.success) {
    const url = `/users/${response.model.id}/tags`
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        tag_ids: action.tags
      })
    }
    yield fetchM(url, options)
    yield put(fetchRequest('users', {}, "users_RELOAD_ACTION"))
  }
}

function* closeSession({userId}: ICloseSessionAction) {
  const url = `users/${userId}/close_work_session`
  const options = {
    method: "PATCH"
  }

  const response = yield fetchM(url, options, undefined, undefined, true)
  if (response.success)
    yield put(successMessageAction(i18next.t('success close session')))
  else
    yield put(successMessageAction(i18next.t('failed close session')))
}

export interface ICreateUser {
  type: typeof USER_CREATE_ACTION,
  user: User,
  file?: any
}

export const createUserAction = (user: User, file?: any): ICreateUser => {
  return {
    type: USER_CREATE_ACTION,
    user: user,
    file: file
  }
}

function* createUser({user, file}: ICreateUser) {
  let body = buildRaw(user, User)
  let resp = yield getApiResponse("/users", "POST", body)
  if (resp.success) {
    if (file) {
      let model = resp.model
      const formData = new FormData()

      formData.append('avatar', file)
      const url = `/users/${model.id}/avatar`
      const options = {
        method: "PATCH",
        body: formData
      }
      yield fetchM(url, options)
    }

    yield put(fetchRequest('users', {}, "users_RELOAD_ACTION"))
  }
}

export interface IUpdateUser {
  type: typeof USER_UPDATE_ACTION,
  user: User,
  file?: any
}

export const updateUserAction = (user: User, file?: any): IUpdateUser => {
  return {
    type: USER_UPDATE_ACTION,
    user: user,
    file: file
  }
}

function* updateUser({user, file}: IUpdateUser) {
  let body = buildRaw(user, User)
  let resp = yield getApiResponse("/users/" + user.id, "PATCH", body)
  if (resp.success) {
    if (file) {
      let model = resp.model
      const formData = new FormData()

      formData.append('avatar', file)
      const url = `/users/${model.id}/avatar`
      const options = {
        method: "PATCH",
        body: formData
      }
      yield fetchM(url, options)
    }

    yield put(fetchRequest('users', {}, "users_RELOAD_ACTION"))
  }
}

export function* userSaga(): any {
  yield takeLatest(SET_TAGS_ACTION, setUserTags)
  yield takeEvery(CLOSE_SESSION, closeSession)
  yield takeLatest(USER_CREATE_ACTION, createUser)
  yield takeLatest(USER_UPDATE_ACTION, updateUser)
}
