import { RouteProps } from "react-router-dom"
import { HISTORY, IBrowserHistoryGetAction, UPDATE_HIST_SETTER, ISetUrlPathAction, SET_URL_PATH, SET_ROLE_ACTION, ISetRole } from "./type"

export const updateHistorySetter = (brwHistory: HISTORY): IBrowserHistoryGetAction => {
  return {
    type: UPDATE_HIST_SETTER,
    brwHistory
  }
}

export const setUrlPathAction = (routeProps: RouteProps): ISetUrlPathAction => {
  return {
    type: SET_URL_PATH,
    routeProps
  }
}

export const setRoleAction = (role?: string): ISetRole => {
  return {
    type: SET_ROLE_ACTION,
    role
  }
}