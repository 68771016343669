import React from "react"
import { IPolicyRouterState, TRole } from "./type"
import { connect, ConnectedProps } from "react-redux"
import {languageIconColor} from "../../styles/variables";

interface IOwnProps {
  path: string
  roles?: TRole[]
  children?: React.ReactNode
}

const mapStateProps = ({ policy }: { policy: IPolicyRouterState }, ownProps: IOwnProps) => {
  return {
    policy,
    ...ownProps
  }
}

const connector = connect(mapStateProps, {})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector((props: PropsFromRedux) => {
  if (!props.roles ||
    (props.roles && props.policy.currentRole && props.roles.includes(props.policy.currentRole))
  ) {
    const current = props.policy.currentPath === props.path
    return <li className={current ? "active" : ""}
               style={current ? {color: "white"} : {color: languageIconColor}}>
            {props.children}
           </li>
  }
  return <></>
})