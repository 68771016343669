import { Desk } from "../../../models/desk"
import { EntityId } from "../../../features/entity/type"
import { Ticket } from "../../../models/ticket"
import {TClientData, TExpertData} from "../../../models/ticket/type"
import { PUSH_ICOMING } from "../shared/constants"

export const DESK_SELECTION = "DESK_SELECTION"
export const WAIT_WORK_SESSION = "WAIT_WORK_SESSION"
export const WAIT_TICKET = "WAIT_TICKET"
export const WAIT_CLIENT = "WAIT_CLIENT"
export const CLIENT_HENDLING = "CLIENT_HENDLING"
export const WAIT_ANOTHER_LINE = "WAIT_ANOTHER_LINE"
export const FIRST_SCREEN = "FIRST_SCREEN"

export type TStatus = typeof DESK_SELECTION | typeof WAIT_WORK_SESSION | typeof WAIT_TICKET |
  typeof WAIT_CLIENT | typeof CLIENT_HENDLING | typeof WAIT_ANOTHER_LINE | typeof FIRST_SCREEN

export type TwebPushState = "NOT_LOAD_YET" | "READY" | "PROBLEM"

export interface ExpertPageState {
  selected_desk: Desk | null
  status: TStatus
  currentTicket: Ticket | null
  expert_data: TExpertData
  client_data: TClientData
  workSessionId: string | null
  webPushReady: TwebPushState
}

export const DESK_SELECT_ACTION = "EP_DESK_SELECT_ACTION"
export const START_WORK_SESSION = "EP_START_WORK_SESSION"
export const DESK_CHANGE_ACTION = "EP_DESK_CHANGE_ACTION"
export const FINISH_TICKET = "EP_FINISH_TICKET"
//TODO: rename to client no comming
export const CANCEL_TICKET = "EP_CANCEL_TICKET"
export const STOP_WORK_SESSION = "EP_STOP_WORK_SESSION"
export const FORWARD_TICKET = "EP_FORWARD_TICKET"
export const FINISH_AND_STOP_TICKET = "EP_FINISH_AND_STOP_TICKET"
export const RECIVE_TICKET = "EP_RECIVE_TICKET"
export const CLIENT_INCOMING = "EP_CLIENT_INCOMING"
export const MISS_CLIENT = "EP_MISS_CLIENT"
export const STATUS_CHANGING = "EP_STATUS_CHANGING"
export const SET_CURRENT_TICKET = "EP_SET_CURRENT_TICKET"
export const WORK_SESSION_SET = "EP_WORK_SESSION_SET"
export const SELECT_ANOTHER_LINE = "SELECT_ANOTHER_LINE"
export const SELECT_ANOTHER_LINE_MANUAL_LINE = "SELECT_ANOTHER_LINE_MANUAL_LINE"
export const START_WORK = "EP_START_WORK"
export const HELP_REQUEST = "EP_HELP_REQUEST"


export interface ISetWorkSessionAction {
  type: typeof WORK_SESSION_SET
  workSessionId: string
}

export interface IPushNotificationActtion {
  type: typeof PUSH_ICOMING
  payload: any
}

export interface ISetCurrentTicketAction {
  type: typeof SET_CURRENT_TICKET
  ticket: Ticket | null
}

export interface IStatusChangingAction {
  type: typeof STATUS_CHANGING
  status: TStatus
}

export interface IMissClientAction {
  type: typeof MISS_CLIENT
}

export interface IClientIncomingAction {
  type: typeof CLIENT_INCOMING
}

export interface IFinishAndStopTicketAction {
  type: typeof FINISH_AND_STOP_TICKET
  expertData: TExpertData
  clientData: TClientData
}

export interface IForwardTicketAction {
  type: typeof FORWARD_TICKET
  expertData: TExpertData
  clientData: TClientData
}

export interface IDeskSelectAction {
  type: typeof DESK_SELECT_ACTION
  desk: Desk | null
}

export interface IStartWorkSessionAction {
  type: typeof START_WORK_SESSION
}

export interface IChangeDeskAction {
  type: typeof DESK_CHANGE_ACTION
}

export interface ICancelingTicketAction {
  type: typeof CANCEL_TICKET
}

export interface IStopWorkSessionAction {
  type: typeof STOP_WORK_SESSION
}

export interface IFinishTicketAction {
  type: typeof FINISH_TICKET
  expertData: TExpertData
  clientData: TClientData
}

export interface IReciveTicketAction {
  type: typeof RECIVE_TICKET
}

export interface ISelectAnotherLine {
  type: typeof SELECT_ANOTHER_LINE | typeof SELECT_ANOTHER_LINE_MANUAL_LINE,
  lineId: EntityId,
  ticketId?: EntityId
}

export interface IPushTicketIncomingAction {
  type: typeof PUSH_ICOMING
}

export const RECALL = "EXPERT_PAGE_RECALL"

export interface IRecallAction {
  type: typeof RECALL
}

export interface IStartWorkAction {
  type: typeof START_WORK
}


export const END_WORK = "EXPERT_END_WORK"

export interface IEndWorkAction {
  type: typeof END_WORK
}

export const endWorkAction = (): IEndWorkAction => {
  return {
    type: END_WORK
  }
}

export const WEB_PUSH_READY = "EXPERT_WEB_PUSH_READY"

export interface ISetWebPushReadyStatusAction {
  type: typeof WEB_PUSH_READY
  status: "READY" | "PROBLEM"
}

export const setWebPushReadyStatusAction = (status: "READY" | "PROBLEM"): ISetWebPushReadyStatusAction => {
  return {
    type: WEB_PUSH_READY,
    status
  }
}

export interface IHelpRequestAction{
  type: typeof HELP_REQUEST
}

export type ExpertAction = IDeskSelectAction | IStartWorkSessionAction | IChangeDeskAction |
  ICancelingTicketAction | IStopWorkSessionAction | IFinishTicketAction |
  IForwardTicketAction | IFinishAndStopTicketAction | IReciveTicketAction |
  IClientIncomingAction | IMissClientAction | IStatusChangingAction |
  ISetCurrentTicketAction | ISetWorkSessionAction | IPushTicketIncomingAction |
  IRecallAction | IStartWorkAction | ISetWebPushReadyStatusAction | IHelpRequestAction
