import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import moment from 'moment'
import {Ticket} from "../../../../models/ticket";
import {makeStyles} from "@material-ui/core";
import {i18n} from "i18next";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none"
  }
}))

type Props = {
  history: Ticket[],
  showInfo: (item: Ticket) => void
}

export const datePrepare = (i18n: i18n, dS: string) => {
  moment.locale(i18n.language)
  const date = moment(dS)
  return `${date.format("DD.MM.YY / HH:mm")}`
}

interface ItemProps {
  item: Ticket,
  click: () => void
}

const Item = ({ item, click }: ItemProps) => {
  const { i18n } = useTranslation()
  return <>
    <span className="history-visit-date" onClick={() => click()}>{datePrepare(i18n, item?.created_at)}</span>
  </>
}

export default ({ history, showInfo }: Props) => {
  if (history.length === 0)
    return <></>

  const classes = useStyles()

  return <Timeline align="left">
    {history.slice(0, history.length - 1).map((ch, index) => <TimelineItem key={index}>
      <TimelineSeparator>
        {index > 0 ? <>
          <TimelineConnector className="before-connector timeline-connector" />
          <TimelineDot
            variant="outlined"/>
        </>
          :
          <TimelineDot
            variant="outlined"
            style={{ marginTop: 7 }}/>}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent qa-tag={`ep-${ch.id}-history-item`}>
        <Item item={ch} click={() => showInfo(ch)} />
      </TimelineContent>
    </TimelineItem>)}

    <TimelineItem
      className={"missingOppositeContent"}>
      <TimelineSeparator>
        {history.length > 1 ? <><TimelineConnector className="before-connector timeline-connector" />
          <TimelineDot variant="outlined" className={classes.root} />
        </>
          :
          <TimelineDot variant="outlined" className={classes.root} style={{ marginTop: 7 }} />
        }

      </TimelineSeparator>
      <TimelineContent qa-tag={`ep-${history[history.length - 1].id}-history-item`}>
        <Item item={history[history.length - 1]} click={() => showInfo(history[history.length - 1])}/>
      </TimelineContent>
    </TimelineItem>
  </Timeline>
}