import {connect, ConnectedProps, useDispatch, useSelector} from "react-redux"
import React, {forwardRef, useRef} from "react"
import MaterialTable, {Icons} from "material-table"
import {Box, Container } from "@material-ui/core"
import {useTranslation} from "react-i18next"
import {getArrowTable, getCancelTable, getDoneTable, getEditTable, getTrashTable} from "../../assets/icons";
import {BaseEntity, CPagination} from "../../features/entity";
import {buildMapDispatch, buildMapState} from "../../features/entity/containers/list";
import {User} from "./user";
import AvatarEditor from "./avatar";
import CloseSession from "./close_session";
import {createUserAction, updateUserAction} from "./saga";
import {AppState} from "../../config/store";
import MultiSelectField from '../../features/entity/components/table_multiple'
import SelectField from "../../features/entity/components/table_select";
import {arrayToString} from "../../shared/helper";
import {Role} from "../role";
import {Hub} from "../hub";
import {TicTag} from "../tic_tag";

const mapStateToProps = buildMapState(User)
const mapDspToProp = buildMapDispatch(User)

const connector = connect(mapStateToProps, mapDspToProp)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector((props: PropsFromRedux) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const refTable = useRef<MaterialTable<BaseEntity>>(null)
  const {roles, hubs, tags} = useSelector((store: AppState) => {
    return {
      roles: Object.keys(store.roles.entities).map(k => store.roles.entities[k] as Role),
      hubs: Object.keys(store.hubs.entities).map(k => store.hubs.entities[k] as Hub),
      tags: Object.keys(store.tags.entities).map(k => store.tags.entities[k] as TicTag)
    }
  })

  //@ts-ignore
  let avatar: File = null

  const onAddClick = () => {
    const table: any = refTable.current!
    table.dataManager.changeRowEditing()
    table.setState({
      ...table.dataManager.getRenderState(),
      showAddRow: !table.state.showAddRow,
    })
  }

  const edit = getEditTable()
  const trash = getTrashTable()
  const done = getDoneTable()
  const cancel = getCancelTable()

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref)),
    Check: forwardRef(() => done),
    Clear: forwardRef(() => cancel),
    Delete: forwardRef(() => trash),
    Edit: forwardRef(() => edit)
  }

  return <>
    <Box display="flex" flexDirection="row">
      <Box style={{marginTop: 15}}>
        {props.tabs ? props.tabs : null}
      </Box>
    </Box>
    <Container maxWidth="lg" className="mtable container-table" style={{marginTop: 8}}>
      <MaterialTable
        data={props.filtered as User[]}
        icons={tableIcons}
        options={{
          pageSize: 50,
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1
        }}
        tableRef={refTable}
        localization={{ header: { actions: "" } }}
        columns={[
          {
            field: "avatar",
            title: t("avatar"),
            editable: "always",
            editComponent: rowProps => {
              return (
                <AvatarEditor setAvatar={(val: any) => avatar = val} user={rowProps.value as User} />
              )
            },
            render: row => {
              return <AvatarEditor user={row as User} read />
            }
          },
          {
            field: "name",
            title: t("name"),
          },
          {
            field: "email",
            title: t("email")
          },
          {
            field: "password",
            title: t("password")
          },
          {
            field: "tv_display_name",
            title: t("tv_display_name")
          },
          {
            field: "role_id",
            title: t("role_id"),
            editComponent: rowProps => {
              return (
                <SelectField
                  className={''}
                  errorClassName={''}
                  collection={roles.map(role => {
                    role.display_name = t(role.display_name)
                    return  role
                  })}
                  val={rowProps.value || []}
                  setVal={rowProps.onChange}/>
              )
            },
            render: row => {
              return t("" + row?.role?.display_name)
            }
          },
          {
            field: "hub_id",
            title: t("hub_id"),
            editComponent: rowProps => {
              return (
                <SelectField
                  className={''}
                  errorClassName={''}
                  collection={hubs}
                  val={rowProps.value || []}
                  setVal={rowProps.onChange}/>
              )
            },
            render: row => {
              return t("" + hubs.find(hub => hub?.id === row?.hub_id)?.display_name)
            }
          },
          {
            field: "tag_ids",
            title: t("tag_ids"),
            editComponent: rowProps => {
              return (
                <MultiSelectField
                  className={''}
                  errorClassName={''}
                  collection={tags}
                  val={rowProps.value || []}
                  setVal={rowProps.onChange}/>
              )
            },
            render: row => {
              return arrayToString(row.tag_ids, tags)
            }
          },
          {
            title: "",
            editable: "never",
            render: (row:BaseEntity) => {
              return <CloseSession user={row as User} />
            }
          }
        ]}
        components={{
          Pagination: (props) => <CPagination {...props} onAddClick={onAddClick} />,
        }}
        editable={{
          onRowAdd: (newData) => {
            dispatch(createUserAction(newData, avatar))
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100)
            })
          },
          onRowUpdate: (newData) => {
            dispatch(updateUserAction(newData, avatar))
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100);
            })
          },
          onRowDelete: (oldData) => {
            props.onDelete(oldData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve()
              }, 100);
            })
          }
        }}
      />
    </Container>
  </>
})
