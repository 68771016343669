import centrifugo from 'centrifuge'
import { eventChannel, END } from 'redux-saga'
import {call, take, put, select} from 'redux-saga/effects';
import { PUSH_ICOMING } from '../config/pages/shared/constants';
import {AppState} from "../config/store";
import {endLoadReportAction} from "../config/pages/statistics/type";
import Centrifuge from "centrifuge";
import {setTokenCentrifugo} from "../config/root_saga";
import {setTokenDiviceCentrifugo} from "../config/pages/device/saga";
import { store } from '../App'
import {baseAPIUrl} from "../config/pages/authentication/reducers/sagas";
import { CENTRIFUGO_YANDEX_PROD, CENTRIFUGO_YANDEX_TST} from "../shared/hosts";

export const UPDATE_TOKEN_CENTRIFUGO = "UPDATE_TOKEN_CENTRIFUGO"

interface IUpdateTokenCentrifugoAction{
  type: typeof UPDATE_TOKEN_CENTRIFUGO
  typeEssence: TypeEssence
}

export function updateTokenCentrifugoAction(typeEssence: TypeEssence): IUpdateTokenCentrifugoAction{
  return {
    type: UPDATE_TOKEN_CENTRIFUGO,
    typeEssence: typeEssence
  }
}

interface ICentrMessage {
  message: any
}

type CentrifugoMessage = END | "CONNECTION" | ICentrMessage

export enum TypeEssence {
  USER,
  DEVICE
}

let cen: Centrifuge

function isMessage(answer: CentrifugoMessage): answer is ICentrMessage{
  return (answer as ICentrMessage).message !== undefined
}

function initSocketChanel(cen: centrifugo, channel: string) {
  return eventChannel((emmiter: (inp: CentrifugoMessage) => void) => {
    cen.on('connect', function (ctx) {
      console.log("connected", ctx);
      emmiter("CONNECTION")
    });

    cen.on('disconnect', function (ctx) {
      console.log("disconnected", ctx);
    });

    cen.subscribe(channel, function (ctx) {
      console.log(ctx.data.value)
      emmiter({ message: ctx.data })
      console.log("subscribe to cahnnel: ", channel)
    });

    cen.connect()

    return () => cen.disconnect()
  })
}

function* getToken(type: TypeEssence) {
  switch (type) {
    case TypeEssence.USER:
      yield setTokenCentrifugo()
      break
    case TypeEssence.DEVICE:
      yield setTokenDiviceCentrifugo()
      break
  }
}

export function* updateToken({typeEssence}: IUpdateTokenCentrifugoAction) {
  yield getToken(typeEssence)
  cen.setToken(window.localStorage["cenToken"])
}

export function* pushTicket(chat: string, type: TypeEssence) {
  if (cen === undefined) {
    yield getToken(type)
    cen = yield call(initCen, type)
  }

  const chan = yield call(initSocketChanel, cen, "public:"+chat)
  const media: HTMLAudioElement | null = yield select((state: AppState) => state.devicePage.audioTag)
  try {
    while (true) {
      const message: CentrifugoMessage = yield take(chan)
      if (isMessage(message)){
        if (message.message.event == "ticket_assigned")
          media?.play()

        console.log(`socket : ${message.message}`)
        yield put({type: PUSH_ICOMING, payload: message.message})
      }
    }
  } finally {
    console.log('socket terminated ticket')
  }
}

export function* pushStatistics(chat: string) {
  if (cen === undefined) {
    yield getToken(TypeEssence.USER)
    cen = yield call(initCen, TypeEssence.USER)
  }

  const chan = yield call(initSocketChanel, cen, "stat:"+chat)
  try {
    while (true) {
      const message: CentrifugoMessage = yield take(chan)
      //const taskId = yield select((state: AppState) => state.statistics.taskId)
      if (isMessage(message)){
        if (message.message.success == true)
          yield put(endLoadReportAction({reports: message.message.data, task_id: message.message.task_id}, false))
      }
    }
  } finally {
    console.log('socket terminated stat')
  }
}

function initCen(type: TypeEssence) {
  // const cen = new centrifugo("ws://localhost:8000/connection/websocket")
  //@ts-ignore
  const postFix = window.globalAppConfig.centrifugoHostPostfix
  //@ts-ignore
  const protocol = window.globalAppConfig.centrifugoHostProtocol

  let constring
  let refreshPath = ''
  if (postFix == CENTRIFUGO_YANDEX_TST || postFix == CENTRIFUGO_YANDEX_PROD) {
    constring = `${protocol}://${postFix}/connection/websocket`
    refreshPath = baseAPIUrl()
  } else if (window.location.hostname !== 'localhost') {
    constring = `${protocol}://${window.location.hostname}${postFix}/connection/websocket`
    refreshPath = baseAPIUrl()
  } else {
    constring = `${protocol}://${postFix}/connection/websocket`
    refreshPath = 'http://localhost:3000/'
  }

  const cen = new centrifugo(constring, {
    onRefresh: function(ctx, cb) {
      fetch( refreshPath + "centrifuge/refresh", {
        method: "POST"
      }).then(function(resp) {
        if (resp.ok)
          resp.json().then(function(data) {
            // Data must be like {"status": 200, "data": {"token": "JWT"}} - see
            // type definitions in dist folder. Note that setting status to 200 is
            // required at moment. Any other status will result in refresh process
            // failure so client will eventually be disconnected by server.
            cb(data);
          })
        else
          store.dispatch(updateTokenCentrifugoAction(type))
      })
    }
  })

  const cenToken = window.localStorage["cenToken"]

  //@ts-ignore
  cen.setToken(cenToken)
  //cen.setToken(window.globalAppConfig.centrifugToken)

  return cen
}

