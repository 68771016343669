import React from 'react'
import {Box, Grid, Typography} from '@material-ui/core'
import {Comment} from "./comment";
import {timeDDHHMM} from "../../config/pages/statistics/pages/helper";

type Props = {
  comment: Comment
}

export default ({ comment }: Props) => {
  return <Box className="client-panel-comment">
    <Grid
      container
      direction="row"
      justify="space-between">
        <Box>
          <Typography className="panel-comment-title">{comment.user?.name || ''}</Typography>
        </Box>
        <Box>
          <Typography className="panel-comment-title">{timeDDHHMM(comment.created_at)}</Typography>
        </Box>
    </Grid>
    <Box marginTop="10px">
      <Typography>{comment.message}</Typography>
    </Box>
  </Box>
}