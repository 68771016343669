import React from "react"
import { BaseAttribute, BaseEntity } from "../../features/entity"
import { EntityId } from "../../features/entity/type"
import { BelongsToAttribute } from "../../features/entity/attributes/belongs_to_attribute"
import { LinkToChildAttr } from "../../features/entity/attributes/link_to_child_attr"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

type TChldLinkProps = {
  url: string
  translateCode: string
}

const ChildLink = ({ translateCode, url }: TChldLinkProps) => {
  const { t } = useTranslation()
  return <Link to={url}>
    {t(translateCode)}
  </Link>
}

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BaseAttribute('address'),
  new BelongsToAttribute({
    key: "city_id",
    parentClassStorageEntityKey: "cities",
    displayAttribute: "display_name"
  }),
  new LinkToChildAttr((row) => {
    return <>
      {row ?
        <ChildLink url={`hubs/${row.id}/time_slots`} translateCode="assignment" />
      : null}
    </>
  }, "time_slots"),
  new LinkToChildAttr((row) => {
    return <>
      {row ?
        <ChildLink url={`hubs/${row.id}/devices`} translateCode="configurate" />
        : null}
    </>
  }, "devices")
]

export class Hub extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Hub"
  display_name: string
  address: string
  city_id: EntityId = "-1"
  constructor(display_name: string = "", address: string = "", id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
    this.address = address
  }
}
