import React, {useState} from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles, createStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import StateChip from './state'
import {TicketState} from '../../../../models/ticket/ticket'
import InfoTicketDialog from './info_ticket_dialog'
import {getHoursWork} from "../helper";
import {ITicketChange} from "../../../../models/ticket_change";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
    }
  })
)

type TProps = {
  data?: {
    ticket_id: string
    ticket_no: string
    state: TicketState
    wait_time: number
    client_data: {
      first_name: string
      last_name: string
    }
    changes: ITicketChange[]
  }[]
}

export default ({ data }: TProps) => {
  if (!data)
    return <></>

  const classes = useStyles()

  const [dialogInfoTicketOpen, setDialogInfoTicketOpen] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState()

  const onShowInfo = (history: ITicketChange[], client: string) => {
    setSelectedTicket({history: history, client: client})
    setDialogInfoTicketOpen(true)
  }

  const { t } = useTranslation()
  return <Card variant="outlined">
    <CardHeader title={t("line")} classes={classes}/>
    <CardContent>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("client")}</TableCell>
            <TableCell>{t("ticket_no")}</TableCell>
            <TableCell>{t("wait_time")}</TableCell>
            <TableCell>{t("state")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}
                      onClick={() => onShowInfo(row.changes, row.client_data.first_name + ' ' + row.client_data.last_name)}
                      style={{cursor: 'pointer'}}>
              <TableCell>{row.client_data.first_name} {row.client_data.last_name}</TableCell>
              <TableCell>{row.ticket_no}</TableCell>
              <TableCell>{getHoursWork(row.wait_time, t('h'), t('min'), t('sec'))}</TableCell>
              <TableCell scope="row">
                <StateChip s={row.state} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardContent>
    <InfoTicketDialog title={selectedTicket?.client} history={selectedTicket?.history || []} open={dialogInfoTicketOpen} setOpen={setDialogInfoTicketOpen} />
  </Card>
}