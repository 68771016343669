import React from 'react'

import { Button, Grid } from '@material-ui/core'
import { TEntityClass, TMappedState } from '../type'
import { BaseEntity } from "../BaseEntity"
import { BaseAttribute } from '../attributes/base_attribute'
import { WraperPaper } from './wrapper_paper'

export interface Props<TEntity extends BaseEntity> {
  onCreate(entity: TEntity): void
  buildTemplateEntity(): TEntity
}

type TEvent = React.ChangeEvent<{ value: unknown }>

interface TState<TEntity> {
  ent: TEntity
}

type TProps<TEntity extends BaseEntity> = Props<TEntity> & TMappedState

export interface IWrapperProps {
  children: React.ReactElement
}

class CreateEntity<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>> extends React.Component<TProps<TEntity>, TState<TEntity>> {
  attributes: BaseAttribute[]
  constructor(props: TProps<TEntity>) {
    super(props)
    this.state = { ent: props.buildTemplateEntity() }
    this.attributes = (this.state.ent.constructor as TEClass).attributes
  }
  onChangeAttribute(event: TEvent, attribute: BaseAttribute) {
    // this.setState({ ...this.state, [attribute.key]: String(event.target.value)})
    this.setState({ ent: attribute.change(this.state.ent, event) })
  }
  render() {
    const create = () => {
      this.props.onCreate(this.state.ent)
      this.setState({ ent: this.props.buildTemplateEntity() })
    }
    return <WraperPaper>
      <Grid container spacing={3}>
        {this.attributes.map(attr => <Grid key={attr.key} item xs={12}>
          {attr.editRender(
            (event: TEvent) => this.onChangeAttribute(event, attr),
            this.state.ent,
            this.props)}
        </Grid>
        )}
        <Grid item xs={12}>
          <Button className="cy_entity_create" variant="contained" color="primary" onClick={create}>
            Add
          </Button>
        </Grid>
      </Grid>
    </WraperPaper>
  }
}

export default function <TEntity extends BaseEntity>(props: TProps<TEntity>) {
  return <CreateEntity {...props} />
}