import { takeEvery, call, put } from "redux-saga/effects";
import { IUpdateEnabledTTAction, UPDATE_ENABLE_TT_ACTION } from "./type";
import { fetchM } from "../../config/pages/shared/fetchM";
import { fetchRequest } from "../../features/entity/store/actions";

export function* updateOrder({ticketTemplate}: IUpdateEnabledTTAction){
  yield call(fetchM, `ticket_templates/${ticketTemplate.id}/enable`, {method: "PATCH"})
  yield put(fetchRequest('ticket_templates', {method: "GET"},  "ticket_templates_RELOAD_ACTION"))
}
 
export function* ticketTemplateSagaRegestration() {
  yield takeEvery(UPDATE_ENABLE_TT_ACTION, updateOrder)
}