import React, {useEffect, useRef, useState} from 'react'
import { AppState } from '../../../store'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import logo from '../../../../assets/logo.svg'
import { useTranslation } from 'react-i18next'
import arrow from '../../../../assets/arrow.svg'
import TicketDialog from "./ticket_dialog";
import {TicketDialogData} from "./ticket_dialog";
import {usePrevious} from "../../statistics/pages/helper";
import {ITicketInDesk} from "../type";
import PQueue from 'p-queue';
import delay from 'delay';

export default () => {
  const { ticketsInQueue, ticketsOnDesk } = useSelector((state: AppState) => {
    return {
      ticketsInQueue: state.devicePage.ticketsInQueue,
      ticketsOnDesk: state.devicePage.ticketsOnDesk.filter(t => t.ticket.state === "assigned")
    }
  })

  const [ticketDialogData, setTicketDialogData] = useState<TicketDialogData | null>(null)
  const [dialogTicketOpen, setDialogTicketOpen] = useState(false)

  const { t } = useTranslation()

  const queue = useRef(new PQueue({concurrency: 1})).current

  const getSize = () => {
    if (ticketsOnDesk.length <= 5)
      return "size-5"
    else if (ticketsOnDesk.length == 6)
      return "size-6"
    else if (ticketsOnDesk.length == 7)
      return "size-7"
    else
      return "size-8"
  }

  const showNewTickets = async function (prevTicketsOnDesk: ITicketInDesk[] | undefined, ticketsOnDesk: ITicketInDesk[]) {
    for (let ticketOnDesk of ticketsOnDesk) {
      if (prevTicketsOnDesk?.find(prevTicket => prevTicket.ticket.id == ticketOnDesk.ticket.id))
        continue

      setTicketDialogData({
        ticketNumber: ticketOnDesk.ticket.ticket_no,
        ticketWindow: ticketOnDesk.desk.display_name,
        ticketSound: ticketOnDesk.sound.ticket
      })
      setDialogTicketOpen(true)
      await delay(5000)
      setDialogTicketOpen(false)
      await delay(500)
    }
  }

  const prevTicketsOnDesk = usePrevious(ticketsOnDesk)
  useEffect(() => {
    queue.add(async () => {
      await showNewTickets(prevTicketsOnDesk?.slice(), ticketsOnDesk.slice())
    })
  }, [JSON.stringify(ticketsOnDesk.map(ticketOnDesk => ticketOnDesk.ticket.id))])

  const isSmall = ticketsOnDesk.length > 7
  //const isSmall = true
  if (ticketsOnDesk.length === 0 && ticketsInQueue.length === 0) {
    return <Box className="tv-screen-wrapper">
      <Box display="flex" alignItems="center" flexDirection="column">
        <img className="tv-logo" src={logo} alt="logo" />
        <Typography variant="h1" className="tv-logo-caption">
          {t("electric_queue")}
        </Typography>
      </Box>
    </Box>
  }
  return <>
    <div className="tv-wrapper">
      {isSmall ?
        <div className="tv-header-small">
          <div className="ticket1">{t('coupon')}</div>
          <div className="desk1">{t('desk')}</div>
          <div className="ticket2">{t('coupon')}</div>
          <div className="desk2">{t('desk')}</div>
          <div className="waiting">{t('waiting')}</div>
        </div>
        :
        <div className="tv-header">
          <div className="ticket">{t('coupon')}</div>
          <div className="desk">{t('desk')}</div>
          <div className="waiting">{t('waiting')}</div>
        </div>
      }
      <div className="tv-queue">
        <div className={"tv-desk-queue" + (isSmall ? " tv-desk-queue-small" : "")}>
            {ticketsOnDesk.map((td, index) => {
              if (index < 9) {
                return <div key={td.ticket.id} className={"tv-ticket-queue " + getSize()}>
                  <div className="bolder tv-ticket-q-ticket">{td.ticket.ticket_no}</div>
                  <img className="arrow tv-ticket-q-arrow" src={arrow} alt="arrow" />
                  <div className={"bolder queue tv-ticket-q-desk " + (isSmall ? " truncate-small" : "truncate")}>{td.desk.display_name}</div>
                </div>
              } else if (index == 9) {
                return <div key={td.ticket.id} className={"tv-ticket-queue " + getSize()}>
                  <Typography variant="h3">...</Typography>
                </div>
              }
            })}
        </div>
        <div className="tv-in-queue">
          {ticketsInQueue.map((td , index) => {
            if (index < 60) {
              return <div key={td.ticket.id} className="tv-ticket-number-queue">
                {td.ticket.ticket_no}
              </div>
            } else if (index == 60) {
              return <div key={td.ticket.id} className="tv-ticket-number-queue">
                <Typography variant="h3">...</Typography>
              </div>
            }
          })}
        </div>
      </div>
    </div>
    <TicketDialog title={t('invite')} ticketDialog={ticketDialogData} open={dialogTicketOpen} setOpen={setDialogTicketOpen} />
  </>
}
