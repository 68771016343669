import { EntityId } from "../../../features/entity/type"
import { TicketState } from "../../../models/ticket/ticket"
import { TState } from "./components/state"
import {ITicketChange} from "../../../models/ticket_change";

interface IAdminDashBoardData {
  tickets_finished: number
  tickets_started: number
  tickets_waiting: number
  experts_active: number
  experts_busy: number
  by_lines: {
    line_id: EntityId
    line_display_name: string
    tickets_count: number
  }[]
  queue: {
    ticket_id: EntityId
    ticket_no: string
    state: TicketState
    wait_time: number
    client_data: any
    changes: ITicketChange[]
  }[]
  experts: {
    expert_id: EntityId
    expert_name: string
    work_time: number
    desk_id: EntityId
    desk_name: string
    status: TState
  }[]
  waiting_time: {
    min_time: number
    average_time: number
    max_time: number
  }
  working_time: {
    min_time: number
    average_time: number
    max_time: number
  }
}

export type TAdminDashBoardState = {
  data?: IAdminDashBoardData
}

export const LOAD_ADMIN_REPORT = "DSH_LOAD_ADMIN_REPORT"

export interface ILoadAdminReportAction {
  type: typeof LOAD_ADMIN_REPORT,
  response: any
}

export const loadReportAction = (report: any): ILoadAdminReportAction => {
  return {
    type: LOAD_ADMIN_REPORT,
    response: report
  }
}

export type AdminDashBoardAction = ILoadAdminReportAction