import React from 'react'
import { AppState } from '../../store'
import { SELECT_ANOTHER_LINE } from './type'
import { EntityId } from '../../../features/entity/type'
import { useSelector, useDispatch } from 'react-redux'
import { RemoteSyncLineContainer } from '../../../models/line'
import { Line } from "../../../models/line"
import { Button } from '@material-ui/core'
import QueueIcon from '@material-ui/icons/Queue'

export default () => {
  const lines = useSelector(({ lines }: AppState) => {
    let filtered: Line[] = []
    for (const key in lines.entities) {
      filtered.push(lines.entities[key] as Line)
    }
    return filtered
  })
  const ticket = useSelector(({ expertPage }: AppState) => expertPage.currentTicket)
  const dispatch = useDispatch()
  const onLineClickFab = (lineId: EntityId) => () => dispatch({
    type: SELECT_ANOTHER_LINE,
    lineId
  })
  return <>
    {ticket ?
      <RemoteSyncLineContainer customUrl={`tickets/${ticket.id}/lines_for_forwarding`} />
      : null}
    {lines.map(line => <Button key={line.id} onClick={onLineClickFab(line.id)}>
      <QueueIcon />{line.display_name}
    </Button>)}
  </>
}