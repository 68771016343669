import { Hub } from './hub'
import { buildStore, buildTableContainer, buidlRemoteSyncContainer } from "../../features/entity"
import { EntityId } from "../../features/entity/type"

export const HubReducer = buildStore(Hub)
export const RemoteSyncHubContainer = buidlRemoteSyncContainer(Hub)
export const ListHubContanier = buildTableContainer(Hub)
export function loadHub(hubId: EntityId) {
  return {
    type: "FETCH_ACTION",
    url: "hubs/" + hubId,
    options: { method: "GET" },
    callbackAction: "hubs_SINGLE_LOAD_ACTION"
  }
}
export { Hub }