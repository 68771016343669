import {
  CHANGE_PAGE,
  DevicePageState,
  IChangePageAction,
  LOADING_PAGE,
  SET_AUDIO_RING,
  SET_CLIENT_DATA,
  SET_CURRENT_CITY,
  SET_CURRENT_DEVICE,
  SET_CURRENT_HUB,
  SET_DAY_ACTION,
  SET_DEVICE_TYPE,
  SET_ERROR,
  SET_LINE_ID, SET_LINES, SET_LOYALTY,
  SET_TICKET,
  SET_TICKETS_IN_QUEUE,
  SET_TIME_SLOT,
  TDeviceAction,
  TPage
} from "./type"
import moment from "moment"

const defaultState: DevicePageState = {
  page: LOADING_PAGE,
  selected_day: moment(),
  ticketsInQueue: [],
  ticketsOnDesk: [],
  lines: [],
  timeSlotId: undefined,
  errorMessag: undefined,
  deviceType: undefined,
  ticket: undefined
}

export const changePageAction = (page: TPage): IChangePageAction => {
  return {
    type: CHANGE_PAGE,
    page
  }
}

export default (state: DevicePageState = defaultState, action: TDeviceAction): DevicePageState => {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      }

    case SET_ERROR:
      return {
        ...state,
        errorMessag: action.error
      }

    case SET_DEVICE_TYPE:
      return {
        ...state,
        deviceType: action.deviceType
      }

    case SET_CURRENT_DEVICE:
      return {
        ...state,
        device: action.device
      }

    case SET_DAY_ACTION:
      return {
        ...state,
        selected_day: action.date
      }

    case SET_TIME_SLOT:
      return {
        ...state,
        timeSlotId: action.timeSlotId
      }

    case SET_LINE_ID:
      return {
        ...state,
        lineId: action.lineId
      }

    case SET_LOYALTY:
      return {
        ...state,
        loyalty: action.loyalty
      }

    case SET_CLIENT_DATA:
      return {
        ...state,
        clientData: action.clientData
      }

    case SET_TICKETS_IN_QUEUE:
      return {
        ...state,
        ticketsInQueue: action.ticketsInQueue,
        ticketsOnDesk: action.ticketsOnDesk
      }

    case SET_LINES:
      return {
        ...state,
        lines: action.lines
      }

    case SET_CURRENT_HUB:
      return {
        ...state,
        hub: action.hub
      }

    case SET_TICKET:
      return {
        ...state,
        ticket: action.ticket
      }

    case SET_AUDIO_RING:
      return {
        ...state,
        audioTag: action.audio
      }

    case SET_CURRENT_CITY:
      return {
        ...state,
        city: action.city
      }
    default:
      return state
  }
}
