import {IStatisticsAction, StatisticState, END_LOAD_REPORT, SET_TASK_ID, ISetTaskAction} from "./type"

const initState: StatisticState = {
  taskId: '',
  data: undefined,
  isLoading: false
}

export default (state: StatisticState = initState, action: IStatisticsAction | ISetTaskAction): StatisticState => {
  switch (action.type) {

    case SET_TASK_ID:
      return {
        ...state,
        taskId: action.taskId,
      }

    case END_LOAD_REPORT:
      return {
        ...state,
        data: action.response,
        isLoading: action.isLoading
      }

    default:
      return state
  }
} 