import React, { ReactNode } from 'react'
import { Paper } from '@material-ui/core'

export default (props: { caption: string, children: ReactNode }) => <Paper 
  variant="outlined" 
  elevation={2} 
  className="dash-plate plate-time-dash">
  <p className="dash-plate-time plate-time-dash">
    {props.children}
  </p>
  <p className="dash-plate-caption">
    {props.caption}
  </p>
</Paper>