import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { logOutAction} from '../reducers/store'
import { useHistory } from 'react-router-dom'
import {LoadingCircularProgress} from "../../shared/loading_progress";
import {Box} from "@material-ui/core";

const mapStateToProps = () => {
  return {
  }
}

const mapDispatch = {
  lga: logOutAction
}

const connector = connect(mapStateToProps, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux


export default connector(({lga}:Props) => {
  const history = useHistory()
  useEffect(()=>{
    lga()
    setTimeout(() => history.push("/"), 100)
  })
  return <Box style={{width: "100%", height: "100%", position: "absolute"}}
              display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <LoadingCircularProgress />
        </Box>
})