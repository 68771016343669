import React from 'react';
import { BaseAttribute, BaseEntity } from "..";
import { Column } from 'material-table';
export class LinkToChildAttr extends BaseAttribute {
  title: string
  render: (r: BaseEntity) => React.ReactElement = () => <></>;
  constructor(render: (r: BaseEntity) => React.ReactElement, title="link") {
    super("nothing");
    this.render = render;
    this.title = title
  }
  setVal() {
  }
  getDisplayValue(): string {
    return "";
  }
  getVal(source: BaseEntity): any {
    return "";
  }
  change<T extends BaseEntity>(source: T): T {
    return source;
  }
  showRender() {
    return <></>;
  }
  editRender() {
    return <></>;
  }
  mapState(appState: any): {
    [key: string]: BaseEntity;
  } {
    return {};
  }
  buildColumn(): null | Column<BaseEntity> {
    return {
      title: this.title,
      editable: "never",
      render: this.render
    };
  }
}
