import React from 'react'
import { AppState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { Redirect } from 'react-router-dom'

const mapState = ({ policy }: AppState) => {
  return {
    currentRole: policy.currentRole
  }
}
const connector = connect(mapState, {})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(({ currentRole }: PropsFromRedux) => {
  if (currentRole) {
    switch (currentRole) {
      case "admin":
        return <Redirect to={"/admin"} />

      case "expert":
      case "manager":
        return <Redirect to={"/expert"} />
      
      case "device":
        return <Redirect to={"/device"} />
    }
  }
  return <Redirect to={"/user"} />
})