import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import {Box, makeStyles, useTheme} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {ITicketChangeClientData} from "../../ticket_change_client_data";
import {i18n} from "i18next";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none"
  }
}))

type Props = {
  history: ITicketChangeClientData[]
}

const datePrepare = (i18n: i18n, dS: string) => {
  moment.locale(i18n.language)
  const date = moment(dS)
  return `${date.format("HH:mm DD MMMM YYYY")}`
}

interface ItemProps {
  item: ITicketChangeClientData
}

const Item = ({ item }: ItemProps) => {
  const { t, i18n } = useTranslation()
  return <Box display="flex" flexDirection="row">
    <Box>
      {datePrepare(i18n, item.created_at)}
    </Box>
    <Box marginLeft={1} marginRight={1}>
      —
    </Box>
    <Box display="flex" flexDirection="column">
      <Box>
        {t('phone')}: <b>{t(item.client_data.phone)}</b>
      </Box>
      <Box marginTop={1}>
        {t('first_name')}: <b>{t(item.client_data.first_name)}</b>
      </Box>
      <Box marginTop={1}>
        {t('last_name')}: <b>{t(item.client_data.last_name)}</b>
      </Box>
    </Box>
  </Box>
}

export default ({ history }: Props) => {
  const theme = useTheme()
  const classes = useStyles()
  if (history.length === 0)
    return <></>
  return <Timeline align="left">
    {history.slice(0, history.length - 1).map((ch, index) => <TimelineItem
      key={index}
    >
      <TimelineSeparator>
        {index > 0 ? <>
          <TimelineConnector style={{
            backgroundColor: theme.palette.primary.main,
          }} className="before-connector timeline-connector" />
          <TimelineDot
            variant="outlined"
            color="primary" />
        </>
          :
          <TimelineDot
            variant="outlined"
            style={{ marginTop: 7 }}
            color="primary" />}
        <TimelineConnector style={{
          backgroundColor: theme.palette.primary.main,
        }} />
      </TimelineSeparator>
      <TimelineContent qa-tag={`ep-${ch.id}-history-item`}>
        <Item item={ch} />
      </TimelineContent>
    </TimelineItem>)}

    <TimelineItem
      className={"missingOppositeContent"}>
      <TimelineSeparator>
        {history.length > 1 ? <><TimelineConnector style={{
          backgroundColor: theme.palette.primary.main,
        }} className="before-connector timeline-connector" />
          <TimelineDot color="primary" className={classes.root} />
        </>
          :
          <TimelineDot color="primary" className={classes.root} style={{ marginTop: 7 }} />
        }

      </TimelineSeparator>
      <TimelineContent qa-tag={`ep-${history[history.length - 1].id}-history-item`}>
        <Item item={history[history.length - 1]} />
      </TimelineContent>
    </TimelineItem>
  </Timeline>
}