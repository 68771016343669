import React from 'react'
import { useRouteMatch, Switch, Route, useHistory } from 'react-router-dom'
import { ListHubContanier } from '.'
import { RemoteSyncDeviceContainer, ListDeviceContanier } from '../device'
import TimeSlotAdminPage from '../time_slot/page'
import { Modal } from '../../shared/components/modal'
import { useTranslation } from 'react-i18next'

type TOwnProps = {
  editable: boolean
  tabs?: React.ReactElement
}

export default (props: TOwnProps) => {
  const matchRoot = useRouteMatch()
  const location = useHistory()
  const { t } = useTranslation()
  return <>
    <Switch>
      <Route path={`${matchRoot.path}/:hub_id/time_slots`}
        render={({ match }) => {
          return <>
            <Modal title={t('Time slots')} className="timeslot-dialog"
              titleAdditional={""}
              open={true}
              setOpen={(v) => {
                if (!v) {
                  location.push(`${matchRoot.path}`)
                }
              }}
              actions={<></>}>
              <TimeSlotAdminPage hub_id={match.params.hub_id} />
            </Modal>
            <ListHubContanier {...props} />
          </>
        }} />
      <Route path={`${matchRoot.path}/:hub_id/devices`}
        render={({ match }) => {
          return <>
            <ListDeviceContanier tabs={props.tabs} hub_id={match.params.hub_id} />
            <RemoteSyncDeviceContainer />
          </>
        }} />
      <Route path={`${matchRoot.path}`}>

        <ListHubContanier {...props} />
      </Route>
    </Switch>
  </>
}