import { makeStyles, Theme, createStyles, List, Box, Typography, Container, Paper } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { MainMenu } from "../shared/main_menu"
import MiniDrawer from '../../../shared/components/mini_drawer'
import { useTranslation } from "react-i18next"
import { RemoteSyncCityContainer } from "../../../models/city"
import { RemoteSyncHubContainer, Hub } from "../../../models/hub"
import { RemoteSyncLineContainer, Line } from "../../../models/line"
import { RemoteSyncDeskContainer } from "../../../models/desk"
import { RemoteSyncUserContainer } from "../../../models/user"
import {
  bildUrl,
  loadFilterMultitipleFromLocalStorage,
  loadFilterToLocalStorage,
  NameFilter,
  TypeFilter
} from "../statistics/pages/helper"
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../../store"
import { EntityId } from "../../../features/entity/type"
import MultipleField from '../statistics/components/multiple'
import { fetchRequest } from "../../../features/entity/store/actions"
import {LOAD_ADMIN_REPORT} from "./type_admin"
import Plate from './components/plate'
import TimeDash from './components/time_dash'
import PieChart from './components/pie'
import LineDash from './components/line_dash'
import ExpertDash from './components/expert_dash'
import { colorizeData } from "./helper"
import {activeTabColor, linkColor, tabTextWeight, textColor, textLightTitleColor} from "../../../styles/variables";
import DatePicker from "../statistics/components/daterange";
import {DateRange} from "@material-ui/pickers";
import {getCommonItems} from "../../../shared/helper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    title: {
      margin: "28px 0 18px 0px",
      fontSize: 24,
      fontWeight: 500,
      color: textColor,
      lineHeight: "28px"
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      color: textColor,
      lineHeight: "11px"
    },
    links: {
      width: "260px",
      height: "19px",
      fontSize: 14,
      color: linkColor
    },
    accordion: {
      marginBottom: 15
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingBottom: theme.spacing(4),
      maxWidth: "100%"
    },
    appBarSpacer: theme.mixins.toolbar,

    paper: {
      marginTop: theme.spacing(1),
      padding: "24px",
    },
    pageHead: {
      marginBottom: "30px"
    },
    tabsWrapper: {
      margin: "0px 0 0 24px",
      minWidth: 607,

      '& > *': {
        marginRight: 32,
        fontWeight: tabTextWeight,
        fontSize: 16,
        color: textLightTitleColor,
        paddingBottom: 10,
        textDecoration: "none",
        paddingTop: 10
      },
      '& > .active': {
        marginRight: 32,
        fontWeight: tabTextWeight,
        fontSize: 16,
        paddingBottom: 8,
        color: textColor,
        borderBottom: `2px solid ${activeTabColor}`
      },
      '& > *:hover': {
        borderBottom: `2px solid ${textLightTitleColor}`,
        textDecoration: "none",
        paddingBottom: 8,
      },
      '& > .active:hover': {
        borderBottom: `2px solid ${activeTabColor}`
      }
    }
  })
)

let timerId: NodeJS.Timeout | null = null

export default () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector((state:AppState) => state.adminDashBoard.data)

  const { hubs, lines } = useSelector((state: AppState) => ({
    hubs: Object.keys(state.hubs.entities).map(k => state.hubs.entities[k] as Hub),
    lines: Object.keys(state.lines.entities).map(k => state.lines.entities[k] as Line)
  }))

  const lineIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Dashboard, NameFilter.Line)
  const hubIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Dashboard, NameFilter.Hub)

  const [lineIds, setLineIds] = useState<EntityId[]>(lineIdsLS)
  const [hubIds, setHubIds] = useState<EntityId[]>(hubIdsLS)

  const [date, setDate] = useState<DateRange<Date>>([new Date(), new Date()])

  const valideInputs = lineIds.length > 0 && hubIds.length > 0
  const loadReports = () => {
    dispatch(fetchRequest(bildUrl("dashboard", {
      line_ids: lineIds,
      hub_ids: hubIds,
      since: date[0],
      till: date[1]
    }), {
      method: "GET"
    }, LOAD_ADMIN_REPORT))
  }
  useEffect(() => {
    if (valideInputs){
      if (timerId){
        clearTimeout(timerId)
      }
      loadReports()
      timerId = setInterval(loadReports, 30000)
    }
  }, [lineIds, hubIds, date])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Dashboard, NameFilter.Line, lineIds)
  }, [lineIds])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Dashboard, NameFilter.Hub, hubIds)
  }, [hubIds])

  useEffect(() => {
    if (lineIds.length > 0 && lines.length > 0) {
      const newLineIds = lines.map(line => line.id)
      setLineIds(getCommonItems(lineIds, newLineIds))
    }
  }, [lines.length])

  useEffect(() => {
    if (hubIds.length > 0 && hubs.length > 0) {
      const newHubIds = hubs.map(hubId => hubId.id)
      setHubIds(getCommonItems(hubIds, newHubIds))
    }
  }, [hubs.length])

  const menu = <List>
    <MainMenu />
  </List>
  const pieData = data ? colorizeData(data?.by_lines.map(d => ({ name: d.line_display_name, value: d.tickets_count }))) : []
  return (<MiniDrawer
    headerTitle="Admin page"
    menu={menu}>
    <main className={classes.content + " dashbord-wrp info-window"}>
      <RemoteSyncCityContainer />
      <RemoteSyncHubContainer />
      <RemoteSyncLineContainer />
      <RemoteSyncDeskContainer />
      <RemoteSyncUserContainer />
      <Box className="title-page-margin" >
        <Typography variant="h5" className="title-page" style={{marginBottom: "30px"}}>{t("dashboard")}</Typography>
      </Box>
        <Container maxWidth="lg" className={classes.container}>
          <Paper className={classes.paper}>
            <Box display="flex" className="dash-plates" flexDirection="row" >
              <MultipleField setVal={setLineIds} val={lineIds} collection={lines} label={t("line")} />
              <MultipleField setVal={setHubIds} val={hubIds} collection={hubs} label={t("hub")} />
              <DatePicker setVal={setDate} val={date} />

              <Box flexGrow={1} />
            </Box>
            <Box display="flex" flexDirection="row">
              <Plate caption={t("tickets done")} >{data?.tickets_finished}</Plate>
              <Plate caption={t("tickets in work")} >{data?.tickets_started}</Plate>
              <Plate caption={t("tickets wait")} >{data?.tickets_waiting}</Plate>
              <Plate caption={t("manager in work")} >{data?.experts_busy}<span>/ {data ? data.experts_active : null}</span></Plate>
            </Box>
            <Box>
              {/* <Chart/> */}
            </Box>
            <Box display="flex" flexDirection="row" className="dash-two-in-row" style={{marginBottom: 42}}>
              <TimeDash data={
                {
                  title: t('time wait in line'),
                  minCaption: t('min time wait'),
                  avgCaption: t('avg time wait'),
                  maxCaption: t('max time wait'),
                  minTime: data?.waiting_time?.min_time || 0,
                  avgTime: data?.waiting_time?.average_time || 0,
                  maxTime: data?.waiting_time?.max_time || 0
                }
              } />
              <TimeDash data={
                {
                  title: t('time process ticket in line'),
                  minCaption: t('min time process'),
                  avgCaption: t('avg time process'),
                  maxCaption: t('max time process'),
                  minTime: data?.working_time?.min_time || 0,
                  avgTime: data?.working_time?.average_time || 0,
                  maxTime: data?.working_time?.max_time || 0
                }
              } />
            </Box>
            <Box>
              <PieChart data={pieData} />
            </Box>
            <Box display="flex" flexDirection="row" className="dash-two-in-row">
              <LineDash data={data?.queue} />
              <ExpertDash data={data?.experts} />
            </Box>
          </Paper>
        </Container>
    </main>
  </MiniDrawer>
  )
}
