import React from 'react'
import { Paper, makeStyles } from '@material-ui/core'
import { IWrapperProps } from './create'

const useStyles = makeStyles((theme) => ({

  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(4),
  }
}))

export const WraperPaper = ({ children }: IWrapperProps) => {
  const classes = useStyles()
  return <Paper className={classes.paper}>
    {children}
  </Paper>
}
