import { BaseAttribute, BaseEntity, HasMany } from "../../features/entity"
import { EntityId } from "../../features/entity/type"
import { Field } from "../field"
import { IRawTicketTemplate } from "./type"

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BaseAttribute("enabled"),
  new HasMany("fields", Field, 'ticket_template')
]

export class TicketTemplate extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "TicketTemplate"
  fields: Field[] = []
  display_name: string
  enabled: boolean = false
  constructor(display_name: string = "", id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
  }
  toNoField(): IRawTicketTemplate{
    return {
      id: this.id,
      display_name: this.display_name,
      enabled: this.enabled
    }
  }
}
