import React, { useState } from "react"
import { connect, ConnectedProps} from "react-redux"
import {goNextAction, setLoyaltyAction} from "../type"
import {Typography, Box, Button} from "@material-ui/core"
import SButton from './button'
import { useTranslation } from "react-i18next"

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  setLoyaltyAction,
  goNextAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromReduc = ConnectedProps<typeof connector>

export default connector(({setLoyaltyAction, goNextAction}: PropsFromReduc) => {

  const { t } = useTranslation()
  const loyaltys = [
    {
      id: 1,
      name: "high",
      displayName: t("platinum_gold")
    },
    {
      id: 2,
      name: "elevated",
      displayName: t("silver_bronze")
    },
    {
      id: 3,
      name: "normal",
      displayName: t("without_status")
    }
  ]

  const [selectLoyaltyId, setSelectLoyaltyId] = useState(3)

  const onSelectQueue = () => {
    setLoyaltyAction(loyaltys.find(loyalty => loyalty.id == selectLoyaltyId)?.name || "")
    goNextAction()
  }

  return <>
    <Typography className="phone-header" variant="h2">{t('status_loyalty')}</Typography>
    {
      loyaltys?.map(loyalty => {
        return <Button qa-tag="line-term-btn"
          disableRipple={true}
          key={loyalty.id}
          style={{ margin: "10px", marginLeft: 0, marginBottom: 0 }}
          variant="outlined"
          color="secondary"
          onClick={() => setSelectLoyaltyId(loyalty.id)}
          className={"default-button default-button-terminal " + (loyalty.id === selectLoyaltyId ? "active-default-button-terminal" : "")}>
          {loyalty.displayName}
        </Button>
      })
    }

    <Box flexGrow={1} />
    <SButton variant={"filled"} size="normal" disable={!selectLoyaltyId}
      onClick={() => onSelectQueue()}>
      <Box qa-tag="confirm-line">{t('next')}</Box>
    </SButton>
    {/* <Mc1 /> */}
  </>
})
