import React from 'react'
import { BaseAttribute, BaseEntity } from '../../features/entity'
import { LinkToChildAttr } from '../../features/entity/attributes/link_to_child_attr'
import { EntityId } from '../../features/entity/type'
import { Link } from 'react-router-dom'
import {BelongsToAttribute} from "../../features/entity/attributes/belongs_to_attribute";
import i18next from "i18next";

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BelongsToAttribute({
    key: "mode",
    parentClassStorageEntityKey: "modes",
    displayAttribute: "mode",
    editable: "onAdd"
  }),
  new LinkToChildAttr((row) => <>
    {row ?
      <Link to={`lines/${row.id}/tickets`}>
        {i18next.t('tickets')}
      </Link>
      : null}
  </>),
  new BaseAttribute('default', "never", true),
  new BaseAttribute('allowed', "never", true),
  new BaseAttribute('tv_allowed', "never", true)
]

export class Line extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Line"
  display_name: string
  mode: string
  default: boolean = false
  allowed: boolean = false
  tv_allowed: boolean = true
  constructor(display_name: string = "", mode: string = "", id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
    this.mode = mode
  }
}

export function loadLine(lineId: EntityId) {
  return {
    type: "FETCH_ACTION",
    url: "lines/"+lineId,
    options: {method: "GET"},
    callbackAction: "lines_SINGLE_LOAD_ACTION"
  }
}