import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles, createStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import StateChip, { TState } from './state'
import {getHoursWork} from "../helper";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
    }
  })
)

type TProps = {
  data?: {
    expert_name: string
    desk_name: string
    work_time: number
    status: TState
  }[]
}

export default ({ data }: TProps) => {
  if (!data)
    return <></>
  const classes = useStyles()
  const { t } = useTranslation()
  return <Card variant="outlined">
    <CardHeader title={t("expert work")} classes={classes}/>
    <CardContent>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("expert")}</TableCell>
            <TableCell>{t("desk")}</TableCell>
            <TableCell>{t("work_time")}</TableCell>
            <TableCell>{t("state")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.expert_name}</TableCell>
              <TableCell>{row.desk_name}</TableCell>
              <TableCell>{getHoursWork(row.work_time, t('h'), t('min'), t('sec'))}</TableCell>
              <TableCell scope="row">
                <StateChip s={row.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardContent>
  </Card>
}