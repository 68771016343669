import { BaseAttribute, BaseEntity, buildStore, buildTableContainer, buidlRemoteSyncContainer } from "../features/entity"
import { EntityId } from "../features/entity/type"
import {BelongsToAttribute} from "../features/entity/attributes/belongs_to_attribute";

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BelongsToAttribute({
    key: "lang",
    parentClassStorageEntityKey: "languages",
    displayAttribute: "lang"
  }),
  new BaseAttribute('phone_code')
]

export class City extends BaseEntity{
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "City"
  display_name: string
  phone_code: string
  lang: string
  tenant_id: EntityId = "-1"
  constructor(display_name: string="", phone_code: string="+7", lang: string="", id: EntityId = "-1"){
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
    this.phone_code = phone_code
    this.lang = lang
  }
}

export const CityReducer = buildStore(City)
export const ListCityContanier = buildTableContainer(City)
export const RemoteSyncCityContainer = buidlRemoteSyncContainer(City)
