import { TEntityClass, IFetchAction, FETCH_ACTION } from '../type'
import { BaseEntity } from "../BaseEntity"
import { getStorageEntityKey } from '../helpers/storage_key'

export function buildActionTypeName<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>(t: TEClass) {
  const prefix = getStorageEntityKey(t)
  return {
    CREATE_ACTION_TYPE: prefix + "_CREATE_ACTION",
    UPDATE_ACTION_TYPE: prefix + "_UPDATE_ACTION",
    DELETE_ACTION_TYPE: prefix + "_DELETE_ACTION",
    RELOAD_ACTION_TYPE: prefix + "_RELOAD_ACTION",
    SINGLE_LOAD_ACTION_TYPE: prefix + "_SINGLE_LOAD_ACTION"
  }
}

export function fetchRequest(url: RequestInfo, options: RequestInit,
  callbackAction: string, customPrefix?: string): IFetchAction {

  return {
    type: FETCH_ACTION,
    url, options, callbackAction,
    apiPrefix: customPrefix
  }
}