import React from 'react'
import InputMask from 'react-input-mask'
import {TextField} from "@material-ui/core";

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props

  return (
    <InputMask
      {...other}
      placeholder={"dd/mm/yyyy"}
      mask={"99/99/2999"}
      maskChar=" "
    />
  )
}

interface IProps {
  className: string
  value: any
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function DateTextEdit(props: IProps) {
  return (
    <TextField
        className={props.className}
        inputProps={{min: 0, style: { textAlign: 'center' }}}
        InputProps={{
          inputComponent: TextMaskCustom as any,
          value: props.value,
          onChange: props.onChange,
        }}
    />
  )
}
