import { BaseAttribute, BaseEntity } from "../../features/entity"
import { EntityId } from "../../features/entity/type"
import { BelongsToAttribute } from "../../features/entity/attributes/belongs_to_attribute"
import { LinkToChildAttr } from "../../features/entity/attributes/link_to_child_attr"
import { Button } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { fetchRequest } from "../../features/entity/store/actions"
import { StatickLookup } from "../../features/entity/attributes/static_lookup"

type ActionProps = {
  device: Device
}

const Actions = ({ device }: ActionProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const click = (type: "block" | "reset") => {
    return () => {
      dispatch(fetchRequest(`devices/${device.id}/${type}`, {
        method: "PATCH"
      }, "devices_CREATE_ACTION"))
    }
  }
  return <>
    <Button
      variant="outlined"
      onClick={click("block")}
      disabled={!(device.state === "activated" || device.state === "created")}
      className="default-button">
      {t("block")}
    </Button>
    <Button
      variant="outlined"
      onClick={click("reset")}
      disabled={!(device.state === "activated" || device.state === "blocked")}
      className="default-button">
      {t("reset")}
    </Button>
  </>
}

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BaseAttribute('state', "never"),

  new StatickLookup("kind", "always", false, {
    terminal: "terminal",
    tv: "tv",
    tablet: "tablet"
  }),
  new BaseAttribute('activation_code', "never"),
  new LinkToChildAttr((row) => {
    return <>
      {row ?
        <Actions device={row as Device} />
        : null}
    </>
  }, "block/reset"),
  new BelongsToAttribute({
    key: "hub_id",
    parentClassStorageEntityKey: "hubs",
    displayAttribute: "display_name"
  }),
]

type TDeviceState = "blocked" | "created" | "activated" | ""

export class Device extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Device"
  display_name: string = ""
  kind: string = "tv"
  hub_id: EntityId = "-1"
  state: TDeviceState = ""
  activation_code: string = ""
  constructor(id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
  }
  isTerminal() {
    return this.kind !== "tv"
  }
}
