import { EntityId } from "../../features/entity/type"
import {takeEvery, put} from "redux-saga/effects"
import { fetchM } from "../../config/pages/shared/fetchM"
import { fetchRequest } from "../../features/entity/store/actions"
import { getApiResponse } from "../../config/pages/authentication/reducers/sagas";

export const SET_DEFAULT_LINE_ACTION = "SET_DEFAULT_LINE_ACTION"

interface ISetDefaultLineAction{
  type: typeof SET_DEFAULT_LINE_ACTION
  lineId: EntityId
}
export const setDefaultLine = (lineId: EntityId) => {
  return {
    type: SET_DEFAULT_LINE_ACTION,
    lineId
  }
}

function* updateOrder({lineId}:ISetDefaultLineAction){
  yield fetchM(`lines/${lineId}/set_default`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    }
  })
  yield put(fetchRequest('lines', {method: "GET"}, 'lines_RELOAD_ACTION'))

}

export const SET_UPDATE_ALLOWED_LINE_ACTION = "SET_UPDATE_ALLOWED_ACTION"

interface ISetUpdateAllowedLineAction{
  type: typeof SET_UPDATE_ALLOWED_LINE_ACTION
  lineId: EntityId
  isAllowed: boolean
}

export const setUpdateAllowedLineAction = (lineId: EntityId, isAllowed: boolean): ISetUpdateAllowedLineAction => {
  return {
    type: SET_UPDATE_ALLOWED_LINE_ACTION,
    lineId,
    isAllowed
  }
}

function* updateAllowedLineAction({lineId, isAllowed}: ISetUpdateAllowedLineAction) {
  yield fetchM(`lines/${lineId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ allowed: isAllowed })
  })
  yield put(fetchRequest('lines', {method: "GET"}, 'lines_RELOAD_ACTION'))
}

export const SET_UPDATE_TV_ALLOWED_LINE_ACTION = "SET_UPDATE_TV_ALLOWED_ACTION"

interface ISetUpdateTVAllowedLineAction{
  type: typeof SET_UPDATE_TV_ALLOWED_LINE_ACTION
  lineId: EntityId
  isAllowed: boolean
}

export const setUpdateTVAllowedLineAction = (lineId: EntityId, isAllowed: boolean): ISetUpdateTVAllowedLineAction => {
  return {
    type: SET_UPDATE_TV_ALLOWED_LINE_ACTION,
    lineId,
    isAllowed
  }
}

function* updateTVAllowedLineAction({lineId, isAllowed}: ISetUpdateTVAllowedLineAction) {
  yield fetchM(`lines/${lineId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ tv_allowed: isAllowed })
  })
  yield put(fetchRequest('lines', {method: "GET"}, 'lines_RELOAD_ACTION'))
}

export function* getLinesForForward(ticketId: string) {
  return yield getApiResponse(`/tickets/${ticketId}/lines_for_forwarding`, 'GET')
}

export function* linesSagaRegestration(){
  yield takeEvery(SET_DEFAULT_LINE_ACTION, updateOrder)
  yield takeEvery(SET_UPDATE_ALLOWED_LINE_ACTION, updateAllowedLineAction)
  yield takeEvery(SET_UPDATE_TV_ALLOWED_LINE_ACTION, updateTVAllowedLineAction)
}