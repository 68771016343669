import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { City } from "../../../models/city";
import { Hub } from "../../../models/hub";
import { User } from "../../../models/user";
import { AppState } from "../../store";

const DEFFAULT_LANG = "ru"

export function useLanguage(): [string, (lang: string)=>void] {
  const [currentLang, setCurrentLang] = useState(DEFFAULT_LANG)
  const { i18n } = useTranslation()
  const { own } = useSelector((state: AppState) => {
    const user = state.authentication.current_user_id ? (state.users.entities[state.authentication.current_user_id] as User) : undefined;
    const myHub = (state.hubs.entities[user?.hub_id || ""] as Hub | undefined)
    return {
      own: (state.cities.entities[myHub?.city_id || ""] as City | undefined)?.lang
    }
  })
  useEffect(() => {
    let res = window.localStorage.selectedLang || own || DEFFAULT_LANG;
    if (res !== currentLang){
      setCurrentLang(res)
      i18n.changeLanguage(res)
    }
  }, [own])

  return [currentLang,
    (lang: string) => { setCurrentLang(lang); window.localStorage.selectedLang = lang; i18n.changeLanguage(lang) }];
}