import React, { useEffect } from 'react'

interface IProps {
  reload: (customUrl?:string) => void
  customUrl?: string
  customPrefix?: string
}

export default ({ reload, customUrl }: IProps) => {
  useEffect(() => {reload(customUrl)})
  return <></>
}