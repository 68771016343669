import { put, takeEvery } from "redux-saga/effects"
import { PUSH_ICOMING } from "./constants"
import { alertDialogAction } from "./common_store"

function* helpRequest(action: any) {
  if (action.payload) {
    const payload = action.payload
    if (payload?.event === "help_request") {
      yield put(alertDialogAction(payload.data))
    }
  }
}

export function* updatePushAfterLogin() {
  yield takeEvery(PUSH_ICOMING, helpRequest)
}