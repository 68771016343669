import {ISelectItem} from "../config/pages/statistics/components/multiple";

export const zeroForm = (t: number) => "" + Math.floor(t / 10) + (t % 10)

export function arrayToString(selected: string[], collection: ISelectItem[]) {
  let result = "";
  (selected as string[]).forEach((value: string) => {
    const item = collection.find(item => item.id == value)
    if (item) {
      result += item.display_name + ', '
    }
  })
  return result.substr(0, result.length - 2)
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function truncate(str: string, n: number){
  return (str.length > n) ? str.substr(0, n-1) + '…' : str;
}

export function getCommonItems(arrayA: string[], arrayB: string[]) {
  return arrayA.filter(function(item) {
    return arrayB.indexOf(item) >= 0;
  });
}