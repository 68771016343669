import React, {useEffect, useState} from 'react'
import { EntityId } from '../../../features/entity/type'
import { AppState } from '../../../config/store'
import { useSelector, useDispatch } from 'react-redux'
import {makeStyles, Paper, Box, TextField, Button, Divider, Typography, Container, Grid} from '@material-ui/core'
import History from './history'
import {Ticket, TicketLoyalty} from '../ticket'
import { fetchRequest } from '../../../features/entity/store/actions'
import {TICKETS_HISTORY_LOAD, cancelTicketAction, saveTicketAction, changeTicketPriorityAction} from '../saga'
import ExpertDataEditor from './expert_data'
import { useTranslation } from 'react-i18next'
import {TClientData, TExpertData} from '../type'
import { Link } from 'react-router-dom'
import Header from './ticket_header'
import InfoDetailDialog from './info_detail_dialog'
import PhoneTickets from "../../../config/pages/ticket/phone_ticket";
import {clearValidationFieldsAction} from "../../../shared/validation/validation_store";
import CommentComponent from "../../comment/comment_component";
import {useCurrentUser} from "../../../config/pages/shared/use_current_user";
import Select from "../../../config/pages/statistics/components/select";
import FreezeDialog from "../../../config/pages/expert/freeze_dialog";
import {Comment} from "../../comment";
import PrintTicket from "./print_ticket"
import {Line} from "../../line";
import {LoadingCircularProgress} from "../../../config/pages/shared/loading_progress";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: 15,
    margin: "10px 0 0 0",
    display: "flex",
    paddingLeft: 0
  },
  paper: {
    marginTop: theme.spacing(0),
    padding: 24,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  buttonCardr: {
    marginBottom: theme.spacing(2)
  }
}))

type TProps = {
  ticket_id: EntityId
}

export default (p: TProps) => {
  const classes = useStyles()
  const { ticket, validation, line, open } = useSelector((st: AppState) => ({
    ticket: (st.tickets.entities[p.ticket_id]) as Ticket,
    validation: st.validation.page === `tickets ${p.ticket_id}` ? st.validation : null,
    line: (st.lines.entities[((st.tickets.entities[p.ticket_id]) as Ticket)?.line_id]) as Line,
    open: st.menu.open
  }))

  const currentUser = useCurrentUser()

  const [expertData, setExpertData] = useState<TExpertData>(ticket && ticket.expert_data ? { ...ticket.expert_data } : {})
  const [clientData, setClientData] = useState<TClientData>(ticket && ticket.client_data ? { ...ticket.client_data } : {})
  const [loyalty, setLoyalty] = useState<TicketLoyalty>(ticket?.priority || "")

  const [dialogInfoDetailOpen, setDialogInfoDetailOpen] = useState(false)
  const [dialogFreezeOpen, setDialogFreezeOpen] = useState(false)

  const [comments, setComments] = useState()
  const [resize, setResize] = useState(window.innerWidth)

  const dispatch = useDispatch()

  useEffect(() => {
    if (ticket) {
      setLoyalty(ticket.priority)
    }
  }, [ticket])

  useEffect(() => {
    if (ticket && ticket.priority != loyalty)
      dispatch(changeTicketPriorityAction(ticket.id, loyalty))
  }, [loyalty])

  useEffect(() => {
    dispatch(fetchRequest(`tickets/${p.ticket_id}/changes`, {method: "GET"},
      TICKETS_HISTORY_LOAD))
  }, [p.ticket_id])

  const { t } = useTranslation()

  useEffect(() => {
    if (ticket?.expert_data)
      setExpertData({ ...ticket?.expert_data })

  }, [ticket?.expert_data])

  useEffect(() => {
    if (ticket?.client_data)
      setClientData({ ...ticket?.client_data })

  }, [ticket?.client_data])

  const onCancelClick = () => {
    dispatch(cancelTicketAction(p.ticket_id))
  }

  useEffect(() => {
    return () => {
      dispatch(clearValidationFieldsAction());
    };
  }, [])

  const onChangeClientData = (prop: string, value: any) => {
    const newClientData = { ...clientData }
    newClientData[prop] = value
    setClientData(newClientData)
  }

  const onSaveClick = () => {
    dispatch(saveTicketAction(ticket, clientData, expertData))
  }

  const onShowInfo = () => {
    setDialogInfoDetailOpen(true)
  }

  const countSymbolsOfComments = (comments?: Comment[]): number => {
    let count = 0
    comments?.map((comment: Comment) => count += comment.message.length)
    return count
  }

  window.addEventListener('resize', function() {
    setResize(window.innerWidth)
  }, true);

  const validateClassName = "ep-failed-validate-field"

  if (!ticket)
    return <Box style={{width: "100%", height: "100%", position: "absolute"}}
                display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <LoadingCircularProgress />
            </Box>

  return <Container maxWidth="lg"
                    className={classes.container}
                    style={
                      ticket.history?.length > 10
                      || comments?.length > 2
                      || countSymbolsOfComments(comments) > 500
                        ? {}
                        : resize < 1338 && comments?.length > 1 && !open ? {} : {height: '100%'}}>
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="row">
        <Header status={""} ticket={ticket} />
        <Box display="flex" justifyContent="center" alignItems="center" className="detail-state">
          <span className="title-state">{t('state')}:</span><span className="text-state"  qa-tag="ticket-card-status">{t(ticket.state) }</span>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <PrintTicket ticket={ticket} line={line?.display_name || ''} />
        </Box>
        <Box flexGrow={1} />
        <Typography className="title-history-change"
                    onClick={() => onShowInfo()}>{t('history change')}</Typography>
      </Box>
      <Divider style={{marginTop: 20, marginBottom: 20}} />
      <Grid
        container
        direction="row"
        justify="space-between">
        <Grid xs={9}>
          <Box style={{marginLeft: '-5px'}}>
            {Object.keys(ticket?.client_data || {}).map((prop, index) =>
              prop !== "phone"
              ? <TextField
                  key={index}
                  label={t(prop)}
                  qa-tag="ticket-card-name"
                  value={clientData[prop]}
                  onChange={(event) => onChangeClientData(prop, event.target.value)}
                  className={`ep-client-data cl-field ${validation?.errorsClientData[prop] && !clientData[prop] ? validateClassName : ""}`}/>
              : <PhoneTickets value={clientData[prop]}
                  phone_code={ticket?.phone_code || ""}
                  className={validation?.errorsClientData[prop] && (clientData[prop] as string)?.trim()?.length < 12 ? validateClassName : ""}
                  key={index}
                  label={t(prop)}
                  onChange={(value) => onChangeClientData(prop, value)}
                  minPhoneNumber={2} />)}
          </Box>
          {
            ticket?.ticket_template ?
              <Box marginTop={2} style={{marginLeft: '-5px'}}>
                <ExpertDataEditor ticket={ticket}
                                  expertData={expertData}
                                  setExpertData={setExpertData}
                                  lang={t('current lang')}
                                  validation={validation}/>
              </Box>
              : null
          }
          {
            (ticket.state === "created" || ticket.state === "enqueued" || ticket.state === "registered")
            && (currentUser?.role?.code === "manager" || currentUser?.role?.code === "admin")
              ? <Box className="ticket-create-select-wrapper" marginTop={2} style={{marginLeft: '-5px'}}>
                <Select
                  collection={["high", "elevated", "normal"].map(v => ({ id: v, display_name: t(v) }))}
                  val={loyalty}
                  setVal={v => setLoyalty(v as TicketLoyalty)}
                  label={t("loyalty level")}
                />
              </Box>
              : null
          }
          <Box marginTop={2}>
            <Typography className="title-data-session">{t('Session data')}</Typography>
            <History history={ticket.history} />
          </Box>
        </Grid>
        <CommentComponent ticket={ticket} typePanel="detail" setComments={ comments1 => setComments(comments1)} />
      </Grid>
      <Box flexGrow={1} />
      <Box display="flex" flexDirection="row">
        <Link to="/admin/tickets" style={{height: 40}}>
          <Button
            style={{ margin: "10px", marginLeft: 0, marginBottom: 0 }}
            variant="outlined"
            color="secondary"
            className="default-button">
            {t('back')}
          </Button>
        </Link>
        <Box flexGrow={1} />
        {
          ticket.state === "assigned" || ticket.state === "started"
            ? <Button
              style={{ margin: "10px", marginLeft: 0, marginBottom: 0 }}
              variant="outlined"
              color="secondary"
              onClick={() => setDialogFreezeOpen(true)}
              className="default-button">
              {t('Freeze client')}
            </Button>
            : null
        }
        {
          ticket.state !== "cancelled" && currentUser?.role?.code !== "expert"
            ? <Button
              variant="outlined"
              className="alarm-button"
              onClick={onCancelClick}
              style={{ margin: "10px", marginBottom: 0 }}>
              {t('cancel ticket')}
            </Button>
            : null
        }
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "10px", marginBottom: 0, marginRight: 0, width: 156 }}
          qa-tag="ep-save-client-button"
          className="primary-button"
          onClick={onSaveClick}>
          {t('save')}</Button>
      </Box>
    </Paper>
    <InfoDetailDialog ticket={ticket} open={dialogInfoDetailOpen} setOpen={setDialogInfoDetailOpen} />
    <FreezeDialog ticketId={ticket.id} open={dialogFreezeOpen} setOpen={setDialogFreezeOpen} />
  </Container>
}
