import React, {useState, useRef, MutableRefObject} from "react"
import { connect, ConnectedProps} from "react-redux"
import { setClientDataAction, goNextAction } from "../type"
import {Typography, Box, TextField} from "@material-ui/core"
import { AppState } from "../../../store"
import PhoneInput, { MIN_PHONE_NUMBER } from "./phone"
import SButton from './button'
//@ts-ignore
import Keyboard from 'react-simple-keyboard';
import { useTranslation } from "react-i18next"
import KeyboardWrapper from "./keyboard_wrapper";

const mapStateToProps = ({ devicePage }: AppState) => {
  return {
    clientData: devicePage.clientData,
    device: devicePage.device,
    city: devicePage.city
  }
}

const mapDispatchToProps = {
  setClientDataAction,
  goNextAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromReduc = ConnectedProps<typeof connector>

const phoneUpdater = (phone: string) => phone.replace(/\D/g, "").slice(0, 15)

export default connector((props: PropsFromReduc) => {
  const [currentEditing, setCE] = useState<"phone" | "first_name" | "last_name">("phone")
  let phone: string = phoneUpdater(props.clientData?.phone || "")
  let firstName: string = props.clientData?.first_name || ""
  let lastName: string = props.clientData?.last_name || ""
  const phone_code = props.city?.phone_code || ""

  const changeFactory = (key: "phone" | "first_name" | "last_name") => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    props.setClientDataAction({ phone: phoneUpdater(phone), first_name: firstName, last_name: lastName, [key]: value })
  }
  const changeFactorySc = (key: "phone" | "first_name" | "last_name") => (val: string) => {
    if (key == "phone"){

      val = val.replace(/\D/g, "")
      val = val.substring(0, MIN_PHONE_NUMBER)

      if (val.charAt(0) != phone_code) {
        val = "+" + phone_code + " " + val
      }

      // @ts-ignore
      props.setClientDataAction({ phone: phoneUpdater(phone), first_name: firstName, last_name: lastName, [key]: phoneUpdater(val) })
    } else {
      props.setClientDataAction({ phone: phoneUpdater(phone), first_name: firstName, last_name: lastName, [key]: val })
    }
  }
  const isNoPlate = props.device?.kind === "terminal"
  const editorClickFactory = (key: "phone" | "first_name" | "last_name") => () => {
    if (isNoPlate) {
      setCE(key)
    }
  }

  const firstNameInput = useRef<any>();
  const secondNameInput = useRef<any>();

  const handleKeyUp = (e: any) => {
    e.which = e.which || e.keyCode;
    if (e.which == 13) {
      switch (e.target.id) {
        case "phone":
          firstNameInput.current.focus()
          break;
        case "first_name":
          secondNameInput.current.focus()
          break;
        case "last_name":
          if (!disable)
            props.goNextAction()
          else
            document.getElementById("phone")?.focus();
          break;

        default:
          break;
      }
    }
  }
  const changeFocus = (kindField: string) => {
    switch(kindField) {
      case "phone":
        firstNameInput.current.focus()
        break;
      case "first_name":
        secondNameInput.current.focus()
        break;
      case "last_name":
        if (!disable)
          props.goNextAction()
        else
          document.getElementById("phone")?.focus();
        break;

      default:
        break;

    }
  }

  // @ts-ignore
  const keyRef = useRef<MutableRefObject<Keyboard>>()
  const { t } = useTranslation()
  const disable = phone.trim().length < MIN_PHONE_NUMBER || phone.trim().length > 15 || firstName.length < 2 || lastName.length < 2
  return <>
    <Typography className="phone-header" variant="h2">{t('type phone')}</Typography>
    <PhoneInput
      id={"phone"}
      onKeyUp={handleKeyUp}
      focused={currentEditing === "phone"}
      phoneCode={phone_code}
      value={phone}
      onChange={changeFactory("phone")}
      onFocus={editorClickFactory("phone")}
    />
    <Typography className="tr-cl-caption" variant="h2">{t('type first name')}</Typography>
    <TextField
      id={"first_name"}
      inputRef={firstNameInput}
      onKeyUp={handleKeyUp}
      focused={currentEditing === "first_name"}
      className="tr-cl-field"
      onFocus={editorClickFactory("first_name")}
      onChange={changeFactory("first_name")}
      onClick={() => { firstNameInput.current.focus() }}
      value={firstName} />
    <Typography className="tr-cl-caption" variant="h2">{t('type second name')}</Typography>
    <TextField
      id={"last_name"}
      inputRef={secondNameInput}
      onKeyUp={handleKeyUp}
      focused={currentEditing === "last_name"}
      className="tr-cl-field"
      onFocus={editorClickFactory("last_name")}
      onChange={changeFactory("last_name")}
      onClick={() => { secondNameInput.current.focus() }}
      value={lastName} />
    <Box qa-tag='nubmTick' flexGrow={1} />
    <SButton
      variant={"filled"}
      size="normal"
      disable={disable}
      onClick={() => !disable ? props.goNextAction() : null}>
      <Box id={"submitInput"}>{t('next')}</Box>
    </SButton>
    {isNoPlate ?
      <Box className="screen-key-board-box">
        <KeyboardWrapper currentValue={props?.clientData?.[currentEditing] || ""} keyboardRef={keyRef} onChange={(value: string) => {
          let data = props?.clientData?.[currentEditing] || ""
          if (!value.match(/{\w+}/g)) {
            changeFactorySc(currentEditing)(data + value)
          } else if (value == "{space}") {
            changeFactorySc(currentEditing)(data + " ")
          } else if (value == "{bksp}") {
            changeFactorySc(currentEditing)(data.substring(0, data.length - 1))
          } else if (value == "{enter}") {
            changeFocus(currentEditing)
          }
        }} />
      </Box>
      :
      null
    }
  </>
})
