import {takeEvery, put, select, take, fork} from "redux-saga/effects"
import { ITicketChange } from "../ticket_change"
import { AppState } from "../../config/store"
import {Ticket, TicketLoyalty} from "./ticket"
import { buildActionTypeName } from "../../features/entity/store/actions"
import { fetchM } from "../../config/pages/shared/fetchM"
import { EntityId } from "../../features/entity/type"
import { LOGIN_ACTION_TYPE } from "../../config/pages/authentication/reducers/store"
import { ITicketChangeClientData } from "../ticket_change_client_data";
import {TClientData, TExpertData} from "./type";
import {successMessageAction, warningMessageAction} from "../../config/pages/shared/common_store";
import {validateClientData, validateExpertData} from "../../shared/validation/saga";
import commentSaga from "../comment/saga"
import {
  clearValidationFieldsAction,
  validationFieldsAction
} from "../../shared/validation/validation_store";
import i18next from "i18next";
import { getApiResponse } from "../../config/pages/authentication/reducers/sagas";

export const TICKETS_HISTORY_LOAD = "AD_TICKETS_HISTORY_LOAD"

const { SINGLE_LOAD_ACTION_TYPE, RELOAD_ACTION_TYPE } = buildActionTypeName(Ticket)

export const CANCEL_TICKET_ACTION = "TCK_CANCEL_TICKET_ACTION"

export const SAVE_TICKET_ACTION = "TCK_SAVE_TICKET_ACTION"

export const LOAD_YET_TICKETS = "LOAD_YET_TICKETS"

export const CHANGE_TICKET_PRIORITY = "CHANGE_TICKET_PRIORITY"

interface ICancelTicketAction{
  type: typeof CANCEL_TICKET_ACTION
  ticketId: EntityId
}

interface ISaveTicketAction{
  type: typeof SAVE_TICKET_ACTION
  ticket: Ticket,
  clientData: TClientData,
  expertData: TExpertData
}

interface ILoadYetTicketsAction{
  type: typeof LOAD_YET_TICKETS
  url: string
  showLoadYet: (isShow: boolean) => void
}

interface IChangeTicketPriority{
  type: typeof CHANGE_TICKET_PRIORITY
  ticketId: EntityId
  priority: TicketLoyalty
}

export function cancelTicketAction(ticketId: EntityId):ICancelTicketAction{
  return {
    type: CANCEL_TICKET_ACTION,
    ticketId
  }
}

export function saveTicketAction(ticket: Ticket,
                                 clientData: TClientData,
                                 expertData: TExpertData): ISaveTicketAction{
  return {
    type: SAVE_TICKET_ACTION,
    ticket,
    clientData,
    expertData
  }
}

export function loadYetTicketsAction(url: string, showLoadYet: (isShow: boolean) => void): ILoadYetTicketsAction{
  return {
    type: LOAD_YET_TICKETS,
    url,
    showLoadYet
  }
}

export function changeTicketPriorityAction(ticketId: EntityId, priority: TicketLoyalty) {
  return {
    type: CHANGE_TICKET_PRIORITY,
    ticketId: ticketId,
    priority: priority
  }
}

export function* loadHistory(action: any) {
  let userId: EntityId | null = yield select((st:AppState) => st.authentication.current_user_id)
  if (!userId)
    yield take(LOGIN_ACTION_TYPE)
  const changes: ITicketChange[] = action.response
  if (changes.length > 0) {
    const id = changes[0].ticket_id
    const changesClientData: ITicketChangeClientData[] = yield* fetchM(`tickets/${id}/client_data_changes`, {method: "GET"})
    const ticket = yield* fetchM('tickets/'+ id, { method: 'GET' })
    if (ticket) {
      ticket.history = changes
      ticket.history_client_data = changesClientData
      ticket.id = id
      yield put({
        type: SINGLE_LOAD_ACTION_TYPE,
        response: ticket
      })
    }
  }
}

function* cancelTicket({ticketId}: ICancelTicketAction){
  yield* fetchM(`tickets/${ticketId}/cancel`, { method: "PATCH" }, "do nothing")
  const changes: ITicketChange[] = yield* fetchM(`tickets/${ticketId}/changes`, { method: "GET" }, "do nothing")
  if (changes.length > 0) {
    const id = changes[0].ticket_id
    const changesClientData: ITicketChangeClientData[] = yield* fetchM(`tickets/${id}/client_data_changes`, {method: "GET"})
    const ticket = yield* fetchM('tickets/'+ id, { method: 'GET' })
    if (ticket) {
      ticket.history = changes
      ticket.history_client_data = changesClientData
      ticket.id = id
      yield put({
        type: SINGLE_LOAD_ACTION_TYPE,
        response: ticket
      })
    }
  }
}

function* saveTicket({ticket, clientData, expertData}: ISaveTicketAction){
  const errorsClientData = yield validateClientData(clientData)
  const errorsExpertData = yield validateExpertData(ticket, expertData)

  if (Object.keys(errorsClientData).map(key => errorsClientData[key]).includes(true)
    || errorsExpertData.map((value: any) => value.error).includes(true)) {
    yield put(validationFieldsAction(`tickets ${ticket.id}`, errorsClientData, errorsExpertData))
    yield put(warningMessageAction(i18next.t('error required fields')))
  } else {
    yield updateData({ticket, clientData, expertData} as ISaveTicketAction)
    yield put(clearValidationFieldsAction())
  }
}

function* updateData({ticket, clientData, expertData}: ISaveTicketAction) {
  const options = {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      client_data: clientData,
      expert_data: expertData
    })}
  const response = yield* fetchM(`tickets/${ticket.id}`, options, "do nothing")
  if (response.success) {
    yield put(successMessageAction(i18next.t('changes saved successfully')))
  }
}

export const getTickets = ({ tickets }: AppState) => Object.keys(tickets.entities).map(k => tickets.entities[k] as Ticket)

function* loadYetTickets({url, showLoadYet}: ILoadYetTicketsAction) {
  const newTickets: Ticket[] = yield* fetchM(url, { method: 'GET' })
  if (newTickets.length > 0) {
    const tickets = yield select(getTickets)
    tickets.push(...newTickets)
    yield put({
      type: RELOAD_ACTION_TYPE,
      response: tickets
    })
  } else {
    showLoadYet(false)
  }
}

export function* getCurrentTicket() {
  return yield getApiResponse('/tickets/current', 'GET')
}

export function* getTicketsForLine(lineId: string, status: string, view: string) {
  return yield getApiResponse(`/tickets?line_id=${lineId}&state=${status}&view=${view}`, 'GET')
}

export function* startTicket(ticketId: string) {
  return yield getApiResponse(`/tickets/${ticketId}/start`, 'PATCH')
}

function* changeTicketPriority({ticketId, priority}: IChangeTicketPriority) {
  const options = {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      priority: priority
    })}
  const response = yield* fetchM(`tickets/${ticketId}/change_priority`, options, "do nothing")
  if (response.success) {
    yield put(successMessageAction(i18next.t('changes saved successfully')))
  }
}

export default function* () {
  yield takeEvery(TICKETS_HISTORY_LOAD, loadHistory)
  yield takeEvery(CANCEL_TICKET_ACTION, cancelTicket)
  yield takeEvery(SAVE_TICKET_ACTION, saveTicket)
  yield takeEvery(LOAD_YET_TICKETS, loadYetTickets)
  yield takeEvery(CHANGE_TICKET_PRIORITY, changeTicketPriority)
  yield fork(commentSaga)
}
