import React, { ForwardRefExoticComponent, RefAttributes } from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'

import { List, makeStyles, Typography, Box } from '@material-ui/core'
import MiniDrawer from "../../../shared/components/mini_drawer"
import AdminTabs from './tabs'

import { ListCityContanier, RemoteSyncCityContainer } from '../../../models/city'
import { RemoteSyncHubContainer } from '../../../models/hub'
import { RemoteSyncLineContainer, ListLineContanier } from '../../../models/line'
import { RemoteSyncDeskContainer, ListDeskContanier } from '../../../models/desk'
import { ListTicketTemplateContanier, RemoteSyncTicketTemplateContainer } from '../../../models/ticket_template'
import { ListFieldContanier, RemoteSyncFieldContainer } from '../../../models/field'
import { RemoteSyncUserContainer, ListUserContanier } from '../../../models/user'
import { RemoteSyncRoleContainer } from '../../../models/role'
import { ListTicketContanier, TicketEditPage } from '../../../models/ticket'
import { MainMenu } from '../shared/main_menu'
import HubPage from '../../../models/hub/page'
import { ListTicTagContanier, RemoteSyncTicTagContainer } from '../../../models/tic_tag'
import { RemoteSyncSMSContainer, ListSMSContanier } from '../../../models/sms'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({

  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,

  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
  }
}))

interface IProps {
  currentRole: string
}

export type Handle<T> = T extends ForwardRefExoticComponent<RefAttributes<infer T2>> ? T2 : never;

export default ({ currentRole }: IProps) => {
  let match = useRouteMatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const menu = <List>
    <MainMenu />
  </List>
  const tabs = <AdminTabs subPath={match.path} />

  let redirect = <Redirect to={match.path + "/desks"} />
  switch(currentRole) {
    case "admin":
      redirect = <Redirect to={match.path + "/towns"} />
      break
    case "manager":
      redirect = <Redirect to={match.path + "/hubs"} />
      break
  }

  return <MiniDrawer
    headerTitle="Admin page"
    menu={menu}>
    <main className={classes.content + " info-window"}>
      <Box className="title-page-margin" >
        <Typography variant="h5" className="title-page" qa-tag="settings-header">{t("settings")}</Typography>
      </Box>

      <RemoteSyncCityContainer />
      <RemoteSyncHubContainer />
      <RemoteSyncLineContainer />
      <RemoteSyncDeskContainer />
      <RemoteSyncTicketTemplateContainer />
      <RemoteSyncFieldContainer />
      <RemoteSyncUserContainer />
      <RemoteSyncRoleContainer />
      <RemoteSyncTicTagContainer />
      {
        currentRole === "admin"
          ? <RemoteSyncSMSContainer />
          : null
      }
      <Switch>
        <Route path={`${match.path}/towns`}>
          <ListCityContanier tabs={tabs} editable={currentRole === "admin"} />
        </Route>
        <Route path={`${match.path}/hubs`}>
          <HubPage tabs={tabs} editable={currentRole === "admin"} />
        </Route>

        <Route path={`${match.path}/lines/:line_id/tickets`}
          render={({ match }) => {
            return <ListTicketContanier tabs={tabs} line_id={match.params.line_id} />
          }}
        />
        <Route path={`${match.path}/lines`}>
          <ListLineContanier tabs={tabs} editable={currentRole === "admin"} />
        </Route>

        <Route path={`${match.path}/desks`}>
          <ListDeskContanier tabs={tabs} editable={currentRole === "admin"} />
        </Route>

        <Route path={`${match.path}/users`}>
          <ListUserContanier tabs={tabs} editable={currentRole === "admin"} />
        </Route>
        <Route path={`${match.path}/templates/:template_id`}
          render={({ match }) => {
            return <ListFieldContanier tabs={tabs} ticket_template_id={match.params.template_id} />
          }}
        />
        <Route path={`${match.path}/templates`}>
          <ListTicketTemplateContanier tabs={tabs} />
        </Route>

        <Route path={`${match.path}/sms`}>
          <ListSMSContanier tabs={tabs}  editable={currentRole === "admin"}/>
        </Route>

        <Route path={`${match.path}/tic_tags`}>
          <ListTicTagContanier tabs={tabs} editable={currentRole === "admin" || currentRole === "expert"} />
        </Route>

        <Route path={`${match.path}/tickets/:ticket_id`}
          render={({ match }) => {
            return <TicketEditPage ticket_id={match.params.ticket_id} />
          }}
        />
        <Route path={`${match.path}/tickets`}
          render={() => {
            return <ListTicketContanier tabs={tabs} />
          }}
        />

        <Route path={match.path}>
          {redirect}
        </Route>
      </Switch>


    </main>
  </MiniDrawer>
}
