import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../store'
import { closeHelpRequestDialogAction } from './common_store'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button, createStyles, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton, makeStyles, Slide, Theme,
  Typography
} from '@material-ui/core'
import {TransitionProps} from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 370
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      padding: 20
    },
    divider: {
      marginRight: 20,
      marginLeft: 20
    },
    content: {
      marginTop: 4,
      padding: 20
    },
    action: {
      padding: 20
    }
  }))

export default function () {
  const classes = useStyles()

  const open = useSelector((st: AppState) => !!st.common.helpRequestDialog)

  const data = useSelector((st: AppState) => st.common.helpRequestDialog)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleClose = () => dispatch(closeHelpRequestDialogAction())

  return <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    classes={{ paper: classes.paper }}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
      {t('need help')}
      <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <Divider className={classes.divider} />
    <DialogContent className={classes.content}>
      <Box qa-tag="alarm-text">
        <Typography variant="h5" style={{ fontSize: 14, fontWeight: 'normal' }}>
          { `${data?.user?.name} ${t('help request')}:` }
        </Typography>
        <Typography variant="h5" style={{ fontSize: 14, fontWeight: 'normal', marginTop: 14 }}>
          { data?.desk?.display_name }
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions className={classes.action}>
      <Button
        variant="contained"
        color="primary"
        style={{ width: 156 }}
        qa-tag="accept-help-button"
        className="primary-button"
        onClick={handleClose}>
        {t('apply')}</Button>
    </DialogActions>
  </Dialog>

}
