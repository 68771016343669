import React, {useEffect, useState} from 'react'
import { Button, Typography, RadioGroup, Radio, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import { AppState } from '../../../store'

import { EntityId } from '../../../../features/entity/type'
import { Modal } from '../../../../shared/components/modal'
import {getLinesForForwardAction} from "../saga";

type TProps = {
  onForwardTo: (lineId: EntityId) => void,
  ticketId?: EntityId,
  open: boolean,
  setOpen: (v: boolean) => void
}

export default ({ onForwardTo, ticketId, open, setOpen }: TProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lines = useSelector(({ linesAdditional }: AppState) => {
     return linesAdditional.forwardLines
  })

  const [selectedLineId, setSelectedLineId] = useState<EntityId | null>(null)

  useEffect(() => {
    if (ticketId)
      dispatch(getLinesForForwardAction(ticketId))
  }, [lines.length, open])

  return <Modal
    title={t('Please, select the line')}
    titleAdditional={""}
    open={open}
    setOpen={setOpen}
    className="forward-dialog"
    actions={<Button
      autoFocus
      onClick={(e) => {
        e.preventDefault()
        onForwardTo(selectedLineId!)
      }}
      variant="contained"
      disabled={!selectedLineId}
      size="large"
      style={{ margin: 10 }}
      color="primary"
      qa-tag="confirm-forward"
      className="primary-button">
      {t('Forward to other queue')}
    </Button>}>
    <>
      {ticketId ?
        <>
          <RadioGroup aria-label="gender" name="gender1"
                      value={selectedLineId}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedLineId((event.target as HTMLInputElement).value)}>

            {lines.map((line, index) => (
              <Box key={index} display="flex" flexDirection="row">
                <Box flexGrow={1} display="flex" flexDirection="row" alignItems="center">
                  <Typography qa-tag="line-for-forward" variant="body1" style={{ fontSize: 14, fontWeight: "normal" }}>
                    {line.display_name}
                  </Typography>
                </Box>
                <Radio qa-tag="radio-for-forward" value={line.id} color="primary" />
              </Box>
            ))}
          </RadioGroup>
        </>
      : null}
    </>
  </Modal>
}