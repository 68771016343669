import React from 'react'
import { TicketState } from '../../../../models/ticket/ticket'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'

export type TState = "working" | "waiting" | TicketState


const colorMap = {
  working: "#5D7ED4",
  waiting: "#FD8C4D",
  created: "#A955B8",
  assigned: "#77B6E7",
  enqueued: "#FD8C4D",
  started: "#6663C3",
  finished: "#54BFB7",
  cancelled: "#FC6A55",
  hold: "#AAADB7",
  registered: "#97CC64"
}

export default ({s}: {s: TState}) => {
  const { t } = useTranslation()
  console.log(s)
  return <Box 
    style={{backgroundColor: colorMap[s]}}
    color="#fff"
    height={26}
    display="inline-flex"
    flexDirection="column"
    fontSize={12}
    padding="2px 18px"
    borderRadius={13}
    width="auto"
    justifyContent="center"
    alignItems="center">
    {t(s)}
  </Box>
}