import React, {useState} from "react"
import {StaticDatePicker} from "@material-ui/pickers"
import {
  Box,
  ClickAwayListener,
  Fade,
  FormControl,
  Popper,
  TextField,
  Typography
} from "@material-ui/core";
import DateFns from "@date-io/date-fns";
import {enGB, ru} from 'date-fns/esm/locale'
import { LocalizationProvider } from '@material-ui/pickers';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Field} from "../../field";
import {TExpertData} from "../type";

type Props = {
  field: Field,
  expertData: TExpertData,
  isValid: boolean,
  setVal: (date: Date) => void
}

export default ({setVal, field, expertData, isValid}: Props) => {

  const {t} = useTranslation()

  const lang = t('current lang')

  let locale = ru
  if (lang == "en") {
    locale = enGB
  }

  const [open, setOpen] = useState(false)
  const [anchor, setAnchor] = useState()

  return <>
    <FormControl style={{margin: '5px', marginTop: '4px'}}>
      <Typography className="date-label-expert">
        {field.display_name + (field.required ? "*" : "")}
      </Typography>
      <TextField required={field.required}
                 className={"date-range-text-field " + (isValid ? "" : "ep-failed-validate-field")}
                 placeholder={"dd/mm/yyyy"}
                 onClick={(e) => {
                   setAnchor(e.currentTarget)
                   setOpen(!open)
                 }}
                 value={expertData[field.id] ? moment(expertData[field.id] as string, "YYYY-M-D").format("DD/MM/YYYY") : ""} />
    </FormControl>
    <Popper open={open}
                   anchorEl={anchor}
                   placement={"bottom-start"}
                   transition
                   className="date-single-poper date-popper date-popper-expert"
                    >
      {({TransitionProps}) => (
        <ClickAwayListener onClickAway={() => {setOpen(false)}}>
          <Fade {...TransitionProps}>
            <Box>
              <LocalizationProvider dateAdapter={DateFns} locale={locale}>
                <StaticDatePicker
                  showToolbar={false}
                  label={field.display_name}
                  value={expertData[field.id] ? moment(expertData[field.id] as string, "YYYY-M-D") : null}
                  onChange={(date) => {
                    setOpen(false)
                    // @ts-ignore
                    setVal(date || new Date())}
                  }
                  renderInput={() => <></> }
                  inputFormat="dd/mm/yyyy"
                  qa-tag={"ep-ed-field-"+field.kind}
                />
              </LocalizationProvider>
            </Box>
          </Fade>
        </ClickAwayListener>
      )}
      </Popper>
    </>
}