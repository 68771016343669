import { BaseAttribute, BaseEntity } from "../../features/entity"
import { EntityId, TMappedState } from "../../features/entity/type"
import { TicketTemplate } from "../ticket_template"
import { BelongsToAttribute } from "../../features/entity/attributes/belongs_to_attribute"
import { TExpertData } from "./type"
import { ITicketChange } from "../ticket_change"
import {User} from "../user";
import {ITicketChangeClientData} from "../ticket_change_client_data";
import {Comment} from "../comment";

const ticketTemplateAttr = new BelongsToAttribute({
  key: "ticket_template_id",
  parentClassStorageEntityKey: "ticket_templates",
  displayAttribute: "display_name"
})
const hubAttr = new BelongsToAttribute({
  key: "hub_id",
  parentClassStorageEntityKey: "hubs",
  displayAttribute: "display_name"
})
const lineAttr = new BelongsToAttribute({
  key: "line_id",
  parentClassStorageEntityKey: "lines",
  displayAttribute: "display_name"
})
const tagAttr = new BelongsToAttribute({
  key: "tag_id",
  parentClassStorageEntityKey: "tags",
  displayAttribute: "display_name"
})

const attributes = [
  new BaseAttribute('ticket_no'), 
  new BaseAttribute('state'),
  new BaseAttribute('client_data'),
  new BaseAttribute('expert_data'),
  ticketTemplateAttr,
  hubAttr,
  lineAttr,
  tagAttr,
  new BaseAttribute('created_at'),
  new BaseAttribute('phone_code'),
  new BaseAttribute('priority')
]

export type TicketState = "created" | "assigned" | "enqueued" | "registered" |
  "started" | "finished" | "cancelled" | "hold"

export type TicketLoyalty = "" | "high" | "elevated" | "normal"

export enum TicketKindSort {
  TICKET_NO = "ticket_no",
  CLIENT = "client",
  PHONE_NUMBER = "phone_number",
  STATE = "state",
  LINE = "line",
  HUB = "hub",
  TAG = "tag",
  CREATED_AT = "created_at"
}

export class Ticket extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Ticket"
  state: TicketState
  ticket_no: string
  client_data: any = {}
  expert_data: TExpertData = {}
  expert?: User
  hub_id: string = "-1"
  line_id: string = "-1"
  tag_id: string = "-1"
  ticket_template_id: string = ""
  ticket_template?: TicketTemplate 
  history: ITicketChange[] = []
  history_client_data: ITicketChangeClientData[] = []
  created_at: string = ""
  phone_code: string = ""
  priority: TicketLoyalty = ""
  comments: Comment[] = []
  constructor(state: string = "", ticket_no: string = "", id: EntityId = "-1", created_at: string = "") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.state = state as TicketState
    this.ticket_no = ticket_no
    this.created_at = created_at
  }
  public static buildFromRaw(raw: any, _:any): any{
    let res = BaseEntity.buildFromRaw<Ticket>(raw, Ticket)

    if('ticket_template' in raw && raw.ticket_template){
      res.ticket_template = TicketTemplate.buildFromRaw<TicketTemplate>(raw.ticket_template, TicketTemplate)
    }

    if('expert_data' in raw){
      res.expert_data = raw.expert_data
    }

    if('history' in raw){
      res.history = raw.history
    }

    if('history_client_data' in raw){
      res.history_client_data = raw.history_client_data
    }

    if('expert' in raw && raw.expert != null){
      res.expert = User.buildFromRaw(raw.expert, User)
    }

    if ('comments' in raw) {
      raw?.comments?.map((comment: any) => res.comments.push(Comment.buildFromRaw(comment, Comment)))
    }

    return res
  }
  public static getHubColumn(){
    return hubAttr
  }
  public static getLineColumn(){
    return lineAttr
  }
  public static getTagColumn(){
    return tagAttr
  }
  getLineName(state: TMappedState){
    return lineAttr.getDisplayValue(this, state)
  }
}