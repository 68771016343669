import React, {forwardRef, useRef} from 'react'
import MaterialTable, {Icons} from 'material-table'
import { BaseEntity, BaseAttribute } from '..'
import { TMappedState } from '../type'
import { Container, Box, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CPagination from './pagination'

import {getArrowTable, getCancelTable, getDoneTable, getEditTable, getTrashTable} from "../../../assets/icons";

export interface Props<TEntity extends BaseEntity> {
  onDelete(entity: TEntity): void
  onUpdate(entity: TEntity): void
  onCreate(entity: TEntity): void
  attributes: BaseAttribute[]
  title: string
  tabs?: React.ReactElement
}

type TProps<TEntity extends BaseEntity> = Props<TEntity> & TMappedState

type TEditable = {
  isEditable?: ((rowData: BaseEntity) => boolean) | undefined
  isDeletable?: ((rowData: BaseEntity) => boolean) | undefined
  onRowAdd?: ((newData: BaseEntity) => Promise<any>) | undefined
  onRowUpdate?: ((newData: BaseEntity, oldData?: BaseEntity | undefined) => Promise<any>) | undefined
  onRowDelete?: ((oldData: BaseEntity) => Promise<any>) | undefined
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export default <TEntity extends BaseEntity>(props: TProps<TEntity>) => {
  const refTable = useRef<MaterialTable<BaseEntity>>(null)
  const { t } = useTranslation()
  const changeS = (query: string) => {
    //@ts-ignore
    refTable.current!.dataManager.changeSearchText(query)
    //@ts-ignore
    refTable.current!.onSearchChangeDebounce(query)
  }
  let columns = props.attributes.map(attr => attr.buildColumn(props.additionals)).filter(notEmpty)
  columns = columns.map(col => {
    let title = typeof col.title === "string" ? t(col.title) : col.title
    return { ...col, title }
  })

  const onAddClick = () => {
    const table: any = refTable.current!
    table.dataManager.changeRowEditing()
    table.setState({
      ...table.dataManager.getRenderState(),
      showAddRow: !table.state.showAddRow,
    })
  }
  const editable: TEditable = {
    isEditable: () => props.editable,
    isDeletable: () => props.editable,
    onRowAdd: (newData) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve()
          props.onCreate(newData as TEntity)
        }, 600)
      }),
    onRowUpdate: (newData) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve()
          props.onUpdate(newData as TEntity)
        }, 600)
      }),
    onRowDelete: (oldData) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve()
          props.onDelete(oldData as TEntity)
        }, 600)
      }),
  }
  const isEditable = props.editable

  const trash = getTrashTable()
  const done = getDoneTable()
  const cancel = getCancelTable()
  const edit = getEditTable()

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref)),
    Check: forwardRef(() => done),
    Clear: forwardRef(() => cancel),
    Delete: forwardRef(() => trash),
    Edit: forwardRef(() => edit)
  }

  return <>
    <Box display="flex" flexDirection="row">
      <Box style={{marginTop: 15}}>
        {props.tabs ? props.tabs : null}
      </Box>
      <Box flexGrow={1} />
      <Box>
        <TextField
          style={{marginTop: 6}}
          onChange={(e) => changeS(e.target.value)}
          placeholder={t("search")}
          className="mtable search-field" />
      </Box>
    </Box>
    <Container maxWidth="lg" className="mtable container-table">
      <MaterialTable
        icons={tableIcons}
        tableRef={refTable}
        title={props.title}
        columns={columns}
        data={props.filtered}
        options={{
          pageSize: 50,
          emptyRowsWhenPaging: false,
          draggable: true,
          toolbar: false,
          searchFieldAlignment: "left",
          actionsColumnIndex: -1
        }}
        components={{
          Pagination: (props) => <CPagination {...props} onAddClick={isEditable ? onAddClick : undefined} />
        }}
        localization={{ header: { actions: "" } }}
        editable={props.editable ? editable : undefined}
      />
    </Container>
  </>
}