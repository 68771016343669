import React from 'react'
import { BaseEntity, BaseAttribute } from '../../features/entity'
import { EntityId } from '../../features/entity/type'
import {User} from "../user";

const attributes = [
  new BaseAttribute('message'),
  new BaseAttribute('created_at'),
  new BaseAttribute('user_id'),
  new BaseAttribute('ticket_id'),
  new BaseAttribute('ticket_change_id')
]

export class Comment extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Comment"
  message: string = ""
  created_at?: Date
  user_id: EntityId = "-1"
  ticket_id: EntityId = "-1"
  ticket_change_id: EntityId = "-1"
  user?: User
  constructor(id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
  }

  public static buildFromRaw(raw: any, _: any): any {
    let res = BaseEntity.buildFromRaw<Comment>(raw, Comment)

    if ('user' in raw) {
      res.user = User.buildFromRaw(raw.user, User)
    }

    return res
  }
}