import { IEntityBase, TEntityClass } from '../type'
import pluralize from 'pluralize'

export function toUnderScore(target:string):string {
  return target.replace(/(?:^|\.?)([A-Z])/g, (_e, y) => "_" + y.toLowerCase()).replace(/^_/, "")
}

export function getStorageEntityKey<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>>(t: TEClass): string {
  const res = toUnderScore(pluralize(t.entity_name))
  return res.toLowerCase()
}
