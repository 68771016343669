import React from 'react'
import { inputAuthCodeAction } from '../type'
import { AppState } from '../../../store'
import { ConnectedProps, connect } from 'react-redux'
import { Loading } from '../loading'
import { TextField, Button } from '@material-ui/core'

const mapState = ({ devicePage }: AppState) => {
  return {
    devicePage
  }
}

const mapDispatch = {
  inputAuthCodeAction
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector((props: PropsFromRedux) => {
  let authCode = ""
  switch (props.devicePage.page) {
    case "AUTH_CODE":
      return <>
        {props.devicePage.errorMessag}
        <br /><br />
        input device code: <br />
        <TextField variant={"outlined"} onChange={(e) => authCode = e.target.value} />
        <br />
        <Button onClick={() => props.inputAuthCodeAction(authCode)}> Submit </Button>
      </>
  }
  return <Loading />
})