import { put, take, call, race, takeEvery, select, delay } from "redux-saga/effects"
import { changePageAction } from "../../store"
import {
  USER_SELECT_MODE,
  IUserSelectModeAction,
  setErrorAction,
  setTimeSlotAction,
  SET_DAY_ACTION,
  IClientData,
  GO_BACK,
  setClientDataAction,
  GO_NEXT,
  setTicketAction,
  setLineIdAction, setLinesAction, TERMINAL_SELECT_QUEUE, TERMINAL_CLIENT_DATA_PAGE
} from "../../type"
import * as API from "../../api"
import { AppState } from "../../../../store"
import { Ticket } from "../../../../../models/ticket"
import { preEntries } from "./pre_entries"
import {Line} from "../../../../../models/line";
import {EntityId} from "../../../../../features/entity/type";

export default function* () {
  yield takeEvery(SET_DAY_ACTION, clearSelectedSlot)
  let mode = ''
  while (true) {
    const page = yield select(getPage)

    switch (page) {
      case TERMINAL_SELECT_QUEUE:
        mode = yield initPage()
        break;

      case TERMINAL_CLIENT_DATA_PAGE:
        const lines = yield select(getLinesStore)
        if (lines.length > 1) {
          if (mode === "PRE-ENTRIES")
            yield preEntries()
          else
            yield withoutPreEntries()
        } else {
          mode = yield initPage()
        }
        break;

      default:
        mode = yield initPage()
        break;
    }
  }
}

function* initPage() {
  yield put(changePageAction("TERMINAL_MAIN"))
  const { mode }: IUserSelectModeAction = yield take(USER_SELECT_MODE)
  switch (mode) {

    case "PRE-ENTRIES":
      yield* preEntries()
      break

    case "WITHOUT-PRE-ENTRIES":
      yield* withoutPreEntries()
      break
  }

  return mode
}

function* withoutPreEntries() {
  yield goTerminalSelectLine()
}

function* goTerminalSelectLine() {
  yield put(setLineIdAction())
  const lines = yield getLines()

  if (lines.length > 1) {
    yield put(changePageAction("TERMINAL_SELECT_QUEUE"))
    const userChoose = yield race({ nexta: take(GO_NEXT), back: take(GO_BACK) })
    if (userChoose.back)
      return false

  } else if (lines.length === 1) {
    const line = lines[0]
    yield put(setLineIdAction(line.id))
  }

  yield goTerminalClientData()
}

function* goTerminalClientData() {
  yield put(setClientDataAction())
  yield put(setTicketAction())
  yield put(changePageAction("TERMINAL_CLIENT_DATA"))
  const userChoose = yield race({ nexta: take(GO_NEXT), back: take(GO_BACK) })
  if (userChoose.back)
    return false

  yield goTerminalLoyalty()
}

function* goTerminalLoyalty() {
  yield put(changePageAction("TERMINAL_LOYALTY_PAGE"))
  const userChoose = yield race({ nexta: take(GO_NEXT), back: take(GO_BACK) })
  if (userChoose.back)
    return

  yield put(changePageAction("LOADING"))
  const clientData: IClientData = yield select(getClientData)
  const lineId: EntityId = yield select(getLineId)
  const loyalty = yield select(getLoyalty)

  const res: API.IAPIResponse = yield call(API.withoutPreEntry, clientData, lineId, loyalty)

  if (res.success) {
    yield put(setErrorAction())
    const ticket = Ticket.buildFromRaw((res as any).model, Ticket)
    yield put(setTicketAction(ticket))
    yield* printTicket(ticket)
  }
  else {
    yield put(setErrorAction(res.error || "unknown error"))
  }
}

function* printTicket(ticket: Ticket) {
  yield put(changePageAction("TERMINAL_TICKET_PRINT"))
  try {
    setTimeout(() => fetch("http://localhost:5000/print", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "ticket_no": ticket.ticket_no,
        "date": new Date()
      })
    }), 10)
  } catch (e) {

  }
  yield delay(10000)
}

export function* getLines() {
    const result = yield API.getLines()
    const lines: Line[] = result.map((raw: any) => (
      Line.buildFromRaw(raw, Line)
    ))

    yield put(setLinesAction(lines))

    return lines
}

function* clearSelectedSlot() {
  yield put(setTimeSlotAction())
}

export function getPage({ devicePage }: AppState) {
  return devicePage.page
}

export function getLineId({ devicePage }: AppState) {
  return devicePage.lineId
}

export function getLoyalty({ devicePage }: AppState) {
  return devicePage.loyalty
}

export function getLinesStore({ devicePage }: AppState) {
  return devicePage.lines
}

export function getClientData({ devicePage }: AppState) {
  return devicePage.clientData
}

export function getSelectedTimeSlotId({ devicePage }: AppState) {
  return devicePage.timeSlotId
}

export function getSelectedDate({ devicePage }: AppState) {
  return devicePage.selected_day
}
