import './type'
import {call, fork, put, takeEvery} from 'redux-saga/effects'
import {setRoleAction} from '../../../features/policy/actions'
import {FETCH_ACTION} from '../../../features/entity/type'
import {authedFetch, getApiDeviceResponse} from '../authentication/reducers/sagas'
import {activatorSaga} from './auth'
import {AuthResult, setDeviceTypeAction} from './type'
import {TerminalSaga} from './terminal'
import {screenSaga} from './tv'
import {pushTicket, TypeEssence} from "../../../features/centrifugo_saga"
import {IDecodedToken} from "../authentication/reducers/store";
import jwt from "jsonwebtoken";

export function* setTokenDiviceCentrifugo(){
  const res = yield call(getApiDeviceResponse, '/tokens/centrifugo', 'GET')
  window.localStorage["cenToken"] = res.token

  const decode: IDecodedToken = yield call(jwt.decode, res.token)
  window.localStorage["cenTokenExpires"] = decode.exp * 1000
}

export default function* () {
  yield put(setRoleAction("device"))

  yield takeEvery(FETCH_ACTION, authedFetch)
  const { deviceType, deviceId }: AuthResult = yield call(activatorSaga)
  yield fork(pushTicket, deviceId, TypeEssence.DEVICE)
  yield put(setDeviceTypeAction(deviceType))

  switch (deviceType) {
    case "TERMINAL":
      yield call(TerminalSaga)
      break

    case "TV":
      yield call(screenSaga)
      break
  }
}