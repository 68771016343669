import React from 'react'
import { TextField } from '@material-ui/core'


type TEvent = React.ChangeEvent<{ value: unknown }>

interface IProps {
  value: string
  onChange: (event: TEvent) => void
  attr_key: string
}


export default (props:IProps) => {
  return  <TextField fullWidth className={`cy_${props.attr_key}`} label={props.attr_key} variant="outlined"
  onChange={props.onChange} value={props.value} />
}
