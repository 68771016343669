import React, {useState} from 'react'
import walkIcon from '../../../../assets/walk.svg'
import { useDispatch, useSelector } from 'react-redux'
import { missClientAction, recallAction, clientIncomingAction } from '../store'
import Template from './step_template'
import {Box, Button, MenuItem, Typography} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AppState } from '../../../store'
import Popover from './popover'
import Header from '../../../../models/ticket/components/ticket_header'

export default () => {
  const dispatch = useDispatch()
  const ticket = useSelector((state: AppState) => state.expertPage.currentTicket)
  const [blockSecond, setBlockSecond] = useState(0)

  const missClientClick = () => dispatch(missClientAction())
  const recallClick = () => {
    let time = 4
    let timerId = setInterval(() => setBlockSecond(time--), 1000);
    setTimeout(() => {
      clearInterval(timerId)
      setBlockSecond(0)
    }, 5000)
    dispatch(recallAction())
  }
  const clientIncomingClick = () => dispatch(clientIncomingAction())

  const { t } = useTranslation()
  const bottom = <>
    {
      blockSecond !== 0
        ? <Typography style={{marginRight: 10}}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              {t('repeat call')} {blockSecond}
            </Box>
          </Typography>
        : null
    }
    <Popover>
      <>
        <MenuItem onClick={missClientClick} qa-tag="ep-no-client-button">{t('No client')}</MenuItem>
        <MenuItem onClick={recallClick} disabled={blockSecond !== 0} qa-tag="ep-recall-button">{t('Recall')}</MenuItem>
      </>
    </Popover>
    <Button
      variant="contained"
      color="primary"
      style={{ marginLeft: 20 }}
      className="primary-button"
      qa-tag="ep-client-incoming-button"
      onClick={clientIncomingClick}>
      {t('Client incoming')}
    </Button>
  </>
  return <Template
    header={<Header status={t('Wait for client')} ticket={ticket}/>}
    bottom={bottom}
    stretcher={<img src={walkIcon} qa-tag="wait-client-icon" alt="wait worksession" />}
  />
}