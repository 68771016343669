import React from 'react'
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MiniDrawer from '../../../shared/components/mini_drawer'
import { Box, List } from '@material-ui/core'
import { MainMenu } from '../shared/main_menu'
import FirstPage from './first_page'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import HistoryByExperts from './pages/history_by_experts'
import HistoryByHubs from './pages/history_by_hubs'
import WSByHub from './pages/worksession_by_hubs'
import WSByExperts from './pages/worksession_by_expert'
import ExpertLoad from './pages/expert_load'
import TicketsLoad from './pages/tickets'
import TicketsCreatedByExpert from './pages/tickets_created_for_users'
import { useTranslation } from 'react-i18next'
import {linkColor, textColor} from "../../../styles/variables";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    title: {
      margin: "28px 0 18px 33px",
      fontSize: 24,
      fontWeight: 500,
      color: textColor,
      lineHeight: "28px"
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      color: textColor,
      lineHeight: "11px"
    },
    links: {
      width: "260px",
      height: "19px",
      fontSize: 14,
      color: linkColor
    },
    accordion: {
      marginBottom: 15
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto'
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    appBarSpacer: theme.mixins.toolbar,

    paper: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(4),
    }
  })
)

export default () => {
  const classes = useStyles()
  const { t } = useTranslation()
  let match = useRouteMatch()
  const menu = <List>
    <MainMenu />
  </List>
  return (<MiniDrawer
    headerTitle="Admin page"
    menu={menu}>
    <main className={classes.content + " info-window"}>
      <Box className="title-page-margin">
        <Typography variant="h5" className="title-page" style={{marginBottom: "30px"}}>{t("statistics")}</Typography>
      </Box>
      <Switch>
        <Route path={`${match.path}/hubs/history`}>
          <HistoryByHubs />
        </Route>
        <Route path={`${match.path}/experts/history`}>
          <HistoryByExperts />
        </Route>
        <Route path={`${match.path}/experts/desktop`}>
          <WSByExperts />
        </Route>
        <Route path={`${match.path}/hubs/desktop`}>
          <WSByHub />
        </Route>
        <Route path={`${match.path}/experts/report`}>
          <ExpertLoad />
        </Route>
        <Route path={`${match.path}/tickets`}>
          <TicketsLoad />
        </Route>
        <Route path={`${match.path}/experts/tickets_created_by_expert`}>
          <TicketsCreatedByExpert />
        </Route>
        <Route path={match.path}>
          <FirstPage />
        </Route>
      </Switch>
    </main>
  </MiniDrawer>
  )
}