import { IPolicyRouterState, PolicyRouterActions, SET_URL_PATH, SET_ROLE_ACTION } from "./type"

const initState: IPolicyRouterState = {
  currentPath: undefined,
  redirectFrom: undefined,
  currentRole: undefined
}

export default (state = initState, action: PolicyRouterActions): IPolicyRouterState => {
  switch (action.type) {
    case SET_URL_PATH:
      if(action.routeProps.path !== state.currentPath)
        return {
          ...state,
          currentPath: action.routeProps.path as string
        }
      else
        return state

    // case REDIRECT_ACTION:
    //   return {
    //     ...state,
    //     redirectFrom: action.redirectFrom,
    //     last_redirection: action.redirectTo
    //   }

    case SET_ROLE_ACTION:
      if (!!action.role)
        return {
          ...state,
          currentRole: action.role
        }
      else{
        const res = {...state}
        res.currentRole = undefined
        return res
      }

    default:
      return state
  }
}