import React from 'react'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import arrow from './arrow.svg'
import { useTranslation } from 'react-i18next'
import { linkColor } from "../../../styles/variables";

const defaultProps = {
  showFirstLastPageButtons: true,
  localization: {
    firstTooltip: "First Page",
    previousTooltip: "Previous Page",
    nextTooltip: "Next Page",
    lastTooltip: "Last Page",
    labelDisplayedRows: "{from}-{to} of {count}",
    labelRowsPerPage: "Rows per page:",
  },
}


const useStyles = makeStyles(() => ({
  buttons: {
    height: 40,
    padding: "17px 17px"
  },
  midButtton: {
    borderTop: "1px solid rgba(0, 0, 0, 0.23);",
    borderRadius: 0,
    borderBottom: "1px solid rgba(0, 0, 0, 0.23);",
    borderLeft: "none",
    borderRight: "none",
    minWidth:36
  },
  leftEdgB: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth:36
  },
  rightEdgB: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth:36
  },
  pageTitle: {
    border: "1px solid rgba(0, 0, 0, 0.23);",
    paddingTop: "9px",
    flexGrow: 0,
    padding: "9px 17px 9px"
  },
  separate: {
    textTransform: "none",
    color: linkColor,
    fontSize: 14,
    fontWeight: "normal"
  }
}))

type Func = () => void

export default (props: any) => {
  const localization = {
    ...defaultProps.localization,
    ...props.localization,
  }
  const classes = useStyles()
  const { t } = useTranslation()
  const onAddClick: Func = props.onAddClick ? props.onAddClick : () => { }
  return <Box display="flex" flexDirection="column">
    {!!props.onAddClick ? <Box display="flex" flexDirection="row">
      <Box flexGrow={1} />
      <Button
        className={classes.separate}
        onClick={onAddClick}>
        {t('add')}
      </Button>
    </Box>
    : null }
    <Box flexGrow={1} />
    <Box display="flex" flexDirection="row" margin={"10px 0"}>
      <Box flexGrow={1} />

      <Button variant="outlined"
        className={classes.leftEdgB}
        onClick={(e) => props.onChangePage(e, 0)}>
        {/* {"<<"} */}
        <img src={arrow} alt="arrow" style={{ transform: 'rotate(180deg)' }} />
        <img src={arrow} alt="arrow" style={{ transform: 'rotate(180deg)', marginLeft: -2 }} />
      </Button>
      <Button variant="outlined"
        className={classes.midButtton}
        onClick={(e) => props.page > 0 ?props.onChangePage(e, props.page - 1) : null}>
        {/* {"<<"} */}
        <img src={arrow} alt="arrow" style={{ transform: 'rotate(180deg)' }} />
      </Button>
      <Typography
        className={classes.pageTitle}
        variant="caption"
      >
        {localization.labelDisplayedRows
          .replace(
            "{from}",
            props.count === 0
              ? 0
              : props.page * props.rowsPerPage + 1
          )
          .replace(
            "{to}",
            Math.min(
              (props.page + 1) * props.rowsPerPage,
              props.count
            )
          )
          .replace("{count}", props.count)}
      </Typography>
      <Button variant="outlined"
        className={classes.midButtton}
        onClick={(e) => props.onChangePage(e, props.page + 1)}>
        {/* {">>"} */}
        <img src={arrow} alt="arrow" />
      </Button>
      <Button variant="outlined"
        className={classes.rightEdgB}
        onClick={(e) => props.onChangePage(
          e,
          Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)
        )}>
        {/* {">>"} */}
        <img src={arrow} alt="arrow" />
        <img src={arrow} alt="arrow" style={{ marginLeft: -2 }} />
      </Button>
    </Box>
  </Box>
} 