
interface IExpertDashBoardData {
  total: number,
  finished: number,
  forwarded: number,
  work_time: number,
  max_time: number,
  min_time: number,
  average_time: number
}

export type TExpertDashBoardState = {
  data?: IExpertDashBoardData
}

export const LOAD_EXPERT_REPORT = "DSH_LOAD_EXPERT_REPORT"

export interface ILoadExpertReportAction {
  type: typeof LOAD_EXPERT_REPORT,
  response: any
}

export const loadReportAction = (report: any): ILoadExpertReportAction => {
  return {
    type: LOAD_EXPERT_REPORT,
    response: report
  }
}

export type ExpertDashBoardAction = ILoadExpertReportAction