import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Divider,
  Paper, makeStyles, createStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {getHoursWork} from "../helper";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
    }
  })
)

type Props = {
  data: {
    max?: number
    average?: number
    min?: number
  }
}

export default ({ data }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return <Card variant="outlined" style={{marginBottom: "25px"}}>
    <CardHeader title={t("service time")} classes={classes}/>
    <CardContent>
      <Box display="flex" flexDirection="row">
        <Paper
          elevation={0}
          className="dash-plate dash-plate-time">
          <p className="dash-plate-caption">
            {t('max service time')}
          </p>
          <p className="dash-plate-time-value">
            {getHoursWork(data.max || 0, t('h'), t('min'), t('sec'))}
          </p>
        </Paper>
        <Box>
          <Divider orientation="vertical"/>
        </Box>
        <Paper
          elevation={0}
          className="dash-plate dash-plate-time">
          <p className="dash-plate-caption">
            {t('average service time')}
          </p>
          <p className="dash-plate-time-value">
            {getHoursWork(data.average || 0, t('h'), t('min'), t('sec'))}
          </p>
        </Paper>
        <Box>
          <Divider orientation="vertical"/>
        </Box>
        <Paper
          elevation={0}
          className="dash-plate dash-plate-time">
          <p className="dash-plate-caption">
            {t('min service time')}
          </p>
          <p className="dash-plate-time-value">
            {getHoursWork(data.min || 0, t('h'), t('min'), t('sec'))}
          </p>
        </Paper>
      </Box>
    </CardContent>
  </Card>
}