import { buildStore, buildCreateContainer, buidlRemoteSyncContainer } from "../../features/entity"
import { TicketTemplate } from "./model"
import table from "./table"

export { TicketTemplate } from "./model"
export const TicketTemplateReducer = buildStore(TicketTemplate)
export const RemoteSyncTicketTemplateContainer = buidlRemoteSyncContainer(TicketTemplate)
export const ListTicketTemplateContanier = table
export const CreateTicketTemplateContanier = buildCreateContainer(TicketTemplate, () => new TicketTemplate(""))

