import React, {useEffect} from 'react'
import {Typography, Box, Grid} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Modal } from '../../../shared/components/modal'
import {Ticket} from "../../../models/ticket";
import {datePrepare} from "./steps/history_visit";
import History from "../../../models/ticket/components/history";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {RemoteSyncTicketTemplateContainer, TicketTemplate} from "../../../models/ticket_template";
import {Field} from "../../../models/field";
import {fetchRequest} from "../../../features/entity/store/actions";
import {TICKETS_HISTORY_LOAD} from "../../../models/ticket/saga";

type TProps = {
  ticket: Ticket,
  open: boolean,
  setOpen: (v: boolean) => void
}

export default ({ ticket , open, setOpen }: TProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const ticketWithHistory = useSelector((state: AppState) => state.tickets.entities[ticket?.id]) as Ticket
  const { ticketTemplates } = useSelector((state: AppState) => ({
    ticketTemplates: Object.keys(state.ticket_templates.entities).map(k => state.ticket_templates.entities[k] as TicketTemplate)
  }))

  useEffect(() => {
    if (ticket) {
      dispatch(fetchRequest(`tickets/${ticket.id}/changes`, {method: "GET"},
        TICKETS_HISTORY_LOAD))
    }
  }, [ticket])

  let ticketTemplate = ticketTemplates?.find(template => template.id == ticket?.ticket_template_id )

  return <>
    <RemoteSyncTicketTemplateContainer />
    <Modal
      title={t('session')}
      titleAdditional={datePrepare(i18n, ticket?.created_at)}
      open={open}
      setOpen={setOpen}
      className={"info-session-dialog"}>
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          style={{paddingLeft: 20, paddingRight: 20}}>
          <Box>
            <Box>
              <Typography variant="h5" style={{ fontSize: 18, fontWeight: "normal", marginTop: 20 }}>
                {t('data')}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography className="info-session-text-title">
                    {t('expert')}
                  </Typography>
                  <Typography className="info-session-text">
                    {ticket?.expert?.name || ""}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="info-session-text-title">
                    {t('driver')}
                  </Typography>
                  <Typography className="info-session-text">
                    {(ticket?.client_data?.last_name || "") + " " + (ticket?.client_data?.first_name || "")}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="info-session-text-title">
                    {t('phone')}
                  </Typography>
                  <Typography className="info-session-text">
                    {ticket?.client_data?.phone}
                  </Typography>
                </Box>
                {
                  Object.keys(ticket?.expert_data || {})?.map(k => {
                    return <Box key={k}>
                            <Typography className="info-session-text-title">
                              {ticketTemplate?.fields?.find((t: Field) => t.id == k)?.display_name}
                            </Typography>
                            <Typography className="info-session-text">
                              {ticket.expert_data[k] as string}
                            </Typography>
                          </Box>
                  })
                }
              </Box>
            </Box>
          </Box>
          <Box style={{marginLeft: '100px'}}>
            <Box>
              <Typography variant="h5" style={{ fontSize: 18, fontWeight: "normal", marginTop: 20 }}>
                {t('service history')}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <History history={ticketWithHistory?.history || []} />
            </Box>
          </Box>
        </Grid>
      </>
    </Modal>
  </>
}