import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import {makeStyles, Theme, createStyles, Container, Box} from '@material-ui/core'
import { Link, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    title: {
      margin: "28px 0 18px 33px",
      fontSize: 24,
      fontWeight: 500,
      color: "#222222",
      lineHeight: "28px"
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      color: "#222222",
      lineHeight: "11px",
    },
    links: {
      width: "260px",
      height: "19px",
      fontSize: 14,
      color: "#4E49F5"
    },
    accordion: {
      marginBottom: 15,
      width: "100%",
      paddingLeft: 8
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(0),
      paddingBottom: 15,
      margin: "10px 0 0 0",
      display: "flex",
      flexGrow: 1,
      maxWidth: "100%"
    },
  })
)


export default () => {
  const classes = useStyles()
  const { t } = useTranslation()
  let match = useRouteMatch()
  return <Container className={classes.container}>
    <Box display="flex" flexDirection="column" width="100%">
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{t("by hubs")}</Typography>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <MenuList>
            <MenuItem>
              <Link to={`${match.path}/hubs/history`}  className={classes.links}>
                {t("visit history")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={`${match.path}/hubs/desktop`} className={classes.links}>
                {t("work time conotrol")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={`${match.path}/tickets`} className={classes.links}>
                {t("tickets")}
              </Link>
            </MenuItem>
          </MenuList>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>{t("by experts")}</Typography>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <MenuList>
            <MenuItem>
              <Link to={`${match.path}/experts/history`} className={classes.links}>
              {t("visit history")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={`${match.path}/experts/desktop`} className={classes.links}>
              {t("work time conotrol")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={`${match.path}/experts/report`} className={classes.links}>
              {t("expert report")}
              </Link>
            </MenuItem>
          </MenuList>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>{t("by users")}</Typography>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <MenuList>
            <MenuItem>
              <Link to={`${match.path}/experts/tickets_created_by_expert`} className={classes.links}>
                {t("tickets created by experts")}
              </Link>
            </MenuItem>
          </MenuList>
        </AccordionDetails>
      </Accordion>
    </Box>
  </Container>
}