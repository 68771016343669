import React, {useEffect, useState} from 'react'
import {FormControl, Select, MenuItem, ListItemText, Checkbox} from '@material-ui/core'
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {getCheckedIcon, getUncheckedIcon} from "../../../assets/icons";
import {ISelectItem} from "../../../config/pages/statistics/components/multiple";

type Props = {
  collection: ISelectItem[]
  val: string
  setVal: (v: string) => void
  className?: string
  errorClassName?: string
}

export default (props: Props) => {

  const {t} = useTranslation()

  const [collection, setCollection] = useState(props.collection)

  useEffect(() => {
    setCollection(props.collection)
  }, [props.collection.length])

  const uncheckedIcon = getUncheckedIcon()
  const checkedIcon = getCheckedIcon()

  const handleSearch = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value

    if (value) {
      const collection = props.collection.filter(item => {
        return item.display_name.match(new RegExp(value, 'i'))
      })
      setCollection(collection)
    } else {
      setCollection(props.collection)
    }
  }

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  }

  return <FormControl className={`table-select-from`}>
    <Select
      labelId="select-cities-statistic-field"
      id="select-cities-statistic-field-label"
      value={props.val}
      onChange={e => props.setVal(e.target.value as string)}
      onClose={() => setCollection(props.collection)}
      displayEmpty
      className={`table-select-field`}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      renderValue={(selected) => {
        const item = collection.find(item => item.id == selected)
        if (item)
          return item.display_name
        else
          return ""
      }}>
      <MenuItem value={"search"}
                onClickCapture={stopImmediatePropagation}
                onKeyDown={e => e.stopPropagation()}>
        <TextField
          placeholder={t('search')}
          onChange={handleSearch}
          onBlur={(event) => event.target.focus()}>
        </TextField>
      </MenuItem>
      {collection.map(c => <MenuItem key={c.id} value={c.id}>
          <ListItemText primary={c.display_name}/>
          <Checkbox checked={props.val == c.id}
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    style={{padding: '0', marginLeft: '5px'}}/>
        </MenuItem>
      )}

    </Select>
  </FormControl>
}