import { AdminDashBoardAction, TAdminDashBoardState, LOAD_ADMIN_REPORT } from "./type_admin"
import { ExpertDashBoardAction, TExpertDashBoardState, LOAD_EXPERT_REPORT } from "./type_expert"

const initStateAdmin: TAdminDashBoardState = {
  data: undefined
}

const initStateExpert: TExpertDashBoardState = {
  data: undefined
}

export function AdminDashBoardReducer(state: TAdminDashBoardState = initStateAdmin, action: AdminDashBoardAction): TAdminDashBoardState {
  switch (action.type) {

    case LOAD_ADMIN_REPORT:
      return {
        ...state,
        data: action.response
      }

    default:
      return state
  }
}

export function ExpertDashBoardReducer(state: TExpertDashBoardState = initStateExpert, action: ExpertDashBoardAction): TExpertDashBoardState {
  switch (action.type) {

    case LOAD_EXPERT_REPORT:
      return {
        ...state,
        data: action.response
      }

    default:
      return state
  }
}