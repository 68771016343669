type TResult<T> = T & { color: string}

const colors = [
  "#97CC64", "#A955B8", "#FD5A3E", "#FFD963", "#77B6E7", "#333"
]

export function colorizeData<T>(data: T[]): TResult<T>[]{
  return data.map((e,i) => ({...e, color: colors[i % colors.length]}))
}

export function getHoursWork(time: number, hLabel: string, minLabel: string, sLabel: string): string {
  const hours = Math.floor(time/3600);
  const minutes = Math.floor((time-hours * 3600) / 60);
  const seconds = Math.floor((time%60));

  if ((!hours && !minutes && !seconds) || (hours === 0 && minutes === 0 && seconds === 0))
    return `0 ${minLabel}`

  return `${hours && hours !== 0 ? `${hours} ${hLabel}` : ''} 
          ${minutes && minutes !== 0 ? `${minutes} ${minLabel}` : ''} 
          ${seconds && seconds !== 0 ? `${seconds} ${sLabel}` : ''}`
}