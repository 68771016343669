import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../config/store'
import { TimeSlot } from './time_slot'
import { EntityId } from '../../features/entity/type'
import { RemoteSyncTimeSlotContainer } from '.'
import { Container, Box, Table, TableRow, TableBody, TableCell, TextField, Button, TableHead, TableFooter, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import delIcon from '../../assets/del.svg'

const TWeekKey: TWeekKey[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
type TWeekKey = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun"

type TDaySlotsProps = {
  workday: number
  hub_id: EntityId
  slots: TimeSlot[]
  addingState: boolean
  setAddingState: (v: boolean) => void
}

const DaySlots = ({ slots, hub_id, workday, addingState, setAddingState }: TDaySlotsProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [newTimeSlot, setNewTimeSlot] = useState<TimeSlot | null>(null)
  return <Box className="workday-ts-wrapper">
    <Table className="mtable timeslot-table">
      <TableHead>
        <TableRow>
          <TableCell className="space-title">
          </TableCell>
          <TableCell className="workday-slot-title" colSpan={7}>
            {t(TWeekKey[workday-1])}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="space-title">
          </TableCell>
          <TableCell className="hour-cell-ts-title" colSpan={2}>
            {t('Period')}
          </TableCell>
          <TableCell colSpan={3}>

          </TableCell>
          <TableCell colSpan={2} className="capasity-cell-ts-title">
            {t('Capacity')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
        {slots.map(s => <TableRow>
          <TableCell>
            {t('from')}
          </TableCell>
          <TableCell className="hour-cell-ts">
            <TextField variant="outlined" disabled value={`${s.since_hour} ${t("hour_short")}`} />
          </TableCell>
          <TableCell className="min-cell-ts">
            <TextField variant="outlined" disabled value={`${s.since_min} ${t("min_short")}`} />
          </TableCell>
          <TableCell className="till-cell-ts">
            {t("till")}
          </TableCell>
          <TableCell className="hour-cell-ts">
            <TextField variant="outlined" disabled value={`${s.till_hour} ${t("hour_short")}`} />
          </TableCell>
          <TableCell className="min-cell-ts">
            <TextField variant="outlined" disabled value={`${s.till_min} ${t("min_short")}`} />
          </TableCell>
          <TableCell className="capasity-cell-ts">
            <TextField variant="outlined" disabled value={`${s.capacity} ${t("human_short")}`} />
          </TableCell>
          <TableCell className="del-cell-ts">
            <img src={delIcon} alt="del" onClick={() => dispatch(s.deleteMeAction())} />
          </TableCell>
        </TableRow>)}
      </TableBody>
      {newTimeSlot ?
        <TableBody>
          <TableRow>
            <TableCell>
              {t('from')}
            </TableCell>
            <TableCell className="hour-cell-ts">
              <TextField variant="outlined"
                label={t("hour_short")}
                value={newTimeSlot.since_hour}
                type="number"
                onChange={e => setNewTimeSlot(Object.assign(new TimeSlot(), { ...newTimeSlot, since_hour: Number(e.target.value) }))} />
            </TableCell>
            <TableCell className="min-cell-ts">
              <TextField variant="outlined"
                label={t("min_short")}
                value={newTimeSlot.since_min}
                type="number"
                onChange={e => setNewTimeSlot(Object.assign(new TimeSlot(), { ...newTimeSlot, since_min: Number(e.target.value) }))} />
            </TableCell>
            <TableCell className="till-cell-ts">
              {t("till")}
            </TableCell>
            <TableCell className="hour-cell-ts">
              <TextField variant="outlined"
                label={t("hour_short")}
                value={newTimeSlot.till_hour}
                type="number"
                onChange={e => setNewTimeSlot(Object.assign(new TimeSlot(), { ...newTimeSlot, till_hour: Number(e.target.value) }))} />
            </TableCell>
            <TableCell className="min-cell-ts">
              <TextField variant="outlined"
                label={t("min_short")}
                value={newTimeSlot.till_min}
                type="number"
                onChange={e => setNewTimeSlot(Object.assign(new TimeSlot(), { ...newTimeSlot, till_min: Number(e.target.value) }))} />
            </TableCell>
            <TableCell className="capasity-cell-ts">
              <TextField variant="outlined"
                label={t("human_short")}
                value={newTimeSlot.capacity}
                type="number"
                onChange={e => setNewTimeSlot(Object.assign(new TimeSlot(), { ...newTimeSlot, capacity: Number(e.target.value) }))} />
            </TableCell>
            <TableCell className="del-cell-ts">
              <IconButton size="small"
                onClick={() => {
                  dispatch(newTimeSlot.createMeAction())
                  setNewTimeSlot(null)
                  setTimeout(() => setAddingState(false), 500)
                }}
                color="secondary">
                <AddIcon style={{ width: 20 }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
        : null}

      <TableFooter>
        <TableRow>
          <TableCell colSpan={6}>
          </TableCell>

          <TableCell colSpan={2} className="capasity-cell-ts">
            <Button className="time-slot-add-button"
              disabled={addingState}
              onClick={() => {
                setAddingState(true)
                const blank = new TimeSlot()
                blank.hub_id = hub_id
                blank.since_hour = 1
                blank.since_min = 0
                blank.till_hour = 12
                blank.till_min = 1
                blank.capacity = 10
                blank.wday = workday
                setNewTimeSlot(blank)
              }}>
              {t('add')}
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  </Box>
}

type PropsFromRedux = {
  hub_id: EntityId
}

const wdays = [1, 2, 3, 4, 5, 6, 7]

export default (props: PropsFromRedux) => {
  const [inAddingState, setAddingState] = useState(false)
  const slots = useSelector((state: AppState) => state.time_slots.entities)
  let slotsByDay: { [K in keyof TWeekKey]?: TimeSlot[] } = {}
  wdays.forEach(d => slotsByDay[d] = [])

  for (let id in slots) {
    const slot = (slots[id] as TimeSlot)
    // const day = weekDayKyes[slot.wday - 1]
    if (slot.wday in slotsByDay) {
      slotsByDay[slot.wday]!.push(slot)
    }
  }
  return <>
    <RemoteSyncTimeSlotContainer customUrl={`hubs/${props.hub_id}/time_slots`} />
    <Container>
      <Box className="timeslot-container">
        {wdays.map(d => <DaySlots
          slots={slotsByDay[d]!}
          workday={d}
          hub_id={props.hub_id}
          addingState={inAddingState}
          setAddingState={setAddingState}
        />)}
      </Box>
    </Container>

  </>
}