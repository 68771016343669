import React from 'react'
import waitIcon from '../../../../assets/wait.svg'
import { useDispatch } from 'react-redux'
import { changeDeskAction, startWorkSessionAction } from '../store'
import Template from './step_template'
import { Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default () => {
  const dispatch = useDispatch()
  const changeDeskClick = () => dispatch(changeDeskAction())
  const startWorkSessionClick = () => dispatch(startWorkSessionAction())
  const { t } = useTranslation()
  const header = <Typography variant="h5">
    {t('Start queue handling, when you will be ready')}
  </Typography>
  const bottom = <>
    <Button variant="outlined" 
    color="default" 
    qa-tag="ed-change-desk-button"
    className="default-button"
    onClick={changeDeskClick}>
      {t('Change desk')}
    </Button>
    <Button style={{ marginLeft: 20 }} 
      variant="contained" 
      qa-tag="expert-desk-start-session-button"
      color="primary"
      className="primary-button"
      onClick={startWorkSessionClick}>
      {t("Start queue handling")}
    </Button>
  </>
  return <Template
    header={header}
    bottom={bottom}
    hideAlarmButton
    stretcher={<img src={waitIcon} alt="wait worksession" />}
    />
}