import { Box, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { useSelector } from 'react-redux'
import { Ticket } from '..'
import { RemoteSyncTicTagContainer, TicTag } from '../../tic_tag'
import { AppState } from '../../../config/store'


const useStyles = makeStyles(() => ({
  alert: {
    paddingLeft: 9,
    '& > div': {
      paddingBottom: 0,
      paddingTop: 0
    }
  }
}))

interface IProps {
  ticket: Ticket | null
  status: string
}

export default ({ ticket }: IProps) => {
  const tag = useSelector((state: AppState) => ticket && state.tags.entities[ticket?.tag_id])
  const classes = useStyles()
  return <Box display="flex" flexDirection="row">
    <RemoteSyncTicTagContainer />
    <Typography variant="h5" className="client-handling ticket-title">
      <b qa-tag="client-name">{ticket?.client_data?.last_name} {ticket?.client_data?.first_name}</b> — {ticket?.ticket_no}
    </Typography>
    <Box flexGrow={1} />
    {tag && <Alert severity="info" className={classes.alert}>{(tag as TicTag).display_name}</Alert>}

  </Box>
}