import React, {useState} from 'react'
import {RemoteSyncDeskContainer, Desk} from '../../../../models/desk'
import {AppState} from '../../../store'
import {Button, Box, Typography} from '@material-ui/core'
import {selectDeskAction} from '../store'
import {RemoteSyncHubContainer} from '../../../../models/hub'
import {RemoteSyncLineContainer} from '../../../../models/line'
import {useSelector, useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Template from './step_template'
import {endWorkAction} from '../type'

export default () => {
  const preDesk: Desk[] = []
  const desks = useSelector((state: AppState) => state.desks.entities)
  const dispatch = useDispatch()
  const [selected, setSelected] = useState<Desk | null>(null)
  const {t} = useTranslation()
  for (let key in desks) {
    preDesk.push(desks[key])
  }
  return <Template
    hideAlarmButton
    header={
      <Typography variant="h5">
        {t("Please, choose your table")}
      </Typography>}
    bottom={<>
      <Button variant="outlined"
              qa-tag="ep-back-button"
              className="default-button"
              onClick={() => dispatch(endWorkAction())}>
        {t('End work')}
      </Button>
      <Button
        variant="contained"
        disabled={!selected}
        style={{width: 156, marginLeft: 20}}
        qa-tag="expert-desk-next-button"
        onClick={() => dispatch(selectDeskAction(selected!))}
        color="primary"
        className="primary-button">
        {t('Next')}
      </Button>
    </>
    }><>
    <RemoteSyncHubContainer/>
    <RemoteSyncLineContainer/>
    <RemoteSyncDeskContainer customUrl="desks/free"/>
    <Box marginTop={2}>
      <Box display="flex" flexWrap="wrap">
        {preDesk.map(d => <Button
          className={`select-desk desk-button ${d.id == selected?.id ? 'primary-button' : 'select-button'}`}
          key={d.id}
          variant="text"
          qa-tag="some-desk"
          disableElevation
          onClick={() => setSelected(d)}>
          {d.display_name}
        </Button>)}
      </Box>
    </Box>
  </>
  </Template>
}