import { connect } from 'react-redux'

import RemoteSync from '../components/remote_sync'
import { BaseEntity } from '..'
import { TEntityClass } from '../type'
import { fetchRequest, buildActionTypeName } from '../store/actions'
import { getStorageEntityKey } from '../helpers/storage_key'

interface IOwnProps {
  customUrl?: string
  customPrefix?: string
}

export function buildMapState
  () {
  return (state: any, ownProps: IOwnProps) => {
    return {
      customUrl: ownProps.customUrl
    }
  }
}

function buildMapDispatch<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>
  (t: TEClass) {
  const actionsTypeName = buildActionTypeName(t)
  const keySotrage = getStorageEntityKey(t)
  const options = { method: "GET" }
  return (dispatch:any,  ownProps: IOwnProps) => ({
    reload: (customUrl?: string) => {
      const url = ownProps.customUrl ? ownProps.customUrl : keySotrage
      const callback = actionsTypeName.RELOAD_ACTION_TYPE
      return dispatch(fetchRequest(url, options, callback, ownProps.customPrefix))
    }
  })
}

export default function buidlContainer<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>
  (t: TEClass) {
  const mapState = buildMapState()
  const mapDispatch = buildMapDispatch(t)
  const connector = connect(mapState, mapDispatch)
  return connector(RemoteSync)
}