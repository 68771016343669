import React, {useRef} from 'react'
import {Box, Typography} from '@material-ui/core'
import {Ticket} from "../ticket";
import ReactToPrint from "react-to-print";
import {useTranslation} from "react-i18next";
import {getPrintIcon} from "../../../assets/icons";

import moment from 'moment';

type PrintTicketProps = {
  ticket: Ticket,
  line: string,
  hello: string,
  signedUp: string,
  numberTicket: string,
  endTicket: string
}

class PrintTicket extends React.PureComponent<PrintTicketProps> {
  render() {
    return (
      <Box className="print-ticket-content" display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className="text-additional">{this.props.numberTicket}</Typography>
          <Typography className="text3">{this.props.ticket.ticket_no}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className="text1">{moment().format("DD-MM-YYYY HH:mm")}</Typography>
        </Box>
      </Box>
    );
  }
}

type TProps = {
  ticket: Ticket,
  line: string
}

const pageStyle = `
  @page {
    size: 80mm 80mm;
  }
  
 @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: none;
    }
  }
`

const ButtonPrintTicket = (props: TProps) => {
  const componentRef = useRef();
  const { t } = useTranslation()
  return (
    <div>
      <ReactToPrint
        pageStyle={pageStyle}
        trigger={() => <Box display="flex" flexDirection="row" alignItems="center"
                            className="print-block">
                        <Box>{getPrintIcon()}</Box>
                        <Typography className="text">{t('print')}</Typography>
                      </Box>}
        // @ts-ignore
        content={() => componentRef.current}
      />
      {
        <div style={{ display: "none"}}>
          {
            <PrintTicket ticket={props.ticket}
                         line={props.line}
                         hello={t('hello')}
                         signedUp={t('you signed up')}
                         numberTicket={t('your ticket number')}
                         endTicket={t('take the coupon and wait for you to be called')}
                         // @ts-ignore
                         ref={componentRef}

            />
          }

        </div>
      }
    </div>
  );
};

export default ButtonPrintTicket;