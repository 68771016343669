import React, {FunctionComponent, useState, MutableRefObject, useEffect} from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

interface IProps {
  currentValue: string
  onChange: (input: string) => void
  // @ts-ignore
  keyboardRef: MutableRefObject<Keyboard>
}

const KeyboardWrapper: FunctionComponent<IProps> = ({currentValue, onChange, keyboardRef}) => {
  const [layoutName, setLayoutName] = useState("shift");

  useEffect(() => {
    if (!currentValue)
      setLayoutName("shift")
    else
      setLayoutName("default")
  }, [currentValue])


  let englishLayout = {
    default: [
      "1 2 3 4 5 6 7 8 9 0 {bksp}",
      "q w e r t y u i o p",
      "a s d f g h j k l {enter}",
      "{shift} z x c v b n m {shift}",
      "{space}"
    ],
    shift: [
      "1 2 3 4 5 6 7 8 9 0 {bksp}",
      "Q W E R T Y U I O P",
      'A S D F G H J K L {enter}',
      "{shift} Z X C V B N M {shift}",
      "{space}"
    ]
  }

  let russianLayout = {
    default: [
      "1 2 3 4 5 6 7 8 9 0 {bksp}",
      "й ц у к е н г ш щ з х ъ",
      "ф ы в а п р о л д ж э ё {enter}",
      "{shift} я ч с м и т ь б ю {shift}",
      "{space}"
    ],
    shift: [
      "1 2 3 4 5 6 7 8 9 0 {bksp}",
      "Й Ц У К Е Н Г Ш Щ З Х Ъ",
      'Ф Ы В А П Р О Л Д Ж Э Ё {enter}',
      "{shift} Я Ч С М И Т Ь Б Ю {shift}",
      "{space}"
    ]
  }

  let display = {
    "{bksp}": "backspace ⌫",
    "{enter}": "enter ↵",
    "{shift}": "shift ⇧",
    "{space}": " "
  }

  let keyboardOptions = {
    layout: russianLayout,
    display: display
  }

  const onKeyPress = (button: string) => {
    var currentWord = currentValue
    if (button != "{space}" && button != "{bksp}" && button != "{enter}" && button != "{shift}") {
      currentWord += button
    }

    if (button == "{bksp}") {
      currentWord = currentWord.substring(0, currentWord.length - 1)
    }

    if (button == "{enter}")
      setLayoutName("shift")

    if (currentWord.length == 1)
      setLayoutName("default")

    if (currentWord.length == 0)
      setLayoutName("shift")

    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
  }

  return (
    <Keyboard
      {...keyboardOptions}
      // @ts-ignore
      keyboardRef={r => (keyboardRef.current = r)}
      layoutName={layoutName}
      onKeyPress={(button: string) =>  {
          onKeyPress(button)
          onChange(button)
        }
      }
    />
  );
};

export default KeyboardWrapper;
