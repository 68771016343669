import React from 'react'
import { Select, MenuItem, InputLabel } from '@material-ui/core'


type TEvent = React.ChangeEvent<{ value: unknown }>

export interface IOption {
  value: string
  display_value: string
}

interface IProps {
  value: string
  onChange: (event: TEvent) => void
  options: IOption[]
  caption: string
}


export default (props: IProps) => {
  return <React.Fragment>
    <InputLabel shrink>
      {props.caption}
        </InputLabel>
    <Select variant="outlined"
      fullWidth
      className="cy_city_select"
      value={props.value}
      onChange={props.onChange}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}>
      {props.options.map((option, index) => <MenuItem key={index} className="cy_city_select_option" value={option.value}>
        {option.display_value}
      </MenuItem>)}
    </Select>
  </React.Fragment>
}
