import { Column } from "material-table"
import { BaseEntity } from "../BaseEntity"
import { TAditionalStore } from "../type"
import { BaseAttribute, TEditable } from "./base_attribute"

type Hash = {[ind: string]: string}

export class StatickLookup extends BaseAttribute {
  collection: Hash
  constructor(key: string, editable:TEditable = "always", hide: boolean = false, collection:Hash = {}) {
    super(key, editable, hide)
    this.collection = collection
  }
  buildColumn(additionals: TAditionalStore): null | Column<BaseEntity>{
    const initialEditValue: string = Object.keys(this.collection).length > 0 ? 
        this.collection[Object.keys(this.collection)[0]]
        : ""
    return {
      title: this.key, field: this.key,
      lookup: this.collection,
      initialEditValue
    }
  }
}