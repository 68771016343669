import { put, select, call, take } from "redux-saga/effects"
import { loginAction, ILoginRes } from "../../authentication/reducers/store"
import { fromLocalStorag, saveToLocalStore } from "../../authentication/reducers/sagas"
import * as API from "../api"
import { Device } from "../../../../models/device/device"
import { changePageAction } from "../store"
import { setErrorAction, IInputAuthCodeAction, INPUT_AUTH_CODE, setCurrentHubAction, setCurrentCityAction, setCurrentDeviceAction } from "../type"
import { Hub } from "../../../../models/hub"
import { City } from "../../../../models/city"

export default function* () {
  let localStorage: ILoginRes = yield select(fromLocalStorag)
  if (localStorage) {
    yield put(loginAction(localStorage))

    const rawDevice: any = yield call(API.getDevice)
    const device = Device.buildFromRaw<Device>(rawDevice, Device)
    const hub = Hub.buildFromRaw<Hub>(rawDevice.hub, Hub)
    const city = City.buildFromRaw<City>(rawDevice.hub.city, City)
    yield put(setCurrentHubAction(hub))
    yield put(setCurrentCityAction(city))
    yield put(setCurrentDeviceAction(device))
    localStorage["user_id"] = "device"
    localStorage["scope"] = device.kind
    yield call(saveToLocalStore, localStorage)
    return {
      deviceType: device.isTerminal() ? "TERMINAL" : "TV",
      deviceId: device.id
    }
  }
  while (true) {
    yield put(changePageAction("AUTH_CODE"))
    const { auth_code }: IInputAuthCodeAction = yield take(INPUT_AUTH_CODE)
    try {
      const res: any = yield call(API.activateDevice, auth_code)
      let creditinals: ILoginRes = {
        user_id: "device",
        exp: -1,
        nbf: 0,
        scope: "not_known_yet",
        token: res.token
      }
      yield put(loginAction(creditinals))

      const rawDevice: any = yield call(API.getDevice)
      const device = Device.buildFromRaw<Device>(rawDevice, Device)
      const hub = Hub.buildFromRaw<Hub>(rawDevice.hub, Hub)
      const city = City.buildFromRaw<City>(rawDevice.hub.city, City)
      yield put(setCurrentHubAction(hub))
      yield put(setCurrentCityAction(city))
      yield put(setCurrentDeviceAction(device))
      creditinals["user_id"] = "device"
      creditinals["scope"] = device.kind
      yield call(saveToLocalStore, creditinals)

      return {
        deviceType: device.isTerminal() ? "TERMINAL" : "TV",
        deviceId: device.id
      }

    } catch (error) {
      console.log(error)
    }
    yield put(setErrorAction("wrong activation code"))
  }
}
