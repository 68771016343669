import React, {useRef} from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import InputMask from "react-input-mask";
import { activeTabColor } from "../../../../styles/variables";

export const MIN_PHONE_NUMBER = 11

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      '& > div > div:after': {
        borderBottomWidth: 2,
        borderBottomColor: activeTabColor
      },
      '& input': {
        borderBottomColor: activeTabColor,
        fontSize: "4vh"
      }
    },
  }),
)

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void,
  phoneCode: string
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props

  const defaultPhoneCode = '+' + other.phoneCode
  const phoneCode = '+' + other.phoneCode.replace(/9/g, "\\9") + "\\"

  return (
      <InputMask
          {...other}
          placeholder={defaultPhoneCode}
          mask={phoneCode + " 9999999999"}
          maskChar={null}
      />
  )
}

interface IProps {
  id: string,
  onKeyUp: any,
  defaultValue?: any
  value?: any
  onFocus?: ((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
  focused: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  phoneCode: string
}

export default function FormattedInputs(props: IProps) {
  const classes = useStyles()

  const phoneInput = useRef<any>();

  return (
    <div className={classes.root}>
      <FormControl focused={props.focused}>
        <Input
        {...props}
        name="textmask"
        id={props.id}
        ref={phoneInput}
        onKeyUp={props.onKeyUp}
        inputComponent={TextMaskCustom as any}
        inputProps={{phoneCode: props.phoneCode, qa_tag:"phone-input"}}
        onFocus={props.onFocus}
        onClick={() => { phoneInput.current.focus() }}
        autoFocus
      />
      </FormControl>
    </div>
  )
}
