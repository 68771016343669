import { BaseAttribute } from "./attributes/base_attribute"
import { BaseEntity } from "./BaseEntity"

export type EntityId = string

export interface IEntityBase {
  id: EntityId
}

export interface ICreateAction<TEntity extends IEntityBase> {
  type: string
  response: any
}

export interface IUpdateAction<TEntity extends IEntityBase> {
  type: string
  response: any
}

export interface IReloadAction{
  type: string
  response: any
}

export interface ISingleLoadAction{
  type: string
  response: any
}

export interface IDeleteAction {
  type: string
  response: any
}

export const FETCH_ACTION = "FETCH_ACTION"

export interface IFetchAction{
  type: typeof FETCH_ACTION
  url: RequestInfo
  options: RequestInit
  callbackAction: string
  apiPrefix?: string
}

export type ActionTypes<TEntity extends IEntityBase> = ICreateAction<TEntity> | IUpdateAction<TEntity> | IDeleteAction | IReloadAction | IFetchAction

export interface TEntityClass<TEnt extends IEntityBase> {
  new(): TEnt
  attributes: BaseAttribute[]
  entity_name: string
  buildFromRaw(raw: any, t: TEntityClass<TEnt>): TEnt
}

export type TStoreType = { [key: string]: BaseEntity }

export interface EntityState<TEntity> {
  //key should be id type
  entities: { [key: string]: TEntity }
}

export type TAditionalStore = { 
  [key: string]: { 
    entities: TStoreType
  }
}

export type TMappedState = {
  filtered: BaseEntity[]
  mapEntitiesById: TStoreType
  additionals: TAditionalStore
  attributes: BaseAttribute[]
  title: string
  editable: boolean
  tabs?: React.ReactElement
}

export type EntityReducer<TEntity extends IEntityBase> = (state: EntityState<TEntity> | undefined, action: ActionTypes<TEntity>) => EntityState<TEntity>