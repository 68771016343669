import { call } from "redux-saga/effects"
import { fetchM } from "../shared/fetchM"
import { IClientData } from "./type"
import { EntityId } from "../../../features/entity/type"
import { Moment } from "moment"

const baseUrl = () => `${(window as any).globalAppConfig.backEndUrl}`

const fetchd = (url: RequestInfo, options: RequestInit) => fetchM(url, options,
  "DEVICE_PAGE_FETCH_CALLBACK", "service_api")

export interface IAPIResponse {
  success: boolean
  error: string
}

export function* preEntry(clientData: IClientData, slotId: EntityId, date: Moment, lineId: EntityId) {
  const url = '/pre_entries'
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...clientData,
      client_data: clientData,
      selected_date: date.format("YYYY-M-D"),
      time_slot_id: slotId,
      line_id: lineId
    })
  }
  const res: IAPIResponse = yield call(fetchd, url, options)
  return res
}

export function* withoutPreEntry(clientData: IClientData, lineId: EntityId, loyalty: string) {
  const url = '/tickets/check_in'
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...clientData,
      client_data: clientData,
      line_id: lineId,
      priority: loyalty
    })
  }
  const res: IAPIResponse = yield call(fetchd, url, options)
  return res
}

export function* getDevice() {
  const url = '/device'
  const options = {
    method: "GET"
  }
  const res: IAPIResponse = yield call(fetchd, url, options)
  return res
}

export function activateDevice(activation_code: string) {
  const url = `${baseUrl() !== '/' ? baseUrl() : ''}/service_api/tokens/create`
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      activation_code
    })
  }
  return fetch(url, options).then(r => r.json())
}

export function* getTicketsInQueue() {
  const url = '/tickets'
  const options = {
    method: "GET"
  }
  const res: IAPIResponse = yield call(fetchd, url, options)
  return res
}

export function* getLines() {
  const url = '/lines'
  const options = {
    method: "GET"
  }
  const res: IAPIResponse = yield call(fetchd, url, options)
  return res
}

