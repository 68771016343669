export const backgroundColor = "#F5F5F5";

export const textColor = "#222222";

export const textLightTitleColor = "#858585"

export const textLightCaptionColor = "#9D9D9D"

export const activeTabColor = "#FFDE40"

export const linkColor = "#4E49F5";

export const dashboardResultColor = "#97CC64"

export const white = "#FFF"

export const inactiveColor = "#E6E6E6"

export const errorFieldColor = "#F5523A"

export const terminalTextColor = "#5A5A5A"

export const languageIconColor = "#999999"

export const tabTextWeight = 410

export const switchDisabledColor = "#D2D2D2"

export const switchEnabledColor = activeTabColor

export const tableSelectColor = "#F2F2F2"

export const lightGreyColor = "#E3E3E3"

export const activeNumber = "#FCB900"
