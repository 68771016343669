import { BaseAttribute } from "./base_attribute";
import { TEntityClass, IEntityBase } from "../type";


export class HasMany<TEnt extends IEntityBase> extends BaseAttribute {
  builder: TEntityClass<TEnt>
  inChildKey: string
  constructor(key: string, t: TEntityClass<TEnt>, inChildKey: string) {
    super(key)
    this.builder = t
    this.inChildKey = inChildKey
  }
  buildColumn(): null | {title:string, field:string} {
    return null
  }
  setVal(source: any, val: any) {
    if (val) {
      const raws: any[] = val
      source[this.key] = raws.map(r => {
        const res = this.builder.buildFromRaw(r, this.builder) as any
        res[this.inChildKey] = source
        return res
      })
    }
  }
}