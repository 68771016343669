

export const SET_OPEN = "MENU_SET_OPEN"

export interface ISetOpenAction{
  type: typeof SET_OPEN
  open: boolean
}

export const setOpenAction = (open:boolean):ISetOpenAction =>{
  return {
    type: SET_OPEN,
    open
  }
}

type TAction = ISetOpenAction

type MenuState = {
  open: boolean
}

const menuInitialState:MenuState = {
  open: false
}

export default (state:MenuState = menuInitialState, action: TAction): MenuState => {
  switch (action.type) {

    case SET_OPEN:
      return {
        ...state,
        open: action.open
      }   
    
    default:
      return state
  }
}