import { buildStore, buidlRemoteSyncContainer } from '../../features/entity'
import { User } from './user'
import { EntityId } from '../../features/entity/type'
import Table from './table'
export { User } from './user'

export const UserReducer = buildStore(User)
export const RemoteSyncUserContainer = buidlRemoteSyncContainer(User)
export const ListUserContanier = Table
export function loadUser(userId: EntityId) {
  return {
    type: "FETCH_ACTION",
    url: "users/" + userId,
    options: { method: "GET" },
    callbackAction: "users_SINGLE_LOAD_ACTION"
  }
}
