import { put, fork, join } from 'redux-saga/effects'
import { takingResponse, fetchRequest } from '../expert/saga'

export function* fetchM(url: RequestInfo, options: RequestInit, 
  callbackAction: string = "EP_FETCH_TMP_CALLBACK_ACTION",
  prefix?: string, isIgnoreError?: boolean) {
  const task = yield fork(takingResponse, callbackAction)
  yield put(fetchRequest(url, options, callbackAction, prefix, isIgnoreError))
  yield join(task)
  return task.result().response
}
