import React from 'react'
import {Typography, Box} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Modal } from '../../../shared/components/modal'
import {Ticket} from "../ticket";
import History from "../../../models/ticket/components/history_client_data";
import {RemoteSyncTicketTemplateContainer} from "../../ticket_template";

type TProps = {
  ticket: Ticket,
  open: boolean,
  setOpen: (v: boolean) => void
}

export default ({ ticket , open, setOpen }: TProps) => {
  const { t } = useTranslation()

  return <>
    <RemoteSyncTicketTemplateContainer />
    <Modal
      title={ticket?.ticket_no}
      titleAdditional={""}
      open={open}
      setOpen={setOpen}
      className={"info-session-dialog"}
      actions={<></>}>
      <>
        <Box>
          <Box>
            <Typography variant="h5" style={{ fontSize: 18, fontWeight: "normal" }}>
              {t('history change')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" style={{ marginTop: 20 }}>
            <History history={ticket?.history_client_data || []} />
          </Box>
        </Box>
      </>
    </Modal>
  </>
}