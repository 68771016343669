import React, {useState} from 'react'
import {Box, Grid, MenuItem, TextField, Typography} from '@material-ui/core'
import {Comment} from "./comment";
import {timeDDHHMM} from "../../config/pages/statistics/pages/helper";
import {getDeleteGrayIcon, getEditGrayIcon, getSaveIcon} from "../../assets/icons";
import CommentPopover from "./comment_popover";
import {useTranslation} from "react-i18next";

type Props = {
  comment: Comment,
  saveComment: (commentId: string, message: string) => void,
  deleteComment: (commentId: string) => void
}

export default ({ comment, saveComment, deleteComment}: Props) => {

  const [edit, isEdit] = useState(false)
  const {t} = useTranslation()

  const [message, setMessage] = useState(comment.message)

  return <Grid
    container
    direction="row"
    justify="space-between">
    {
      !edit
        ? <Box className="detail-panel-comment">
           <Grid
              container
              direction="row"
              justify="space-between">
              <Box>
                <Typography className="panel-comment-title">{comment.user?.name || ''}</Typography>
              </Box>
              <Box>
                <Typography className="panel-comment-title">{timeDDHHMM(comment.created_at)}</Typography>
              </Box>
            </Grid>
            <Box marginTop="10px">
              <Typography>{comment.message}</Typography>
            </Box>
          </Box>
        : <TextField
            style={{marginTop: 0}}
            label={t('comment')}
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            rows={6}
            multiline
            className={`ep-client-data cl-field area-field detail-panel-edit-comment`}/>
    }
    {
      edit
        ? <Box style={{marginTop: 8}} onClick={() => {
            isEdit(false)
            saveComment(comment.id, message)
          }}>{getSaveIcon()}</Box>
        : <CommentPopover>
          <>
            <MenuItem onClick={() => isEdit(true)} qa-tag="ep-no-client-button">
              <Box style={{marginRight: 10}}>{getEditGrayIcon()}</Box>
              <Box>{t('change')}</Box>
            </MenuItem>
            <MenuItem onClick={() => {
              setMessage("")
              deleteComment(comment.id)}} qa-tag="ep-recall-button">
              <Box style={{marginRight: 10}}>{getDeleteGrayIcon()}</Box>
              <Box>{t('delete')}</Box>
            </MenuItem>
          </>
        </CommentPopover>
    }
    <Box>

    </Box>
  </Grid>
}