import { IEntityBase } from "../../features/entity/type"
import { BaseEntity } from "../../features/entity"

export const UPDATE_ENABLE_TT_ACTION = "UPDATE_ENABLE_TICKET_TEMPLATE_ACTION"

export interface IUpdateEnabledTTAction {
  type: typeof UPDATE_ENABLE_TT_ACTION,
  ticketTemplate: IEntityBase
}

export interface IRawTicketTemplate extends BaseEntity{
  display_name: string,
  enabled: boolean
}