import React, { useEffect } from "react"
import { AppState } from "../../store"
import { useSelector } from "react-redux"
import { TerminalComponent } from "./terminal"
import { ActivatorComponent } from "./auth"
import { Screen } from './tv'
import { useTranslation } from "react-i18next"
import * as Sentry from "@sentry/react";

export default () => {
  const { deviceType, lang, device } = useSelector((state: AppState) => ({
    deviceType: state.devicePage.deviceType,
    lang: state.devicePage.city?.lang,
    device: state.devicePage.device
  }))
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(lang || "ru").then()
  }, [lang])

  useEffect(() => {
    if (device)
      Sentry.setUser({id: device.id, username: device.display_name, kind: device.kind})
  }, [device])

  switch (deviceType) {
    case "TERMINAL":
      return <TerminalComponent />

    case "TV":
      return <Screen />

    default:
      return <ActivatorComponent />
  }
}
