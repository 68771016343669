import {all, call, fork, put, takeEvery} from "redux-saga/effects"
import {rootPolicySaga} from "./policy"
import {ILoginAction, LOGIN_ACTION_TYPE, LOGOUT_ACTION_TYPE} from "./pages/authentication/reducers/store"
import {loadUser} from "../models/user"
import {fetchM} from "./pages/shared/fetchM"
import {setRoleAction} from "../features/policy/actions"
import {getApiResponse, logout} from "./pages/authentication/reducers/sagas"
import {PUSH_MANAGER_ERROR, PUSH_MANAGER_SUCCESS, warningMessageAction} from "./pages/shared/common_store"
import {ISetWorkSessionAction, setWebPushReadyStatusAction, WORK_SESSION_SET} from "./pages/expert/type"
import {
  pushStatistics,
  pushTicket,
  TypeEssence,
  UPDATE_TOKEN_CENTRIFUGO,
  updateToken
} from "../features/centrifugo_saga"
import {fetchRequest} from "../features/entity/store/actions";
import {TCurrentSessionAnswer} from "./pages/expert/saga";
import {setWorkSessionAction} from "./pages/expert/store";
import {GET_CURRENT_WORK_SESSION, IInitStatPush, INIT_STAT_PUSH} from "./types";
import jwt from "jsonwebtoken";

export function* setTokenCentrifugo(){
  const res = yield call(getApiResponse, '/tokens/centrifugo', 'GET')
  window.localStorage["cenToken"] = res.token

  const decode = yield call(jwt.decode, res.token)
  window.localStorage["cenTokenExpires"] = decode.exp * 1000
}

function* login({ logRes }: ILoginAction) {
  if (logRes.user_id !== "device") {
    const act = loadUser(logRes.user_id)

    console.log("root login action type")
    const res = yield call(fetchM, act.url, act.options, "ROOT_LOGIN_AUTH_TAKE")
    yield put({ type: act.callbackAction, response: res })
    // todo fix this dependency:
    yield put(setRoleAction(res.role.code))

    yield put(fetchRequest("work_sessions/current", {
      method: "GET"
    }, GET_CURRENT_WORK_SESSION))

    yield put({type: INIT_STAT_PUSH, userId: logRes.user_id})
  }
  else{
    // yield put(setRoleAction("device"))
  }
}

function* resetRoleAndLocalStorage() {
  yield put(setRoleAction())
  yield call(logout)
}

function* pushError(){
  yield put(setWebPushReadyStatusAction("PROBLEM"))
  yield put(warningMessageAction("webpush_problem"))
}

function* pushSucces(){
  yield put(setWebPushReadyStatusAction("READY"))
}

function* initPushTicket(action: ISetWorkSessionAction) {
  yield fork(pushTicket, action.workSessionId, TypeEssence.USER)
}

function* initPushStatistics({ userId }: IInitStatPush) {
  yield fork(pushStatistics, userId)
}

function* getCurrentWorkSession(action: any) {
  const existedSession: TCurrentSessionAnswer = action?.response
  let workSessionID: string
  if (existedSession) {
    workSessionID = existedSession.id
    yield put(setWorkSessionAction(workSessionID))
  }
}

export function* RootAppSaga() {
  yield takeEvery(PUSH_MANAGER_ERROR, pushError)
  yield takeEvery(PUSH_MANAGER_SUCCESS, pushSucces)
  yield takeEvery(LOGIN_ACTION_TYPE, login)
  yield takeEvery(LOGOUT_ACTION_TYPE, resetRoleAndLocalStorage)
  yield takeEvery(WORK_SESSION_SET, initPushTicket)
  yield takeEvery(INIT_STAT_PUSH, initPushStatistics)
  yield takeEvery(GET_CURRENT_WORK_SESSION, getCurrentWorkSession)
  yield takeEvery(UPDATE_TOKEN_CENTRIFUGO, updateToken)
  yield all([rootPolicySaga()])
}
