import { BaseAttribute, BaseEntity, buildStore, buildListContainer, buidlRemoteSyncContainer } from "../features/entity"
import { EntityId } from "../features/entity/type"

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name')
]

export class Tenant extends BaseEntity{
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Tenant"
  display_name: string
  lang: string
  constructor(display_name:string="", lang: string="", id: EntityId = "-1"){
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
    this.lang = lang
  }
}

export const TenantReducer = buildStore(Tenant)
export const RemoteSyncTenantContanier = buidlRemoteSyncContainer(Tenant)
export const ListTenantContanier = buildListContainer(Tenant)

