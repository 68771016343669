import React from 'react'
import arrowUp from '../../../../assets/arrow_up.svg'
import arrowDown from '../../../../assets/arrow_down.svg'
import { Button, Popover } from '@material-ui/core'

type Props = {
  children: React.ReactElement
}

export default ({children}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return <>
    <Button
      aria-controls="fade-menu"
      aria-haspopup="true"
      variant="outlined"
      qa-tag="mq-arrow-button"
      style={{ minWidth: 29, minHeight: 29, width: 29 }}
      onClick={handleClick}>
      <img src={open ? arrowUp : arrowDown} alt="arrow" />
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 35,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClick={handleClose}
      className="select-popover"
    >
      {children}
    </Popover>
  </>
}