import { createStore, combineReducers, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import createSagaMiddleware from 'redux-saga'

import {CityReducer} from '../models/city'
import {TenantReducer} from '../models/tenant'
import {HubReducer} from '../models/hub'
import {LineReducer, LineReducerAdditional} from '../models/line'
import {DeskReducer} from '../models/desk'
import {TicketTemplateReducer} from '../models/ticket_template'
import {FieldReducer} from '../models/field'
import { UserReducer } from '../models/user'
import {TicketReducer} from '../models/ticket'
import authReducer  from './pages/authentication/reducers/store'
import expertPageReducer from "./pages/expert/store"
import { RoleReducer } from "../models/role"
import { PolicyReducer } from "../features/policy"
import { RootAppSaga } from "./root_saga"
import { TimeSlotReducer } from "../models/time_slot"
import { DeviceReducer } from "../models/device"
import { DevicePageStore } from "./pages/device"
import MenuStore from '../shared/components/mini_drawer/store'
import ValidationReducer from '../shared/validation/validation_store'
import CommonStore from './pages/shared/common_store'
import { StatisticReducer } from "./pages/statistics"
import { AdminDashBoardReducer, ExpertDashBoardReducer } from "./pages/dashboard"
import { TicTagReducer } from "../models/tic_tag"
import {LanguagesReducer} from "../localizations/store";
import {ModesReducer} from "../models/line/mode_store";
import {CommentReducer} from '../models/comment'
import {SMSReducer} from "../models/sms";

const rootReducer = combineReducers({
  languages: LanguagesReducer,
  modes: ModesReducer,
  cities: CityReducer,
  tenants: TenantReducer,
  hubs: HubReducer,
  lines: LineReducer,
  linesAdditional: LineReducerAdditional,
  desks: DeskReducer,
  ticket_templates: TicketTemplateReducer,
  fields: FieldReducer,
  users: UserReducer,
  roles: RoleReducer,
  time_slots: TimeSlotReducer,
  devices: DeviceReducer,
  tickets: TicketReducer,
  sms_templates: SMSReducer,
  tags: TicTagReducer,
  authentication: authReducer,
  expertPage: expertPageReducer,
  devicePage: DevicePageStore,
  policy: PolicyReducer,
  statistics: StatisticReducer, 
  adminDashBoard: AdminDashBoardReducer,
  expertDashboard: ExpertDashBoardReducer,
  comments: CommentReducer,

  validation: ValidationReducer,

  common: CommonStore,
  menu: MenuStore
})

export type AppState = ReturnType < typeof rootReducer >

const sagaMiddleware = createSagaMiddleware()


export default function configureStore() {
  const middlewares = [sagaMiddleware]
  const middleWareEnhancer = applyMiddleware(...middlewares)
  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  )
  sagaMiddleware.run(RootAppSaga)
  return store
}