import { Box, Button, Container, List, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MiniDrawer from '../../../shared/components/mini_drawer'
import { MainMenu } from '../shared/main_menu'
import { useLanguage } from './lang_hook'

const langs = {
  "ru": "russian",
  "en": "english",
  "am": "armenian"
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    display: "flex",
    flexDirection: "column"
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: 15,
    margin: "10px 0 0 0",
    paddingLeft: 0,
    display: "flex",
    flexGrow: 1,
    maxWidth: "100%"
  },
  appBarSpacer: theme.mixins.toolbar,

  paper: {
    marginTop: theme.spacing(0),
    padding: 24,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }
}))


export const LanguagePage = () => {
  const classes = useStyles()
  const [currentLang, setCurrentLang] = useLanguage()
  const { t } = useTranslation()
  const clickFactory = (key: string) => () => setCurrentLang(key);
  const menu = <List>
    <MainMenu />
  </List>
  return <MiniDrawer
    headerTitle={t("lang")}
    menu={menu}>
    <main className={classes.content + " info-window"}>
      <Box className="title-page-margin" >
        <Typography variant="h5" className="title-page">
          {t("Please, choose your language")}
        </Typography>
      </Box>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <Box marginTop={1}>
            <Box display="flex" flexWrap="wrap">
              {Object.entries(langs).map(([key, val]) => <Button
                  key={key}
                  variant="text"
                  className={`select-desk desk-button ${key == currentLang ? 'primary-button' : 'select-button'}`}
                  onClick={clickFactory(key)}>{t(val)}</Button>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </main>
  </MiniDrawer>
}