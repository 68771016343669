import { buildStore, buildCreateContainer, buidlRemoteSyncContainer } from "../../features/entity"
import { Field } from "./model"
import Table  from "./table"

export { Field } from "./model"
export const FieldReducer = buildStore(Field)
export const RemoteSyncFieldContainer = buidlRemoteSyncContainer(Field)
export const ListFieldContanier = Table
export const CreateFieldContanier = buildCreateContainer(Field, () => new Field())

