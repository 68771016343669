import { makeStyles } from "@material-ui/core"
import { Options } from "material-table"
import { EntityId } from "../../../../features/entity/type"
import { AppState } from "../../../store"
import { User } from "../../../../models/user"
import { Hub } from "../../../../models/hub"
import moment from "moment"
import {useEffect, useRef} from "react";

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    display: "flex",
    flexDirection: "column",
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: 15,
    paddingLeft: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    maxWidth: "100%"
  },
  appBarSpacer: theme.mixins.toolbar,
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 150
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(0),
    padding: 24,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  }
}))

export const bildUrl = (url: string, params: any): string => {
  // const u = new URL(url)
  // Object.keys(params).forEach((k) => u.searchParams.append(k, params[k]))
  // console.log(u.toString())
  const param = new URLSearchParams(params)
  return url + "?" + param.toString()
}

export const tableOptions: Options<any> = {
  pageSize: 50,
  emptyRowsWhenPaging: false,
  draggable: true,
  toolbar: false,
  searchFieldAlignment: "left",
  actionsColumnIndex: -1,
  headerStyle: {
    color: "#858585",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal"
  }
}

interface ICittyId {
  city_id: EntityId
}

export function cityFilter<T extends ICittyId>(collection: T[], cityIds: EntityId[]) {
  return collection.filter(e => cityIds.includes(e.city_id))
}

export function containsIds(where: string[], what: string[]){
  let result = []
  for(var i=0; i<what.length; i++){
    if(where.indexOf(what[i]) != -1)
      result.push(what[i])
  }
  return result
}

export function userSelector({ users, hubs }: AppState) {
  return Object.keys(users.entities).map(k => ({
    id: k,
    display_name: (users.entities[k] as User).name,
    city_id: (hubs.entities[(users.entities[k] as User).hub_id] as Hub)?.city_id,
    role: (users.entities[k] as User).role
  }))
}

export function timeHHMM(date: any) {
  if (!date)
    return ""
  return moment(date).format("HH:mm")
}

export function timeDDHHMM(date: any) {
  if (!date)
    return ""
  return moment(date).format("DD.MM.YY HH:mm")
}

export function loadFilterMultitipleFromLocalStorage(type: string, name: string): EntityId[] {
    const item = localStorage.getItem(type + '_' + name)
    if (item)
      return JSON.parse(item)
    else
      return []
}

export function loadFilterFromLocalStorage(type: string, name: string): string {
  const item = localStorage.getItem(type + '_' + name)
  if (item)
    return JSON.parse(item)
  else
    return ""
}

export function loadFilterToLocalStorage(type: string, name: string, value: any) {
  localStorage.setItem(type + '_' + name, JSON.stringify(value))
}

export enum TypeFilter {
  Expert = 'expert',
  History_experts = 'history_experts',
  History_hubs = 'history_hubs',
  Tickets = 'tickets',
  Tickets_created_by_expert = '',
  Worksession_expert = 'worksession_expert',
  Worksession_hubs = 'worksession_hubs',
  Dashboard = 'dashboard',
  Ticket = 'ticket'
}

export enum NameFilter {
  City = 'city',
  Line = 'line',
  Hub = 'hub',
  User = 'user',
  Tag = 'tag',
  Loyality = 'loyality'
}

export function removeEmpty(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


