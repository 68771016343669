import {Line} from "./line";

export const SUCCESS_ACCESS_FORWARD_LINES = "SUCCESS_ACCESS_FORWARD_LINES"

type StateLineAdditional = {
  forwardLines: Line[]
}

const initState: StateLineAdditional = {
  forwardLines: []
}

export function LineAdditionalReducer(state: StateLineAdditional = initState, action: any): StateLineAdditional {
  switch (action.type) {
    case SUCCESS_ACCESS_FORWARD_LINES:
      return {
        ...state,
        forwardLines: action.payload
      }

    default:
      return {...state}
  }
}