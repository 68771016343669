import React, {forwardRef, useRef} from "react"

import { Field } from "./model"
import { AppState } from "../../config/store"
import { EntityId } from "../../features/entity/type"
import { connect, ConnectedProps } from "react-redux"
import { updateFieldOrder } from './saga'
import { buildMapState, buildMapDispatch } from "../../features/entity/containers/list"
import { arrayMove } from 'react-sortable-hoc'
import MaterialTable, {Column, Icons} from "material-table"
import { IconButton, Box, TextField, Container } from "@material-ui/core"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { TicketTemplate } from "../ticket_template"
import { BaseEntity, CPagination } from "../../features/entity"
import { useTranslation } from "react-i18next"
import {getArrowTable, getCancelTable, getDoneTable, getEditTable, getTrashTable} from "../../assets/icons";

interface IOwnProps {
  ticket_template_id: EntityId
  tabs?: React.ReactElement
}


const mapStateToProps = (store: AppState, ownProps: IOwnProps) => {
  const res = buildMapState(Field)(store, { editable: true })
  if (ownProps.ticket_template_id)
    res.filtered = res.filtered.filter((fl) => (fl as Field).ticket_template_id === ownProps.ticket_template_id)
  res.filtered = res.filtered.sort((f1, f2) => (f1 as Field).position - (f2 as Field).position)
  return {
    ...res,
    ...ownProps
  }
}
const mapDspToProp = {
  ...buildMapDispatch(Field),
  onOrderChange: updateFieldOrder,
}

const connector = connect(mapStateToProps, mapDspToProp)

type PropsFromRedux = ConnectedProps<typeof connector>

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const FieldTable = (props: PropsFromRedux) => {
  const excludeField = ['position', 'ticket_template_id', 'kind']
  let title = "Unknown template"
  const refTable = useRef<MaterialTable<BaseEntity>>(null)
  const { t } = useTranslation()
  const changeS = (query: string) => {
    //@ts-ignore
    refTable.current!.dataManager.changeSearchText(query)
    //@ts-ignore
    refTable.current!.onSearchChangeDebounce(query)
  }
  if (props.ticket_template_id) {
    const template = props.additionals.ticket_templates.entities[props.ticket_template_id] as TicketTemplate
    if (template)
      title = template.display_name
  }
  let columns: Column<Field>[] = (props.attributes.filter(a => !excludeField.includes(a.key)).map(att => att.buildColumn(props.additionals)).filter(notEmpty) as any)
  const feildCount = props.filtered.length
  const moveField = (row: Field, direction: number) => {
    if (props.ticket_template_id) {
      const oldIndex = row.position
      const newIndex = row.position + direction
      const fld = arrayMove(props.filtered, oldIndex, newIndex).map(f => f.id)
      props.onOrderChange(props.ticket_template_id, fld)
    }
  }

  const onAddClick = () => {
    const table: any = refTable.current!
    table.dataManager.changeRowEditing()
    table.setState({
      ...table.dataManager.getRenderState(),
      showAddRow: !table.state.showAddRow,
    })
  }
  columns.push({
    field: "kind",
    title: "kind",
    initialEditValue: "string",
    lookup: {
      'integer': 'integer',
      'string': 'string',
      'select': 'select',
      "multiselect": "multiselect",
      "date": "date"
    },
  })
  columns.push({
    field: "position",
    title: "order",
    editable: 'never',
    render: (row: Field) => {
      if (!row)
        return <></>
      return <>
        {row.position > 0 ? <IconButton onClick={() => moveField(row, -1)}>
          <KeyboardArrowUpIcon />
        </IconButton>
          : null}
        {row.position < feildCount - 1 ? <IconButton onClick={() => moveField(row, 1)}>
          <KeyboardArrowDownIcon />
        </IconButton>
          : null}
      </>
    }
  })

  const edit = getEditTable()
  const trash = getTrashTable()
  const done = getDoneTable()
  const cancel = getCancelTable()

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref)),
    Check: forwardRef(() => done),
    Clear: forwardRef(() => cancel),
    Delete: forwardRef(() => trash),
    Edit: forwardRef(() => edit)
  }

  return <>
    <Box display="flex" flexDirection="row" marginTop={3} marginBottom={2} minWidth={1000}>
      <Box marginTop={1}>
        {props.tabs ? props.tabs : null}
      </Box>
      <Box flexGrow={1} />
      <TextField
        onChange={(e) => changeS(e.target.value)}
        placeholder={t("search")}
        className="mtable search-field" />
    </Box>
    <Container maxWidth="lg" className="mtable container-table">
      <MaterialTable
        icons={tableIcons}
        tableRef={refTable}
        title={title}
        columns={columns}
        options={{
          pageSize: 50,
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1,
        }}
        localization={{ header: { actions: "" } }}
        components={{
          Pagination: (props) => <CPagination {...props} onAddClick={onAddClick} />,
        }}
        data={props.filtered as Field[]}
        editable={{
          onRowAdd: (newData) => {
            newData.ticket_template_id = props.ticket_template_id
            newData.position = feildCount
            props.onCreate(newData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(null)
              }, 100)
            })
          },
          onRowUpdate: (newData) => {
            props.onUpdate(newData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(null)
              }, 100);
            })
          },
          onRowDelete: (oldData) => {
            props.onDelete(oldData)
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(null)
              }, 100);
            })
          },
        }}
      />
    </Container>
  </>
}


const RawTable = connector(FieldTable)

export default (props: IOwnProps) => {
  return <>
    <RawTable {...props} />
  </>
}