import React from 'react'
import { AppState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { Loading } from '../loading'
import { userSelectModeAction, userInputPhoneAction, MOVE_TO_MAIN, MOVE_RETRY, goBackAction, cancleAction, repeatNumberAction } from '../type'
import { Button, TextField, makeStyles, CssBaseline, Box, Typography } from '@material-ui/core'
import TimeSlotPicker from "./time_slot_picker"
import ClientData from "./client_data"
import LineData from "./line_data"
import LoyaltyData from "./loyalty_data"
import arrow from '../../../../assets/arrow.png'
import SButton from './button'
import { zeroForm } from '../../../../shared/helper'
import { TimeSlot } from '../../../../models/time_slot'
import { useTranslation } from "react-i18next"
import {errorFieldColor, terminalTextColor, textColor} from "../../../../styles/variables";

const mapState = ({ devicePage, time_slots }: AppState) => {
  return {
    devicePage,
    time_slots
  }
}

const mapDispatch = {
  selectMode: userSelectModeAction,
  inputPhone: userInputPhoneAction,
  goBack: goBackAction,
  cancel: cancleAction,
  repeatePhone: repeatNumberAction,

  moveToMain: () => ({ type: MOVE_TO_MAIN }),
  moveRetry: () => ({ type: MOVE_RETRY })
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export const TerminalRouter = connector((props: PropsFromRedux) => {
  const { devicePage, selectMode, inputPhone, goBack } = props
  const { t } = useTranslation()
  const isNoPlate = devicePage.device?.kind === "terminal"

  switch (devicePage.page) {
    case "LOADING":
      return <Loading />

    case "TERMINAL_MAIN":
      return <>
        <Box className="head-logo">
          <Typography variant="h3" >{t('electronic queue enrollment')}</Typography>
        </Box>
        <Box flexGrow={1} />
        <SButton variant={"filled"}
          onClick={() => selectMode("WITHOUT-PRE-ENTRIES")}>
          <Box qa-tag="check-in-btn">{t('to get in line')}</Box>
        </SButton>
        <SButton
          onClick={() => selectMode("PRE-ENTRIES")}>
          <Box qa-tag="pre-entry-btn">{t('sign up at another time')}</Box>
        </SButton>
      </>

    case "TERMINAL_CHECK_IN":
      let phone = ""
      return <>
        Ceck in <br />
      input phone
        <TextField variant="outlined" onChange={(e) => phone = e.target.value} />
        <Button onClick={() => inputPhone(phone)}>submit</Button>
      </>

    case "TERMINAL_SELECT_QUEUE":
      return <>
        <img src={arrow} className="back-button" onClick={goBack} alt={"back"} />
        <LineData /></>

    case "TERMINAL_CLIENT_DATA":
      return <>
        <img src={arrow} className="back-button" onClick={goBack} alt={"back"} />
        <ClientData /></>

    case "TERMINAL_LOYALTY_PAGE":
      return <>
        <img src={arrow} className="back-button" onClick={goBack} alt={"back"} />
        <LoyaltyData /></>

    case "TERMINAL_TICKET_PRINT":
      return <>
        <Typography className="page-title" variant="h2"><b>{t('hello!')}</b></Typography>
        <Typography className="terminal-page">{t('you have joined the queue')} </Typography>
        <Typography variant="h1" className="ticket-number"><b qa-tag="ticket-number">{props.devicePage.ticket!.ticket_no}</b></Typography>
        { isNoPlate ?
          <Typography className="terminal-page">{t('take the ticket')}</Typography>
          : null
        }
      </>

    case "TERMINAL_ERORR":
      return <>
        <img src={arrow} className="back-button" onClick={goBack} alt={"back"} />
        <Typography className="page-title" variant="h2"><b>
          {t('Repeated input of phone!')}</b></Typography>
        <Typography className="terminal-page error">{t(devicePage.errorMessag!)}</Typography>
        <Box flexGrow={1} />
        <SButton variant={"filled"} size="normal"
          onClick={props.repeatePhone}>
          <Box>{t('Input other number')}</Box>
        </SButton>
        <SButton size="normal"
          onClick={props.cancel}>
          <Box>{t('Cancel')}</Box>
        </SButton>
      </>

    case "TERMINAL_RESULT_OPERATION":
      const timeSlot = props.time_slots.entities[props.devicePage.timeSlotId!] as TimeSlot
      return <>
        <Typography className="page-title" variant="h2"><b>{t('thank you!')}</b></Typography>
        <Typography className="terminal-page">{t('you have successfully signed up for the driver center')}</Typography>
        <Box height={65} />
        <Typography className="terminal-page">{t('address')}: <b>{props.devicePage.hub!.address}</b></Typography>
        <Typography className="terminal-page">
          {t('date time')}: <b>{props.devicePage.selected_day.format("DD.MM.YYYY")},
          {' '}{t('from')} {zeroForm(timeSlot.since_hour)}:{zeroForm(timeSlot.since_hour)} {t('to')}
          {' '}{zeroForm(timeSlot.till_hour)}:{zeroForm(timeSlot.till_min)}</b>
        </Typography>
      </>

    case "TERMINAL_SLOTS":
      return <>
        <img src={arrow} className="back-button" onClick={goBack} alt={"back"} />
        <TimeSlotPicker />
      </>
  }
  return <> uknown terminal page </>
})

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: "100%",
    "& > .terminal-page": {
      fontSize: "28px",
      color: terminalTextColor,
      marginTop: 20,

      "& > b": {
        color: textColor
      }
    },
    "& > .error.terminal-page": {

      color: errorFieldColor
    }
  },
}))

export default () => {
  const classes = useStyles()
  return <>
    <CssBaseline />
    <div className={classes.paper}>
      <TerminalRouter />
    </div>
  </>
}
