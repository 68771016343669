
export const VALIDATION_FIELDS = "VALIDATION_FIELDS"
export const CLEAR_VALIDATION_FIELDS = "CLEAR_VALIDATION_FIELDS"

export interface IValidationAction {
  type: typeof VALIDATION_FIELDS
  page: string
  errorExpertData?: IErrorExpertData[]
  errorClientData: IValidationClientData
  errorAdditionalData?: IValidationAdditionalData
}

export interface IClearValidationAction {
  type: typeof CLEAR_VALIDATION_FIELDS
}

export const validationFieldsAction = (page: string,
                                       errorClientData: IValidationClientData,
                                       errorExpertData?: IErrorExpertData[],
                                       errorAdditionalData?: IValidationAdditionalData): IValidationAction => {
  return {
    type: VALIDATION_FIELDS,
    page,
    errorExpertData,
    errorClientData,
    errorAdditionalData
  }
}

export const clearValidationFieldsAction = (): IClearValidationAction => {
  return {
    type: CLEAR_VALIDATION_FIELDS
  }
}

export type ValidationAction = IValidationAction | IClearValidationAction

interface IObjectKeys {
  [key: string]: boolean;
}

export interface IValidationClientData extends IObjectKeys{
  phone: boolean
  first_name: boolean
  last_name: boolean
}

export interface IErrorExpertData {
  id: string,
  error: boolean
}


export interface IValidationAdditionalData extends IObjectKeys {
  errorLineId: boolean,
  errorHubId: boolean
}

export type ValidationState = {
  page: string
  errorsClientData: IValidationClientData
  errorsExpertData?: IErrorExpertData[]
  errorsAdditionalData?: IValidationAdditionalData
}

const menuInitialState: ValidationState = {
  page: "",
  errorsClientData: {phone: false, first_name: false, last_name: false},
  errorsExpertData: [{id: "-1", error: false}],
  errorsAdditionalData: {errorHubId: false, errorLineId: false}
}

export default (state: ValidationState = menuInitialState, action: ValidationAction): ValidationState => {

  switch (action.type) {

    case VALIDATION_FIELDS:
      return {
        ...state,
        page: action.page,
        errorsClientData: action.errorClientData,
        errorsExpertData: action.errorExpertData,
        errorsAdditionalData: action.errorAdditionalData
      }   

    case CLEAR_VALIDATION_FIELDS:
      return {
        ...state,
        page: menuInitialState.page,
        errorsClientData: menuInitialState.errorsClientData,
        errorsExpertData: menuInitialState.errorsExpertData,
        errorsAdditionalData: menuInitialState.errorsAdditionalData
      }

    default:
      return state
  }
}