import React, {useState, useEffect, forwardRef} from 'react'
import { Container, Paper, Typography, Box, Divider } from '@material-ui/core'
import { RemoteSyncCityContainer, City } from '../../../../models/city'
import { RemoteSyncHubContainer, Hub } from '../../../../models/hub'
import { RemoteSyncLineContainer, Line } from '../../../../models/line'
import { RemoteSyncDeskContainer } from '../../../../models/desk'
import { AppState } from '../../../store'
import { useSelector, useDispatch } from 'react-redux'
import { EntityId } from '../../../../features/entity/type'
import MultipleField from '../components/multiple'
import DatePicker from '../../../../config/pages/statistics/components/daterange'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { RemoteSyncUserContainer } from '../../../../models/user'
import MaterialTable, {Icons} from 'material-table'
import CPagination from '../../../../features/entity/components/pagination'
import { fetchRequest } from '../../../../features/entity/store/actions'
import {endLoadReportAction, IHubsReportItemp, loadReportAction, TypeStatistics} from '../type'
import {
  useStyles,
  bildUrl,
  tableOptions,
  cityFilter,
  timeHHMM,
  loadFilterMultitipleFromLocalStorage, TypeFilter, NameFilter, loadFilterToLocalStorage, containsIds
} from './helper'
import {DateRange} from "@material-ui/pickers";
import {getHoursWork} from "../../dashboard/helper";
import {getArrowTable, getDownloadIcon} from "../../../../assets/icons";

export default () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { cities, hubs, lines } = useSelector((state: AppState) => ({
    cities: Object.keys(state.cities.entities).map(k => state.cities.entities[k] as City),
    hubs: Object.keys(state.hubs.entities).map(k => state.hubs.entities[k] as Hub),
    lines: Object.keys(state.lines.entities).map(k => state.lines.entities[k] as Line)
  }))

  const isLoading = useSelector((state: AppState) => state.statistics.isLoading)

  const [data, setData] = useState<IHubsReportItemp[]>([])

  const dataNew = useSelector((state: AppState) =>
    state.statistics.taskId == state.statistics.data?.task_id ? state.statistics.data.reports : [])

  useEffect(() => {
    setData(dataNew.map( tic => ({...tic, client_fio: `${tic.first_name} ${tic.last_name}`})))
  }, [dataNew.length])

  const cityIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Tickets, NameFilter.City)
  const lineIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Tickets, NameFilter.Line)
  const hubIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Tickets, NameFilter.Hub)

  const [cityIds, setCityIds] = useState<EntityId[]>(cityIdsLS)
  const [lineIds, setLineIds] = useState<EntityId[]>(lineIdsLS)
  const [hubIds, setHubIds] = useState<EntityId[]>(hubIdsLS)

  const [date, setDate] = useState<DateRange<Date>>([new Date(), new Date()])

  const dispatch = useDispatch()

  const getIds = () => {
    let hubsForCity = cityFilter(hubs, cityIds).map(e => e.id)
    let hubIdsForCity = containsIds(hubIds, hubsForCity)
    setHubIds(hubIdsForCity)
    return hubIdsForCity
  }

  const valideInputs = lineIds.length > 0 && hubIds.length > 0 && hubs.length > 0
  useEffect(() => {
    let hubIdsForCity: string[] = []
    if (cityIds.length > 0 && hubIds.length > 0 && hubs.length > 0)
      hubIdsForCity = getIds()
    if (valideInputs && hubIdsForCity.length > 0)
      dispatch(loadReportAction(TypeStatistics.TICKETS, {
        line_ids: lineIds,
        hub_ids: hubIdsForCity,
        since: moment(date[0]).format("YYYY-M-D"),
        till: moment(date[1]).format("YYYY-M-D")
      }))
    else
      dispatch(endLoadReportAction([], false))
  }, [cityIds.length, lineIds.length, hubIds.length, date[0], date[1], hubs.length])
  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Tickets, NameFilter.City, cityIds)
  }, [cityIds.length])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Tickets, NameFilter.Line, lineIds)
  }, [lineIds.length])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Tickets, NameFilter.Hub, hubIds)
  }, [hubIds.length])

  const columns = [
    {
      field: "client_fio",
      title: t("client")
    },
    {
      field: "user_name",
      title: t("user_id")
    },
    {
      title: t("date"),
      field: "created_at",
      render: (rowData:any) => <>{moment(rowData.created_at).format("DD.MM.YY")}</>
    },
    {
      title: t("created_at"),
      field: "created_at",
      render: (rowData:any) => <>{timeHHMM(rowData.created_at)}</>
    },
    {
      title: t("started_at"),
      field: "started_at",
      render: (rowData:any) => <>{timeHHMM(rowData.started_at)}</>
    },
    {
      title: t("finished_at"),
      field: "finished_at",
      render: (rowData:any) => <>{timeHHMM(rowData.finished_at)}</>
    },
    {
      title: t("wait_time"),
      field: "wait_time",
      render: (rowData:any) => <>{ getHoursWork(rowData.wait_time, t('h'), t('min'), t('sec')) }</>
    },
    {
      title: t("interaction_time"),
      field: "interaction_time",
      render: (rowData:any) => <>{ getHoursWork(rowData.interaction_time, t('h'), t('min'), t('sec')) }</>
    },
    {
      field: "phone",
      title: t("phone")
    },
    {
      field: "line_display_name",
      title: t("line_id")
    }
  ]
  const xlsxClick = () => {
    dispatch(fetchRequest(bildUrl("statistics/tickets.xlsx", {
      line_ids: lineIds,
      hub_ids: hubIds,
      since: moment(date[0]).format("YYYY-M-D"),
      till: moment(date[1]).format("YYYY-M-D"),
      lang: i18n.language
    }), {
      method: "GET"
    }, "DOWNLOAD"))
  }

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref))
  }

  return <>

    <RemoteSyncCityContainer />
    <RemoteSyncHubContainer />
    <RemoteSyncLineContainer />
    <RemoteSyncDeskContainer />
    <RemoteSyncUserContainer />
    <Container maxWidth="lg" className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className="statistic-title">
          <b>{t("tickets")}</b>
        </Typography>
        <Box display="flex" flexDirection="row" className="box-select-statistics">
          <MultipleField setVal={setCityIds} val={cityIds} collection={cities} label={t("city")} />
          <MultipleField setVal={setLineIds} val={lineIds} collection={lines} label={t("line")} />
          <MultipleField setVal={setHubIds} val={hubIds} collection={cityFilter(hubs, cityIds)} label={t("hub")} />
          <DatePicker setVal={setDate} val={date} />
          <Box flexGrow={1} />
          <Box className="st-xlsx-button st-icon" onClick={() => { if (valideInputs) xlsxClick()} }>
            {getDownloadIcon(!valideInputs)}
          </Box>
        </Box>
        <Divider className="table-margin-statistics"/>
        <Box className="table-margin-statistics">
          <MaterialTable
            columns={columns}
            icons={tableIcons}
            data={data}
            options={tableOptions}
            isLoading={isLoading}
            components={{
              Pagination: (props) => <CPagination {...props} />,
            }}
          />
        </Box>
      </Paper>
    </Container>
  </>
}
