import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import { Alert } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import logo from '../../../../logo.svg'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        VoltMobi Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",

    '&:hover': {
      boxShadow: "none"
    },

    '&:active': {
      boxShadow: "none"
    }

  },
}));

interface IProps {
  onSignInClick: (email: string, password: string) => void
  errorMessage: string | null
}
type TEvent = React.ChangeEvent<{ value: unknown }>

export default function SignIn({ onSignInClick, errorMessage }: IProps) {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const onEmailChange = (e: TEvent) => setEmail(String(e.target.value))
  const onPasswordChange = (e: TEvent) => setPassword(String(e.target.value))
  const enterClick = (e: React.KeyboardEvent) =>{
    if(e.keyCode === 13){
      onSignInClick(email, password)
    }
  }
  return (
    <Box style={{width: "100%", height: "100%", position: "absolute"}}
         display="flex" flexDirection="column" justifyContent="center">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt="logo" style={{ height: 35, marginBottom: 45 }} />
          {errorMessage ?
            <Alert severity="error" qa-tag="sigin-alert">{errorMessage}</Alert>
            :
            null
          }
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onEmailChange}
              onKeyUp={enterClick}
              qa-tag="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              qa-tag="password"
              onChange={onPasswordChange}
              onKeyUp={enterClick}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              disableRipple={true}
              className={classes.submit}
              onClick={() => onSignInClick(email, password)}
              qa-tag="sigin-button"
            >
              Sign In
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}