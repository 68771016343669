import React from "react"

import {BaseAttribute} from ".."

import {EntityId, IEntityBase, TAditionalStore, TEntityClass, TMappedState} from "../type"
import {BaseEntity} from "../BaseEntity"
import EditSelect, {IOption} from "./components/edit_select"
import {Column} from "material-table"
import {TEditable} from "./base_attribute";

interface IBelongsConfig{
  key: EntityId
  parentClassStorageEntityKey: string
  displayAttribute: string
  editable?: TEditable
}

type TEvent = React.ChangeEvent<{ value: unknown }>

export class BelongsToAttribute<TEntity extends IEntityBase, TEClass extends TEntityClass<TEntity>> extends BaseAttribute{
  displayAttribute: string
  parentClassStorageEntityKey: string
  isEditable?: boolean
  constructor(config: IBelongsConfig){
    super(config.key)
    this.displayAttribute = config.displayAttribute
    this.parentClassStorageEntityKey = config.parentClassStorageEntityKey
    this.editable = config.editable
  }
  getDisplayValue(source:BaseEntity, state: TMappedState):string {
    const parentEntity: any = this.getParent(source, state)
    return parentEntity ? parentEntity[this.displayAttribute] : "no selected"
  }
  getParent(source: BaseEntity, state: TMappedState) {
    const parentId: EntityId = (source as any)[this.key]
    const parent = state.additionals[this.parentClassStorageEntityKey]
    return (parent.entities as any)[parentId]
  }
  editRender(onChange: (event: TEvent) => void, source: BaseEntity, state: TMappedState ) {
    const options: IOption[] = this.selectOptions(state.additionals);
    return <EditSelect key={this.key} options={options} 
      caption={this.key}
      value={this.getVal(source)} onChange={onChange} />
  }
  private selectOptions(additionals: TAditionalStore) {
    const options: IOption[] = [];
    const subState = additionals[this.parentClassStorageEntityKey].entities;
    for (const key in subState) {
      const entity: any = (subState as any)[key];
      options.push({
        value: entity.id,
        display_value: entity[this.displayAttribute]
      });
    }

    if (this.parentClassStorageEntityKey != "languages" && this.parentClassStorageEntityKey != "modes")
      options.push({
        value: "-1",
        display_value: "no selected"
      });
    return options;
  }

  mapState(appState:any):{[key:string]: BaseEntity}{
    return {
      [this.parentClassStorageEntityKey]: appState[this.parentClassStorageEntityKey]
    } as {[key:string]: BaseEntity}
  }

  buildColumn(additionals: TAditionalStore): Column<BaseEntity>{
    const options = this.selectOptions(additionals)
    const preparedOptions: {[key:string]: string}  = {}
    options.forEach( opt => preparedOptions[opt.value] = opt.display_value)
    return{
      title: this.key, field: this.key,
      lookup: preparedOptions, editable: this.editable || "always"
    }
  }
}