
export function getTicketStates() {
  return [
    {
      id: "0",
      display_name: "created"
    },
    {
      id: "1",
      display_name: "assigned"
    },
    {
      id: "2",
      display_name: "registered"
    },
    {
      id: "3",
      display_name: "enqueued"
    },
    {
      id: "4",
      display_name: "started"
    },
    {
      id: "5",
      display_name: "finished"
    },
    {
      id: "6",
      display_name: "cancelled"
    },
    {
      id: "7",
      display_name: "hold"
    }
  ]
}