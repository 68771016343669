import React  from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Box, Typography, Avatar } from '@material-ui/core'
import { MenuItem } from '../../../config/pages/shared/menu_item'
import theme from '../../theme'
import Alert from '../alert'
import Succeses from '../succeses'
import { useSelector } from 'react-redux'
import { AppState } from '../../../config/store'
import { User } from '../../../models/user'
import { useTranslation } from 'react-i18next'
import { RemoteSyncCityContainer } from '../../../models/city'
import { RemoteSyncHubContainer } from '../../../models/hub'
import { useLanguage } from '../../../config/pages/languge/lang_hook'
import {backgroundColor} from "../../../styles/variables";
import {getLogoutIcon} from "../../../assets/icons";

const drawerWidth = 262

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: "100%"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      background: backgroundColor,
      borderRight: 0,
      "& span ": {
        color: "#999",
        fontSize: 16,
        marginLeft: 2
      },
      "& ul": {
        overflow: "hidden",
      }
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
      overflowY: 'hidden'
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      overflowY: 'hidden',
      width: 82,
      [theme.breakpoints.up('sm')]: {
        width: 82,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      position: "absolute",
      right: 0,
      marginRight: -8,
      top: 54,
      zIndex: 100,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      "& > button span:first-child": {
        backgroundColor: "#fff",
        borderRadius: "50%",
      }
    },
    avatar: {
      width: 34,
      height: 34,
      borderRadius: "50%",
      margin: "24px 14px 12px 12px"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    bottomMenuItem: {
      overflow: "hidden",
      textDecoration: "none"
    },
    iconArrowButton: {
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: "50%"
    }
  }),
)

interface IMiniDrawerProps {
  children: React.ReactNode[] | React.ReactNode
  menu: React.ReactNode[] | React.ReactNode
  headerTitle: String
  open: boolean
  setOpen: (val: boolean) => void
}

export default function MiniDrawer(props: IMiniDrawerProps) {
  const classes = useStyles()
  const { children, menu, open, setOpen } = props
  const { t } = useTranslation()
  const handleDrawerSwitch = () => {
    setOpen(!open)
  }
  const { alerts, success, user } = useSelector((state: AppState) => {
    const user = state.authentication.current_user_id ? (state.users.entities[state.authentication.current_user_id] as User) : undefined;
    return {
      alerts: state.common.warnings,
      success: state.common.success,
      user
    }
  })

  useLanguage()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <RemoteSyncCityContainer />
          <RemoteSyncHubContainer />
          <Box display="flex" flexDirection="column" flexGrow={1} className="menu-drawer">
            <Box>
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerSwitch}>
                  {!open ? <ChevronRightIcon className={classes.iconArrowButton} />
                  : <ChevronLeftIcon className={classes.iconArrowButton} />}
                </IconButton>
              </div>
              <Box display="flex" overflow="hidden">
                <Avatar src={user?.avatar} className={classes.avatar} alt="avatar" qa-tag="avatar"/>
                <Box paddingTop={"22px"} marginRight={"16px"}>
                  <Typography className="user-description-role">{t(user?.role?.display_name || "unknown")}</Typography>
                  <Typography className="user-description-name">{user?.name}</Typography>
                </Box>
              </Box>
              {menu}
            </Box>
            <Box flexGrow={1} />
            <MenuItem className={classes.bottomMenuItem} href={`/logout`} title={t("logout")}>
              {getLogoutIcon("menu-icon")}
            </MenuItem>
          </Box>
        </Drawer>
        {children}
      </div>
      {alerts.map((m, i) => <Alert key={i} message={t(m)} />)}
      <Succeses message={success} />
    </ThemeProvider>
  )
}
