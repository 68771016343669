import { connect } from 'react-redux'

import { TEntityClass, EntityState, TMappedState, TAditionalStore, TStoreType } from '../type'
import { BaseEntity } from "../BaseEntity"
import CreateComponents from '../components/create'
import { getStorageEntityKey } from "../helpers/storage_key"

export function buildMapState<TEntity extends BaseEntity, TEntityState extends EntityState<TEntity>>
  (t: TEntityClass<TEntity>, deffaultBuilder: () => TEntity) {
  const className: string = getStorageEntityKey(t)
  return (appState: any): TMappedState & { buildTemplateEntity: () => TEntity } => {
    const targetState = appState[className]
    const aditional: TAditionalStore = {}
    const addit: TStoreType[] = t.attributes.map(attr => attr.mapState(appState))
    const filtered: BaseEntity[] = []
    const mapEntitiesById: TStoreType = (targetState as TEntityState).entities
    for (const key in mapEntitiesById) {
      filtered.push(mapEntitiesById[key])
    }
    return {
      attributes: t.attributes,
      mapEntitiesById,
      additionals: { ...Object.assign(aditional, ...addit) },
      filtered,
      buildTemplateEntity: deffaultBuilder,
      title: className,
      editable: true
    }
  }
}

function buildMapDispatch<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>
  (t: TEClass) {
  // const create = buildCreateThunk(t)
  return {
    onCreate: () => { }
  }
}

export default function buidlContainer<TEntity extends BaseEntity, TEClass extends TEntityClass<TEntity>>
  (t: TEClass, deffaultBuilder: () => TEntity) {
  const mapState = buildMapState(t, deffaultBuilder)
  const mapDispatch = buildMapDispatch(t)
  const connector = connect(mapState, mapDispatch)
  return connector(CreateComponents)
}