export const SUCCESS_MESSAGE = 'COMMON_SUCCESS_MESSAGE'
export const WARNING_MESSAGE = "COMMON_WARNING_MESSAGE"
export const PUSH_MANAGER_ERROR = "COMMON_PUSH_MANAGER_ERROR"
export const CLOSE_WARNING_MESSAGE = "COMMON_CLOSE_WARNING_MESSAGE"
export const CLOSE_SUCCESS_MESSAGE= "COMMON_CLOSE_SUCCESS_MESSAGE"
export const PUSH_MANAGER_SUCCESS = "COMMON_PUSH_MANAGER_SUCCESS"

interface IHelpReqDialogData {
  user: {
    name: string
    avatar: string
  }
  desk: {
    display_name: string
  }
}

export interface IPushManagerSuccessAction {
  type: typeof PUSH_MANAGER_SUCCESS
}

export interface ICloseWarningMessageAction {
  type: typeof CLOSE_WARNING_MESSAGE
  message: string
}

export interface IPushManagerErrorAction {
  type: typeof PUSH_MANAGER_ERROR
}

export interface IWarningMessageAction {
  type: typeof WARNING_MESSAGE
  message: string
}

export interface ICloseSuccessMessageAction {
  type: typeof CLOSE_SUCCESS_MESSAGE
}

export interface ISuccessMessageAction {
  type: typeof SUCCESS_MESSAGE
  message: string
}

export const closeWarningMessageAction = (message: string): ICloseWarningMessageAction => {
  return {
    type: CLOSE_WARNING_MESSAGE,
    message
  }
}

export const warningMessageAction = (message: string): IWarningMessageAction => {
  return {
    type: WARNING_MESSAGE,
    message
  }
}

export const closeSuccessMessageAction = (): ICloseSuccessMessageAction => {
  return {
    type: CLOSE_SUCCESS_MESSAGE
  }
}

export const successMessageAction = (message: string): ISuccessMessageAction => {
  return {
    type: SUCCESS_MESSAGE,
    message
  }
}

export const pushManagerErrorAction = (): IPushManagerErrorAction => {
  return {
    type: PUSH_MANAGER_ERROR
  }
}

export const ALERT_DIALOG = "CM_ALERT_DIALOG"

export interface IAlertDialogAction extends IHelpReqDialogData {
  type: typeof ALERT_DIALOG
}

export const alertDialogAction = (data: IHelpReqDialogData): IAlertDialogAction => {
  return {
    type: ALERT_DIALOG,
    ...data
  }
}

export const CLOSE_HELP_REQUEST_DIALOG = "CM_CLOSE_HELP_REQUEST_DIALOG"

export interface ICloseHelpRequestDialogAction {
  type: typeof CLOSE_HELP_REQUEST_DIALOG
}

export const closeHelpRequestDialogAction = (): ICloseHelpRequestDialogAction => {
  return {
    type: CLOSE_HELP_REQUEST_DIALOG
  }
}

type TCommonAction = IWarningMessageAction | IPushManagerErrorAction |
  ICloseWarningMessageAction | IPushManagerSuccessAction | IAlertDialogAction |
  ICloseHelpRequestDialogAction | ISuccessMessageAction | ICloseSuccessMessageAction

interface ICommonStoreState {
  success: string
  warnings: string[]
  helpRequestDialog?: IHelpReqDialogData
}

const commonStoreInitialState: ICommonStoreState = {
  success: "",
  warnings: [],
  helpRequestDialog: undefined
}

export default (state: ICommonStoreState = commonStoreInitialState, action: TCommonAction): ICommonStoreState => {
  switch (action.type) {

    case SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.message
      }

    case WARNING_MESSAGE:
      return {
        ...state,
        warnings: [...state.warnings, action.message]
      }

    case CLOSE_WARNING_MESSAGE:
      return {
        ...state,
        warnings: state.warnings.filter(m => m !== action.message)
      }

    case CLOSE_SUCCESS_MESSAGE:
      return {
        ...state,
        success: ""
      }

    case ALERT_DIALOG:
      return {
        ...state,
        helpRequestDialog: { ...action }
      }


    case CLOSE_HELP_REQUEST_DIALOG:
      return {
        ...state,
        helpRequestDialog: undefined
      }
  }
  return state
}