import React, {useEffect, useState} from 'react'
import {FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";

import {getCheckedFullIcon, getCheckedIcon, getUncheckedIcon} from "../../../../assets/icons";
import {arrayToString} from "../../../../shared/helper";

export interface ISelectItem {
  id: string
  display_name: string
}

type Props = {
  collection: ISelectItem[]
  val: string[]
  setVal: (v: string[]) => void
  label: string
  className?: string
  errorClassName?: string
}

export default (props: Props) => {

  const { t } = useTranslation()

  const [collection, setCollection] = useState(props.collection)

  useEffect(() => {
    setCollection(props.collection)
  }, [props.collection.length])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = (event.target.value as string[])
    if (newValue.indexOf("search") == -1) {
      if (newValue.indexOf("all") > -1) {
        props.setVal(props.collection.map(c=>c.id))
      }
      else if (newValue.indexOf("nothing") > -1) {
        props.setVal([])
      }
      else {
        props.setVal(newValue)
      }
    }
  }

  const handleSearch = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value

    if (value) {
      const collection = props.collection.filter(item => {
        return item.display_name.match(new RegExp(value, 'i'))
      })
      setCollection(collection)
    } else {
      setCollection(props.collection)
    }
  }

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const uncheckedIcon = getUncheckedIcon()
  const checkedIcon = getCheckedIcon()
  const checkedFullIcon = getCheckedFullIcon()

  const selectedAll = (a: ISelectItem[], b: string[]) => {
    return a.length == b.length;
  }

  return <FormControl className={`${props.className ? props.className : ''} st-select-field st-select-form`}>
    <InputLabel shrink id="select-cities-statistic-field"
      className="st-select-lable st-cities-lable">
      {props.label}
    </InputLabel>
    <Select
      labelId="select-cities-statistic-field"
      id="select-cities-statistic-field-label"
      value={props.val}
      onChange={handleChange}
      onClose={() => setCollection(props.collection)}
      multiple
      displayEmpty
      // input={<Input />}
      className={`${props.className ? props.className : ''} st-select-field st-cities-field ${props.errorClassName ? props.errorClassName : ''}`}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      renderValue={(selected) => {
        return arrayToString(selected as string[], props.collection)
      }}>
      <MenuItem value={"search"}
                onClickCapture={stopImmediatePropagation}
                onKeyDown={e => e.stopPropagation()}>
        <TextField
            placeholder={t('search')}
            onChange={handleSearch}
            onBlur={(event) => event.target.focus()}>
        </TextField>
      </MenuItem>
      <MenuItem value={"all"}>
        <ListItemText primary={<b>{t('select all')}</b>} />
        <Checkbox checked={selectedAll(collection, props.val)}
                  icon={uncheckedIcon}
                  checkedIcon={checkedFullIcon}
                  style={{padding: '0', marginLeft: '5px'}}/>
      </MenuItem>
      <MenuItem value={"nothing"}>
        <b>{t('select nothing')}</b>
      </MenuItem>
      {
        collection.length > 0
          ? <Divider style={{marginRight: '10px', marginLeft: '10px'}} />
          : null
      }
      {collection.map(c => <MenuItem key={c.id} value={c.id}>
        <ListItemText primary={c.display_name} />
        <Checkbox checked={!!props.val.find(val => val == c.id)}
                  icon={uncheckedIcon}
                  checkedIcon={checkedIcon}
                  style={{padding: '0', marginLeft: '5px'}}/>
      </MenuItem>
      )}
    </Select>
  </FormControl>
}