import { call, put, delay } from 'redux-saga/effects'
import { loginAction, ILoginRes, IDecodedToken } from './store'
import jwt from 'jsonwebtoken'
import { baseAPIUrl, saveToLocalStore } from './sagas'

export function* updatingToken(resAct: ILoginRes) {
  const url = baseAPIUrl() + "api/tokens/renew"
  let currentToken = resAct.token
  let expireInterval = resAct.exp*1000 - Number(new Date())
  const originalExpireInt = resAct.exp*1000 - resAct.nbf*1000
  if (expireInterval > originalExpireInt / 2) {
    yield delay(expireInterval / 2)
  }
  while (true) {
    const options = {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${currentToken}`
      }
    }
    const resp: Response = yield call(fetch, url, options)
    const { token: new_token } = yield call([resp, resp.json])
    currentToken = new_token
    const decode: IDecodedToken = yield call(jwt.decode, new_token)
    expireInterval = decode.exp*1000 - decode.nbf*1000
    yield call(saveToLocalStore, { ...decode, token: new_token })
    yield put(loginAction({ ...decode, token: new_token }))
    yield delay(expireInterval / 2)
  }
}
