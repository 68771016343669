import { BaseAttribute, TEvent } from "./base_attribute"
import { BaseEntity } from ".."
import { TAditionalStore } from "../type"
import { Column } from "material-table"

export class NumberAttribute extends BaseAttribute {
  
  setVal(source: any, val:any){
    source[this.key] = Number(val)
  }
  change<T extends BaseEntity>(source: T, event: TEvent): T {
    return Object.assign(Object.create(source), {
      [this.key]: Number(event.target.value)
    })
  }
  buildColumn(additionals: TAditionalStore): null | Column<BaseEntity>{
    return {
      title: this.key, field: this.key,
      initialEditValue:  1,  type: 'numeric'
    }
  }
}