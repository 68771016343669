import { RouteProps } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { Location } from "history"

export type HISTORY = ReturnType<typeof useHistory>
export const SET_URL_PATH = "POLICY_SET_URL_PATH"
export const UPDATE_HIST_SETTER = "POLICY_HISTORY_UPDATE_SETTER_ACTION"
export const REDIRECT_ACTION = "POLICY_REDIRECT_ACTION"
export const SET_ROLE_ACTION = "POLICY_SET_ROLE_ACTION"

export type TSubjectKey = string

export type TRole = String

export interface ISetUrlPathAction {
  type: typeof SET_URL_PATH
  routeProps: RouteProps
}

type TSaga = (routProps: RouteProps) => Generator
export interface ISagaRoute {
  roles?: TRole[]
  saga?: TSaga[]
}

export interface IPolicyRoute extends ISagaRoute {
  path: string
}


export interface IPolicySwitcher {
  resolve: (path: string) => ISagaRoute | null
  forbidden_path: string
  auth_path: string
}

export interface IPolicyRouetProps extends RouteProps {
  pageSaga?:  TSaga[]
  roles?: TRole[]
}

export interface IUserPermissionable {
  getRoles: () => TRole[]
}

export interface IBrowserHistoryGetAction {
  type: typeof UPDATE_HIST_SETTER
  brwHistory: HISTORY
}
export interface IRedirectAction {
  type: typeof REDIRECT_ACTION,
  redirectFrom: Location<unknown>,
  redirectTo: string
}

export interface ISetRole {
  type: typeof SET_ROLE_ACTION,
  role?: string
}

export type PolicyRouterActions = ISetUrlPathAction | IBrowserHistoryGetAction | IRedirectAction | ISetRole

export interface IPolicyRouterState {
  currentPath?: string 
  redirectFrom?: Location<unknown>
  currentRole?: string
}