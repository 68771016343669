import {Button} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import React from "react"
import { useDispatch } from "react-redux"
import { helpRequestAction } from "./store"

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const click = () => dispatch(helpRequestAction())
  return <Button variant="outlined"
                 className="alarm-button"
                 qa-tag="alarm-button"
                 onClick={click}>{t("Alarm button")}</Button>
}