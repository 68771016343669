import React from 'react'
import {Box, Grid} from '@material-ui/core'

import {Modal} from '../../../shared/components/modal'
import {Ticket} from "../../../models/ticket";
import {timeDDHHMM} from "../statistics/pages/helper";

type TProps = {
  ticket: Ticket,
  open: boolean,
  setOpen: (v: boolean) => void
}

export default ({ticket, open, setOpen}: TProps) => {
  return <>
    <Modal
      title={`${(ticket?.client_data?.last_name || "")} ${(ticket?.client_data?.first_name)}` }
      titleAdditional={""}
      open={open}
      setOpen={setOpen}
      className={"info-session-dialog comments-dialog"}>
      <>
        {
          ticket?.comments.map((comment, index) => {
            return <Grid
                    key={comment.id}
                    container
                    direction="row"
                    className={index === 0 ? "comments-dialog-content-first-item" : "comments-dialog-content"}>
                    <Grid
                      container
                      justify="space-between"
                      direction="row">
                      <Box className="comments-dialog-text-title">
                        {comment.user?.name}
                      </Box>
                      <Box className="comments-dialog-text-title">
                        {timeDDHHMM(comment.created_at)}
                      </Box>
                    </Grid>
                    <Box className="comments-dialog-text-message" style={{marginTop: 16}}>
                      {comment.message}
                    </Box>
                  </Grid>
          })
        }
      </>
    </Modal>
  </>
}