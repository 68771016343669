import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStore } from 'react-redux'
import { AppState } from '../../store'
import { Desk } from '../../../models/desk'
import { Line } from '../../../models/line'
import { useTranslation } from 'react-i18next'

export default () => {
  const state: AppState = useStore().getState()  
  const { t } = useTranslation()
  if(state.expertPage.status === "FIRST_SCREEN"){
    return <></>
  }
  const deskId = state.expertPage.selected_desk?.id
  if (deskId) {
    const desk = state.desks.entities[deskId] as Desk
    const line = state.lines.entities[desk.line_id] as Line
    return <>
      <Box className="desk-description expert-page" qa-tag="expert-desk-line">
        <Typography variant="body1"><b>{desk?.display_name}</b> — {line?.display_name}</Typography>
      </Box>
    </>
  }else{
    return <Box className="desk-description expert-page">
      <Typography variant="body1"><b>{t('Before the handling')}</b></Typography>
    </Box>
  }
}