import React, {useState, useEffect, forwardRef} from 'react'
import { Container, Paper, Typography, Box, Divider } from '@material-ui/core'
import { RemoteSyncHubContainer } from '../../../../models/hub'
import { AppState } from '../../../store'
import { useSelector, useDispatch } from 'react-redux'
import { EntityId } from '../../../../features/entity/type'
import MultipleField from '../components/multiple'
import DatePicker from '../../../../config/pages/statistics/components/daterange'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { RemoteSyncUserContainer } from '../../../../models/user'
import MaterialTable, {Icons} from 'material-table'
import CPagination from '../../../../features/entity/components/pagination'
import { fetchRequest } from '../../../../features/entity/store/actions'
import {endLoadReportAction, IHubsReportItemp, loadReportAction, TypeStatistics} from '../type'
import {
  useStyles,
  bildUrl,
  tableOptions,
  loadFilterMultitipleFromLocalStorage, TypeFilter, NameFilter, loadFilterToLocalStorage, userSelector
} from './helper'
import {DateRange} from "@material-ui/pickers";
import {getArrowTable, getDownloadIcon} from "../../../../assets/icons";

export default () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { users } = useSelector((state: AppState) => ({
    users: userSelector(state).filter((user: any) => user.role.code !== 'expert')
  }))

  const isLoading = useSelector((state: AppState) => state.statistics.isLoading)

  const [data, setData] = useState<IHubsReportItemp[]>([])

  const dataNew = useSelector((state: AppState) =>
    state.statistics.taskId == state.statistics.data?.task_id ? state.statistics.data.reports : [])

  useEffect(() => {
    setData(dataNew)
  }, [dataNew.length])

  const userIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Tickets_created_by_expert, NameFilter.User)

  const [userIds, setUserIds] = useState<EntityId[]>(userIdsLS)

  const [date, setDate] = useState<DateRange<Date>>([new Date(), new Date()])

  const dispatch = useDispatch()

  const valideInputs = userIds.length > 0
  useEffect(() => {
    if (userIds.length > 0)
      dispatch(loadReportAction(TypeStatistics.TICKETS_CREATED_BY_EXPERTS, {
        user_ids: userIds,
        since: moment(date[0]).format("YYYY-M-D"),
        till: moment(date[1]).format("YYYY-M-D")
      }))
    else
      dispatch(endLoadReportAction([], false))
  }, [userIds, date[0], date[1]])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Tickets_created_by_expert, NameFilter.User, userIds)
  }, [userIds])

  const columns = [
    {
      field: "user_name",
      title: t("user_id")
    },
    {
      field: "created_at",
      title: t("date"),
      render: (rowData:any) => <>{moment(rowData.created_at).format("DD.MM.YY")}</>
    },
    {
      field: "first_name",
      title: t("first_name"),
    },
    {
      field: "last_name",
      title: t("last_name"),
    },
    {
      field: "phone",
      title: t("phone")
    },
    {
      field: "line_display_name",
      title: t("line_id")
    },
    {
      field: "state",
      title: t("state"),
      render: (rowData:any) => <>{t(rowData.state)}</>
    },
    {
      field: "tag",
      title: t("tag")
    }
  ]
  const xlsxClick = () => {
    dispatch(fetchRequest(bildUrl("statistics/tickets_created_by_experts.xlsx", {
      user_ids: userIds,
      since: moment(date[0]).format("YYYY-M-D"),
      till: moment(date[1]).format("YYYY-M-D"),
      lang: i18n.language
    }), {
      method: "GET"
    }, "DOWNLOAD"))
  }

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref))
  }

  return <>
    <RemoteSyncHubContainer />
    <RemoteSyncUserContainer />
    <Container maxWidth="lg" className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className="statistic-title">
          <b>{t("tickets created by experts")}</b>
        </Typography>
        <Box display="flex" flexDirection="row" className="box-select-statistics">
          <MultipleField setVal={setUserIds} val={userIds} collection={users} label={t("user_id")} />
          <DatePicker setVal={setDate} val={date} />
          <Box flexGrow={1} />
          <Box className="st-xlsx-button st-icon" onClick={() => { if (valideInputs) xlsxClick()} }>
            {getDownloadIcon(!valideInputs)}
          </Box>
        </Box>
        <Divider className="table-margin-statistics"/>
        <Box className="table-margin-statistics">
          <MaterialTable
            columns={columns}
            icons={tableIcons}
            data={data}
            options={tableOptions}
            isLoading={isLoading}
            components={{
              Pagination: (props) => <CPagination {...props} />,
            }}
          />
        </Box>
      </Paper>
    </Container>
  </>
}