import React from "react"
import { IPolicyRouetProps, IPolicyRouterState } from "./type"
import { updateHistorySetter, setUrlPathAction } from "./actions"
import { Route, Redirect } from "react-router-dom"
import { connect, ConnectedProps } from "react-redux"


const mapStateProps = ({ policy }: { policy: IPolicyRouterState }, ownProps: IPolicyRouetProps) => {
  return {
    policy,
    ownProps
  }
}
const mapDispatch = {
  updateHistorySetter,
  setUrlPathAction,

}
const connector = connect(mapStateProps, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const Permited = connector((props: PropsFromRedux) => {
  if (!props.ownProps.roles) {
    props.setUrlPathAction(props.ownProps)
    return <>
      {props.ownProps.children}
    </>
  }

  if (props.policy.currentRole) {
    if (props.ownProps.roles.includes(props.policy.currentRole)) {
      props.setUrlPathAction(props.ownProps)
      return <>
        {props.ownProps.children}
      </>
    } else {
      return <Redirect to="/"></Redirect>
    }
  }

  return <Redirect to={{
      pathname: "/user",
      state: { from: props.ownProps.location }
    }}></Redirect>
})

export default (props: IPolicyRouetProps) => {
  if (props.render) {
    const oldRender = props.render
    const render: typeof props.render = (...args) => {
      const res = oldRender(...args)
      return <>
        <Permited {...props} >
          {res}
        </Permited>
      </>
    }
    return <Route {...{ ...props, render }} />
  }

  return <Route {...props}>
    <Permited {...props} >
      {props.children}
    </Permited>
  </Route>
}