import React from 'react'
import {Box, Popover} from '@material-ui/core'
import {getMenuIcon} from "../../assets/icons";

type Props = {
  children: React.ReactElement
}

export default ({children}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return <Box>
    <Box onClick={handleClick} style={{marginTop: 16}}>
      {getMenuIcon()}
    </Box>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 25,
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 115,
      }}
      onClick={handleClose}
      className="select-popover">
      {children}
    </Popover>
  </Box>
}