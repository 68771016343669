import { BaseAttribute, BaseEntity, buildStore, buildTableContainer, buidlRemoteSyncContainer } from "../features/entity"
import { EntityId } from "../features/entity/type"

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name')
]

export class TicTag extends BaseEntity{
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Tag"
  display_name: string
  tenant_id: EntityId = "-1"
  constructor(display_name:string="", id: EntityId = "-1"){
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
  }
}

export const TicTagReducer = buildStore(TicTag)
export const ListTicTagContanier = buildTableContainer(TicTag)
export const RemoteSyncTicTagContainer = buidlRemoteSyncContainer(TicTag)
