import React from 'react'
import { BaseEntity, BaseAttribute } from '../../features/entity'
import { EntityId } from '../../features/entity/type'

const attributes = [
  new BaseAttribute('template'),
  new BaseAttribute('link'),
  new BaseAttribute('sender'),
  new BaseAttribute('enabled', "never", true),
  new BaseAttribute('delay'),
  new BaseAttribute('city_ids'),
  new BaseAttribute('line_ids')
]

export class SMS extends BaseEntity {
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Sms_templates"
  template: string = ""
  link: string = ""
  sender: string = ""
  enabled: boolean = false
  delay: number = 0
  city_ids: EntityId[] = []
  line_ids: EntityId[] = []
  constructor(id: EntityId = "-1") {
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
  }
}