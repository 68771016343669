import React, {useState} from "react"
import {DateRange, DateRangePicker} from "@material-ui/pickers"
import {Box, InputLabel, TextField} from "@material-ui/core";
import DateFns from "@date-io/date-fns";
import {enGB, ru} from 'date-fns/esm/locale'
import { LocalizationProvider } from '@material-ui/pickers';
import {useTranslation} from "react-i18next";

type Props = {
  val: DateRange<Date>
  setVal: (date: DateRange<Date>) => void
}

export default ({val, setVal}: Props) => {

  const {t} = useTranslation()

  const lang = t('current lang')

  let locale = ru
  if (lang == "en") {
    locale = enGB
  }

  const [dateRange, setDateRange] = useState<DateRange<Date>>(val)

  const handleDateChange = (e: DateRange<Date>) => {
    setDateRange([e[0], e[1]])
    setVal([e[0] || new Date(), e[1] || new Date()]);
  }

  return <LocalizationProvider dateAdapter={DateFns} locale={locale}>
    <DateRangePicker
      className="date-popper"
      startText={t('start period')}
      endText={t('end period')}
      value={dateRange}
      onChange={handleDateChange}
      renderInput={(startProps, endProps) => (
        <React.Fragment>
          <Box className="date-range-text-field-box">
            <InputLabel shrink id="select-cities-statistic-field"
                        className="st-select-lable st-cities-lable"
                        style={{marginBottom: '6px'}}>
              {t('start period')}
            </InputLabel>
            <TextField className="date-range-text-field" inputProps={startProps.inputProps} />
          </Box>
          <Box className="date-range-text-field-box">
            <InputLabel shrink id="select-cities-statistic-field"
                        className="st-select-lable st-cities-lable"
                        style={{marginBottom: '6px'}}>
              {t('end period')}
            </InputLabel>
            <TextField className="date-range-text-field" inputProps={endProps.inputProps} />
          </Box>
        </React.Fragment>
      )}
    />
  </LocalizationProvider>
}