import {call, takeEvery, put, select} from "redux-saga/effects"
import {Ticket} from "../../../models/ticket";
import {Comment} from "../../../models/comment"

import {fetchM} from "../shared/fetchM";
import {
  clearValidationFieldsAction,
  validationFieldsAction
} from "../../../shared/validation/validation_store";
import {warningMessageAction} from "../shared/common_store";
import {validateAdditionalData, validateClientData} from "../../../shared/validation/saga";
import i18next from "i18next";
import {createCommentAction} from "../../../models/comment/saga";
import {AppState} from "../../store";

export const CREATE_TICKET = "CT_CREATE_TICKET"

export interface ICreateTicketAction {
  type: typeof CREATE_TICKET
  ticket: Ticket,
  comment: string
}

export const createTicketAction = (ticket: Ticket, comment: string): ICreateTicketAction => {
  return {
    type: CREATE_TICKET,
    ticket: ticket,
    comment: comment
  }
}

function* processCreateTicket({ticket, comment}: ICreateTicketAction) {
  const errorsClientData = yield validateClientData(ticket.client_data)
  const errorsAdditionalData = yield validateAdditionalData(ticket)

  if (Object.keys(errorsClientData).map(key => errorsClientData[key]).includes(true)
    || Object.keys(errorsAdditionalData).map(key => errorsClientData[key]).includes(true)) {
    yield put(validationFieldsAction("new_ticket", errorsClientData, undefined, errorsAdditionalData))
    yield put(warningMessageAction(i18next.t('error required fields')))
  } else {
    yield createTicket(ticket, comment)
    yield put(clearValidationFieldsAction())
  }
}

function* onTicketCreate(id: string){
  //@ts-ignore
  window.location = `/admin/tickets/${id}`
}

function* createTicket(ticket: Ticket, comment: string) {
  const response = yield call(fetchM, "tickets", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(ticket)
  })

  if (comment) {
    const currentUserId = yield select(({ authentication }: AppState) => authentication.current_user_id)

    const ticketHistories = yield call(fetchM, `tickets/${response.model.id}/changes`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const ticketHistory = ticketHistories[0]

    yield put(createCommentAction(
      response.model.id,
      Comment.buildFromRaw({
      message: comment,
      user_id: currentUserId,
      ticket_id: response.model.id,
      ticket_change_id: ticketHistory.id
    }, Comment)))
  }

  yield onTicketCreate(response.model.id)
}

export default function*(){
  yield takeEvery(CREATE_TICKET, processCreateTicket)
}