import { useSelector } from "react-redux";
import { User } from "../../../models/user";
import { AppState } from "../../store";


export function useCurrentUser(): User | null{
  let u_id: string | null
  return useSelector((state:AppState)=>
    ((u_id = state.authentication.current_user_id) ? 1 : null) 
    && state.users.entities[u_id!] as User)
}