import { Switch, SwitchProps } from "react-router-dom"
import { IPolicySwitcher, ISagaRoute, IPolicyRoute } from "./type"
import NoPerRoute from './no_permission_route'
import PRoute from './route'

type TChild = typeof PRoute | typeof NoPerRoute

export interface IPolicySwitcherPorps extends SwitchProps {
  children: TChild[]
}

function routeFromChild(child: TChild): IPolicyRoute | null {
  const props: any = (child as any).props
  if (props.path)
    return {
      path: props.path,
      saga: props.pageSaga,
      roles: props.roles
    }
  return null
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export default class extends Switch implements IPolicySwitcher {
  constructor(props: IPolicySwitcherPorps) {
    super(props)
    this.pathsMap = {}
    props.children.map(routeFromChild).filter(notEmpty).forEach(route => {
      this.pathsMap[route.path] = route
    })
    props.children.forEach(ch => {
      if ((ch as any).type === NoPerRoute) {
        this.forbidden_path = (ch as any).props.path
      }
    })
  }
  auth_path: string = "/user"
  resolve(path: string): ISagaRoute | null {
    if (this.pathsMap[path])
      return this.pathsMap[path]
    return null
  }
  forbidden_path: string = "/"
  private pathsMap: {
    [key: string]: IPolicyRoute
  }
}