import React, {forwardRef, useRef} from 'react'
import MaterialTable, {Icons} from 'material-table'
import { TicketTemplate } from './model'
import { Box, TextField, Container } from '@material-ui/core'
import { buildMapDispatch, filteredEntities } from '../../features/entity/containers/list'
import { AppState } from '../../config/store'
import { connect, ConnectedProps } from 'react-redux'
import { UPDATE_ENABLE_TT_ACTION } from './type'
import { IEntityBase } from '../../features/entity/type'
import { Link } from 'react-router-dom'
import { BaseEntity, CPagination } from '../../features/entity'
import { useTranslation } from 'react-i18next'
import Switcher from "./switcher"
import {getArrowTable, getCancelTable, getDoneTable, getEditTable, getTrashTable} from "../../assets/icons";


interface IOwnProps {
  tabs?: React.ReactElement
}

const mapStateToProps = (store: AppState, ownProps: IOwnProps) => {
  const filtered: TicketTemplate[] = (filteredEntities(store.ticket_templates.entities) as any)
  return {
    ...ownProps,
    filtered: filtered.map(t => t.toNoField()).sort((t1, t2) => t1.display_name > t2.display_name ? 1 : -1)
  }
}
const mapDspToProp = {
  ...buildMapDispatch(TicketTemplate),
  onEnabled: (template: IEntityBase) => ({
    type: UPDATE_ENABLE_TT_ACTION,
    ticketTemplate: template
  }),
}

const connector = connect(mapStateToProps, mapDspToProp)

type PropsFromRedux = ConnectedProps<typeof connector>

const FieldDetail = ({ tiket_template }: { tiket_template: TicketTemplate }) => {
  const { t } = useTranslation()
  return <Link to={"templates/" + tiket_template.id}>
    {t('Fields')}
  </Link>
}

export default connector((props: PropsFromRedux) => {
  const refTable = useRef<MaterialTable<BaseEntity>>(null)
  const { t } = useTranslation()
  const changeS = (query: string) => {
    //@ts-ignore
    refTable.current!.dataManager.changeSearchText(query)
    //@ts-ignore
    refTable.current!.onSearchChangeDebounce(query)
  }
  const onAddClick = () => {
    const table: any = refTable.current!
    table.dataManager.changeRowEditing()
    table.setState({
      ...table.dataManager.getRenderState(),
      showAddRow: !table.state.showAddRow,
    })
  }

  const edit = getEditTable()
  const trash = getTrashTable()
  const done = getDoneTable()
  const cancel = getCancelTable()

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref)),
    Check: forwardRef(() => done),
    Clear: forwardRef(() => cancel),
    Delete: forwardRef(() => trash),
    Edit: forwardRef(() => edit)
  }

  return <>
    <Box display="flex" flexDirection="row">
      <Box style={{marginTop: 15}}>
        {props.tabs ? props.tabs : null}
      </Box>
      <Box flexGrow={1} />
      <TextField
        style={{marginTop: 6}}
        onChange={(e) => changeS(e.target.value)}
        placeholder={t("search")}
        className="mtable search-field" />
    </Box>
    <Container maxWidth="lg" className="mtable container-table">
      <MaterialTable
        icons={tableIcons}
        tableRef={refTable}
        title={"Ticket templates"}
        options={{
          pageSize: 50,
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1,
        }}
        localization={{ header: { actions: "" } }}
        components={{
          Pagination: (props) => <CPagination {...props} onAddClick={onAddClick} />,
        }}
        columns={[
          {
            title: t("display_name"),
            field: "display_name"
          },
          {
            title: t("Fields"),
            editable: 'never',
            render: (row) => <>
              {row ? <FieldDetail tiket_template={row as TicketTemplate} /> : null}
            </>
          },
          {
            field: "enabled",
            title: t("enabled"),
            render: (row) => <>
              {row
                ? <Switcher
                      checked={row.enabled}
                      onChange={() => props.onEnabled(row)}/>
                : null}
                </>,
            editComponent: () => <></>
          },
        ]}
        data={props.filtered}
        editable={{
          onRowAdd: (newData) => new Promise((resolve) => {
            props.onCreate(newData)
            setTimeout(() => {
              resolve()
            }, 200)
          }),
          onRowUpdate: (newData) => new Promise((resolve) => {
            props.onUpdate(newData)
            setTimeout(() => {
              resolve()
            }, 200);
          }),
          onRowDelete: (oldData) => new Promise((resolve) => {
            props.onDelete(oldData)
            setTimeout(() => {
              resolve();
            }, 200);
          }),
        }}
      />
    </Container>
  </>
})