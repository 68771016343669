import {call, cancel, fork, put, race, select, take, takeEvery} from 'redux-saga/effects'
import {
  IAuthState,
  IDecodedToken,
  ILoginRAction,
  ILoginRes,
  logFailAction,
  LOGIN_REQ_ACTION_TYPE,
  loginAction,
  LOGOUT_ACTION_TYPE,
  logOutAction
} from './store'
import jwt from 'jsonwebtoken'
import {updatingToken} from './updating_token'
import {warningMessageAction} from '../../shared/common_store'
import {pushTicket, TypeEssence} from "../../../../features/centrifugo_saga"
import {removeEmpty} from "../../statistics/pages/helper";
import i18next from "i18next";


const FETCH_ACTION = "FETCH_ACTION"
// todo: rid of this: (use react proxy)
export const baseAPIUrl = () => `${(window as any).globalAppConfig.backEndUrl}`

export interface IFetchAction {
  type: typeof FETCH_ACTION
  url: RequestInfo
  options: RequestInit
  callbackAction: string
  apiPrefix?: string
  isIgnoreError?: boolean
}
export const getToken = (state: { authentication: IAuthState }) => {
  return state.authentication.token
}

export const fromLocalStorag = (): ILoginRes | false => {
  if (!window.localStorage["authToken"])
    return false
  return {
    user_id: window.localStorage["auth_user_id"] as string,
    exp: Number(window.localStorage["authToken_exp"]),
    nbf: Number(window.localStorage["authToken_nbf"]),
    token: window.localStorage["authToken"] as string,
    scope: window.localStorage["authToken_scope"] as string,
  }
}

export function saveToLocalStore(lres: ILoginRes) {
  window.localStorage["auth_user_id"] = lres.user_id
  window.localStorage["authToken_exp"] = lres.exp
  window.localStorage["authToken_nbf"] = lres.nbf
  window.localStorage["authToken"] = lres.token
  window.localStorage["authToken_scope"] = lres.scope
}

export function* loginSaga({ login, password }: ILoginRAction) {
  const option = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: login,
      password
    })
  }
  const url = baseAPIUrl() + 'api/tokens/create'
  const resp: Response = yield call(fetch, url, option)
  if (resp.ok) {
    const { token } = yield call(() => resp.json())
    const decode: IDecodedToken = (jwt.decode(token) as IDecodedToken)
    return {
      token,
      ...decode
    }
  } else {
    return false
  }
}

function saveResponseAsFile(blob: Blob) {
  const a = document.createElement("a")
  document.body.appendChild(a)
  a.style.display = "none"
  a.href = window.URL.createObjectURL(blob)
  a.download = "report.xlsx"
  a.click()
  window.URL.revokeObjectURL(a.href)
}

export function* authedFetch(action: IFetchAction) {
  const { url, options, callbackAction, apiPrefix, isIgnoreError } = action
  const token = yield select(getToken)
  if (token) {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`
    }
    let urlR = baseAPIUrl()
    urlR += apiPrefix ? apiPrefix : "api/"
    urlR += url
    try {
      const response = yield call(() => fetch(urlR, options)
        .then(res => {
          if (!res.ok) {
            throw res
          }
          const contType = res.headers.get('content-type')
          if (contType) {
            if (contType.indexOf('application/json;') > -1) {
              return res.json()
            } else {
              if (callbackAction === "DOWNLOAD") {
                res.blob().then(saveResponseAsFile)
              }
              return res
            }
          }
          throw "no content type in response"
        }))
      yield put({ type: callbackAction, response })
    } catch (error) {
      if (error.status === 401) {
        yield put(logOutAction())
      } else if (error.status === 405) {
        const response = yield call(() => error.json())
        yield put(warningMessageAction(i18next.t(response.errors[0])))
      } else {
        if (!isIgnoreError)
          yield put(warningMessageAction(i18next.t("oops an error occured")))
      }
      //console.log(error)
      //yield put({ type: callbackAction, response: error })
    }
  }
}

export function* logout() {
  yield call(() => {
    delete window.localStorage["authToken"]
    delete window.localStorage["currentUser.mail"]
    delete window.localStorage["cenToken"]
    delete window.localStorage["cenTokenExpires"]
    window.location.replace("/user")
  })
}

export function* authSaga() {

  yield takeEvery(FETCH_ACTION, authedFetch)
  yield takeEvery(LOGOUT_ACTION_TYPE, logout)
  const existedCredetinals: ILoginRes = yield select(fromLocalStorag)
  if (existedCredetinals) {
    if (existedCredetinals.exp * 1000 > Number(new Date())) {
      yield put(loginAction(existedCredetinals))
      const service = yield fork(pushTicket, existedCredetinals.user_id, TypeEssence.USER)
      yield race({
        logout: take(LOGOUT_ACTION_TYPE),
        tokenUpdate: call(updatingToken, existedCredetinals)
      })
      yield cancel(service)
    }
    yield put(logOutAction())
  }
  while (true) {
    let action = yield take(LOGIN_REQ_ACTION_TYPE)
    let loginRes = yield call(loginSaga, action)
    while (!loginRes) {
      yield put(logFailAction("Wrong login or password"))
      action = yield take(LOGIN_REQ_ACTION_TYPE)
      loginRes = yield call(loginSaga, action)
    }
    yield put(loginAction(loginRes))
    const service = yield fork(pushTicket, loginRes.user_id, TypeEssence.USER)
    yield call(saveToLocalStore, loginRes)
    yield race({
      logout: take(LOGOUT_ACTION_TYPE),
      tokenUpdate: call(updatingToken, loginRes)
    })
    yield cancel(service)
    yield put(logOutAction())
  }
}

export function* getApiResponse(path: string, method: string, body?: any) {
  const token = yield select(getToken)
  const options = removeEmpty({
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: body ? JSON.stringify(body) : null
  })
  const url = baseAPIUrl() + 'api' + path
  const response = yield call(fetch, url, options)
  return yield call(() => response.json())
}

export function* getApiDeviceResponse(path: string, method: string, body?: any) {
  const token = yield select(getToken)
  const options = removeEmpty({
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: body ? JSON.stringify(body) : null
  })
  const url = baseAPIUrl() + 'service_api' + path
  const response = yield call(fetch, url, options)
  return yield call(() => response.json())
}

