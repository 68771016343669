import { BaseAttribute, BaseEntity, buildStore, buildTableContainer, buidlRemoteSyncContainer } from "../features/entity"
import { EntityId } from "../features/entity/type"

const attributes: BaseAttribute[] = [
  new BaseAttribute('display_name'),
  new BaseAttribute('code'),
]

export class Role extends BaseEntity{
  public static attributes: BaseAttribute[] = attributes
  public static entity_name: string = "Role"
  display_name: string
  code: string
  city_id: EntityId = "-1"
  constructor(display_name:string="", code: string="", id: EntityId = "-1"){
    super(id === "-1" ? String(Math.floor(Math.random() * 10000)) : id)
    this.display_name = display_name
    this.code = code
  }
}

export const RoleReducer = buildStore(Role)
export const RemoteSyncRoleContainer = buidlRemoteSyncContainer(Role)
export const ListRoleContanier = buildTableContainer(Role)

