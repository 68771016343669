import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles, createStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
    }
  })
)

type Props = {
  data: {
    name: string
    value: number
    color: string
  }[]
}

export default ({ data }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return <Card variant="outlined">
    <CardHeader title={t("ticket topic")} classes={classes}/>
    <CardContent>
      <Box display="flex" flexDirection="row">
        <PieChart width={320} height={320}>
          <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={30} outerRadius={135} fillRule="color">
            {
              data.map((entry) => <Cell fill={entry.color} />)
            }
          </Pie>
        </PieChart>

        <Divider orientation="vertical" flexItem className="dash-pie-divider" />
        <Box flexGrow={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="dash-color-marker-col"/>
                <TableCell>{t("line")}</TableCell>
                <TableCell>{t("clients")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.name}>
                  <TableCell className="dash-color-marker-col" component="th" scope="row">
                    <Box className="dash-color-marker" style={{ backgroundColor: row.color }} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </CardContent>
  </Card>
}